<div class="news-blocks">
      <div *ngIf="!shortList">
        <app-news-block *ngFor="let item of images.slice().reverse(); index as i;"
              [image1]="cards[images.length - 1 - i]" [image2]="backdrop"
              [headline]="headlines[images.length - 1 - i]"
              [story]="stories[images.length - 1 - i][0]" [container]="this"
              [index]="images.length - 1 - i">
        </app-news-block>
      </div>
      <div *ngIf="shortList && this.news.length > 3">
        <app-news-block [image1]="cards[this.images.length - 1]" [image2]="backdrop"
              [headline]="headlines[this.images.length - 1]" [story]="stories[this.images.length - 1][0]"
              [container]="this" [index]="this.images.length - 1">
        </app-news-block>
        <app-news-block [image1]="cards[this.images.length - 2]" [image2]="backdrop"
              [headline]="headlines[this.images.length - 2]" [story]="stories[this.images.length - 2][0]"
              [container]="this" [index]="this.images.length - 2">
        </app-news-block>
        <app-news-block [image1]="cards[this.images.length - 3]" [image2]="backdrop"
              [headline]="headlines[this.images.length - 3]" [story]="stories[this.images.length - 3][0]"
              [container]="this" [index]="this.images.length - 3">
        </app-news-block>
      </div>
</div>
    