<div [id]="'container ' + index" class="container" (mouseover)="slide()" (mouseout)="slide()" (click)="click()">
  <ng-container *ngIf="blurb === undefined && data === undefined">
    <div *ngIf="!newest" [@fade]="isdown ? 'in' : 'out'"
    class="name" [attr.id]="'name ' + myth.mythname">{{ myth.mythname }}</div>
    <div *ngIf="newest" class="name" style="opacity: 1"
      [attr.id]="'name ' + myth.mythname">{{ myth.mythname }}</div>
    <img alt="Malware was here!" class="mythdisplay" [src]="myth.mythdisplay[0]">
    <img alt="Malware was here!" [@fade]="isdown ? 'in' : 'out'" class="mythdisplay" [src]="myth.mythdisplay[1]">
  </ng-container>
  <ng-container *ngIf="blurb !== undefined">
    <img alt="Malware was here!" class="mythdisplay" [src]="blurb.cardImage">
  </ng-container>
  <ng-container *ngIf="data !== undefined">
    <img alt="Malware was here!" class="mythdisplay" [src]="data[1]">
  </ng-container>
  <div class="sidebar-container">
    <img alt="Malware was here!" class="sidebarLeft" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-02.png" />
    <img alt="Malware was here!" class="sidebarRight" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-01.png" />
  </div>
  <img class="mythframe"><!--This is empty on purpose for visual effects-->
  <ng-container *ngIf="blurb === undefined && data === undefined">
    <img alt="Malware was here!" *ngIf="!newest" [@updown]="isdown ? 'down' : 'up'"
      class="mythframe2" [attr.id]="myth.mythname" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/lore-frame.webp">
    <img alt="Malware was here!" *ngIf="newest" class="mythframe2" style="top: 12.5em"
      [attr.id]="myth.mythname" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/lore-frame.webp">
  </ng-container>
  <ng-container *ngIf="blurb !== undefined || data !== undefined">
    <img alt="Malware was here!" class="mythframe2" [@updown]="isdown ? 'down' : 'up'" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/lore-frame.webp">
  </ng-container>
</div>
