<div class="container" (click)="navigate()" (mouseover)="setLocation()" (mouseout)="slideOut()">
  <div [@fade]="isdown ? 'in' : 'out'" class="name">{{ location[0] }}</div>
    <img alt="Malware was here!" class="mythdisplay" [src]="location[2]">
    <img alt="Malware was here!" [@fade]="isdown ? 'in' : 'out'" class="mythdisplay" [src]="location[1]">
    <div class="sidebar-container">
      <img alt="Malware was here!" class="sidebarLeft" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-02.png" />
      <img alt="Malware was here!" class="sidebarRight" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-01.png" />
  </div>
    <img class="mythframe"><!--This is empty on purpose for visual effects-->
    <img alt="Malware was here!" [@updown]="isdown ? 'down' : 'up'" class="mythframe2" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/lore-frame.webp">
</div>