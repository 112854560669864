import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {

  @Input() news;
  @Input() storyIndex: number;
  videoLink;

  currentSlide = 0;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.news.video.length > 0) {
      this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/'
      + this.news.video
      + '?autoplay=1&modestbranding=1&showinfo=0&loop=1&playlist='
      + this.news.video);
    }
  }

  changeSlides(n: number): void {
    this.currentSlide += n;
    if (this.currentSlide < 0) { this.currentSlide = this.news.images.length - 1; }
    if (this.currentSlide >= this.news.images.length) { this.currentSlide = 0; }
  }
}
