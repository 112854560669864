import { Component, OnInit } from '@angular/core';
import { InternalComms } from '../internal-comms.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SwaggerService } from '../swagger.service';
import { ElementSchemaRegistry, ThrowStmt } from '@angular/compiler';
import { AlphabetComponent } from '../alphabet/alphabet.component';

@Component({
  selector: 'app-lore',
  templateUrl: './lore.component.html',
  styleUrls: ['./lore.component.css']
})
export class LoreComponent implements OnInit {
  isHeadless = false;

  codex = [];
  currentCodex = 0;
  currentParagraph = 0;
  currentImage = 0;
  selectedCodexes = [];
  displayLetter  = '';
  searchText = '';
  alphabet = [
    ['A', 'A2'],
    ['B', 'B2'],
    ['C', 'C2'],
    ['D', 'D2'],
    ['E', 'E2'],
    ['F', 'F2'],
    ['G', 'G2'],
    ['H', 'H2'],
    ['I', 'I2'],
    ['J', 'J2'],
    ['K', 'K2'],
    ['L', 'L2'],
    ['M', 'M2'],
    ['N', 'N2'],
    ['O', 'O2'],
    ['P', 'P2'],
    ['Q', 'Q2'],
    ['R', 'R2'],
    ['S', 'S2'],
    ['T', 'T2'],
    ['U', 'U2'],
    ['V', 'V2'],
    ['W', 'W2'],
    ['X', 'X2'],
    ['Y', 'Y2'],
    ['Z', 'Z2']
  ];

  constructor(private comms: InternalComms, private route: ActivatedRoute, private swagger: SwaggerService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.isHeadless = params.isHeadless;
    });

    this.comms.setCurrent('navCodex');
    // tslint:disable-next-line: deprecation
    this.swagger.getAllCodex().subscribe(response => {
      this.swagger.setCodex(response);
      for (let i = 0; i < response.length; i++) {
        this.codex[i] = JSON.parse(response[i].data);
      }
      this.selectedCodexes = this.codex;
      this.selectedCodexes.sort((a, b) => {
        a = a.title.replace('The ', '');
        b = b.title.replace('The ', '');
        return a.localeCompare(b) ;
      });
      // tslint:disable-next-line: deprecation
      this.route.paramMap.subscribe(params => {
        if (params.has('id') && +params.get('id') <= this.selectedCodexes.length) {
          for (let i = 0; i < this.selectedCodexes.length; i++) {
            if (this.selectedCodexes[i].loreIndex ===  +params.get('id')) {
              this.currentCodex = i;
            }
          }
        } else if (params.has('id')) {
          for (let i = 0; i < this.selectedCodexes.length; i++) {
            if (this.selectedCodexes[i].title === params.get('id').split('-').join(' ')) {
              this.currentCodex = i;
            }
          }
        }
        setTimeout(() => {
          this.gotoBook(this.currentCodex);
        }, 200);
      });
    });
  }

  navigate(page) {
    if(this.isHeadless) {
      this.router.navigate([page], {
        queryParams: {
          isHeadless: true
        }
      });
    } else {
      this.router.navigate([page]);
    }
  }

  changePage(n) {
    this.currentParagraph += n;
    const elems = document.getElementsByClassName('pB');
    for (let i = 0; i < elems.length; i++) {
      const elem = document.getElementById('page ' + i) as HTMLElement;
      elem.style.backgroundColor = 'transparent';
      elem.style.color = 'white';
    }
    const selectedPage = document.getElementById('page ' + this.currentParagraph) as HTMLElement;
    if (selectedPage !== undefined && selectedPage !== null) {
      selectedPage.style.backgroundColor = 'rgb(202, 166, 91)';
      selectedPage.style.color = 'black';
    }
    const page = document.getElementById('storyPage');
    page.scrollTop = 0;
  }

  gotoPage(n) {
    this.currentParagraph = n;
    const elems = document.getElementsByClassName('pB');
    for (let i = 0; i < elems.length; i++) {
      const elem = document.getElementById('page ' + i) as HTMLElement;
      elem.style.backgroundColor = 'transparent';
      elem.style.color = 'white';
    }
    const selectedPage = document.getElementById('page ' + this.currentParagraph) as HTMLElement;
    if (selectedPage !== undefined && selectedPage !== null) {
      selectedPage.style.backgroundColor = 'rgb(202, 166, 91)';
      selectedPage.style.color = 'black';
    }
    const page = document.getElementById('storyPage');
    page.scrollTop = 0;
  }

  gotoBook(n) {
    this.currentCodex = n;
    
    if(this.isHeadless) {
      window.history.pushState({}, '', `/#/Library/${this.selectedCodexes[this.currentCodex].loreIndex}/${this.selectedCodexes[this.currentCodex].title.split(' ').join('-')}?isHeadless=true`);
    } else {
      window.history.pushState({}, '', `/#/Library/${this.selectedCodexes[this.currentCodex].loreIndex}/${this.selectedCodexes[this.currentCodex].title.split(' ').join('-')}`);
    }
    
    setTimeout(() => {
      const elems = document.getElementsByClassName('book');
      for (let i = 0; i < elems.length; i++) {
        const elem = document.getElementById('book ' + i) as HTMLElement;
        elem.style.backgroundColor = 'transparent';
        elem.style.color = 'rgb(202, 166, 91)';
      }
      const selectedBook = document.getElementById('book ' + this.currentCodex) as HTMLElement;
      selectedBook.style.backgroundColor = 'rgb(202, 166, 91)';
      selectedBook.style.color = 'black';
      this.gotoPage(0);
      document.getElementById('scrollPointLibrary').scrollIntoView();
    }, 100);
  }

  changeSlides(n: number) {
    this.currentImage += n;
    if (this.currentImage < 0) {this.currentImage = this.codex[this.currentCodex].images.length - 1; }
    if (this.currentImage >= this.codex[this.currentCodex].images.length) {this.currentImage = 0; }
  }

  onKeySearch(value) {
    this.searchText = value;
    this.searchByWord(this.searchText);
  }

  searchByWord(value) {
    this.selectedCodexes = [];
    let j = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let codexIndex = 0; codexIndex < this.codex.length; codexIndex++) {
      // tslint:disable-next-line: prefer-for-of
      for (let tagIndex = 0; tagIndex < this.codex[codexIndex].tags.length; tagIndex++) {
        if (this.codex[codexIndex].tags[tagIndex].toLowerCase().includes(value.toLowerCase())
        && this.selectedCodexes.indexOf(this.codex[codexIndex]) < 0) {
          this.selectedCodexes[j++] = this.codex[codexIndex];
          break;
        }
      }
      // tslint:disable-next-line: prefer-for-of
      for (let arrayIndex = 0; arrayIndex < this.codex[codexIndex].paragraphs.length; arrayIndex++) {
        // tslint:disable-next-line: prefer-for-of
        for (let paragraphIndex = 0; paragraphIndex < this.codex[codexIndex].paragraphs[arrayIndex].length; paragraphIndex++) {
          // tslint:disable-next-line: prefer-for-of
          if (this.codex[codexIndex].paragraphs[arrayIndex][paragraphIndex].toLowerCase().includes(value.toLowerCase())
          && this.selectedCodexes.indexOf(this.codex[codexIndex]) < 0) {
            this.selectedCodexes[j++] = this.codex[codexIndex];
            break;
          }
        }
      }
    }
    this.selectedCodexes.sort((a, b) => {
      a = a.title.replace('The ', '');
      b = b.title.replace('The ', '');
      return a.localeCompare(b) ;
    });
  }

  filter(item) {
    const elems = [];
    this.selectedCodexes = [];
    this.searchText = item;
    let j = 0;
    switch (item) {
      case 'All':
        this.selectedCodexes = this.codex;
        break;
      case 'Other':
        // tslint:disable-next-line: prefer-for-of
        for (let codexIndex = 0; codexIndex < this.codex.length; codexIndex++) {
          // tslint:disable-next-line: prefer-for-of
          for (let tagIndex = 0; tagIndex < this.codex[codexIndex].tags.length; tagIndex++) {
            if (this.codex[codexIndex].tags[tagIndex] === item) {
              this.selectedCodexes[j++] = this.codex[codexIndex];
              break;
            }
          }
        }
        break;
      default:
        // tslint:disable-next-line: prefer-for-of
        for (let codexIndex = 0; codexIndex < this.codex.length; codexIndex++) {
          // tslint:disable-next-line: prefer-for-of
          for (let tagIndex = 0; tagIndex < this.codex[codexIndex].tags.length; tagIndex++) {
            if (this.codex[codexIndex].tags[tagIndex][0] === item) {
              this.selectedCodexes[j++] = this.codex[codexIndex];
              break;
            }
          }
        }
        break;
    }
    this.selectedCodexes.sort((a, b) => {
      a = a.title.replace('The ', '');
      b = b.title.replace('The ', '');
      return a.localeCompare(b) ;
    });
    if (this.selectedCodexes.length > 0) {
      this.gotoBook(0);
    }
  }

  showLetter(letter) {
    this.displayLetter = letter;
  }

  hideLetter() {
    this.displayLetter = '';
  }
}
