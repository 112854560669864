import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isHeadless = false;

  constructor(library: FaIconLibrary, private route: ActivatedRoute) {
    library.addIcons(faTimes);
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.isHeadless = params.isHeadless;
      });
  }
}
