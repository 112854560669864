import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-news-container',
  templateUrl: './news-container.component.html',
  styleUrls: ['./news-container.component.css']
})
export class NewsContainerComponent implements OnInit {

  @Input() shortList: boolean;

  backdrop = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frameBorder.svg';
  images: string[][] = [[]];
  headlines: string[] = [];
  bylines: string[] = [];
  tags: string[] = [];
  stories: string[][] = [[]];
  news = [];
  cards: string[] = [];
  today = new Date();

  constructor(private router: Router, private swagger: SwaggerService) { }

  ngOnInit(): void {
    // tslint:disable-next-line: deprecation
    this.swagger.getAllNews().subscribe(response => {
      this.swagger.setNews(response);
      let counter = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < response.length; i++) {
        const temp = JSON.parse(response[i].data);
        const tempDate = new Date(temp.date);
        if (tempDate < this.today) {
          this.news[counter] = temp;
          counter++;
        } else if (tempDate === this.today && this.today.getUTCHours() > 17) {
          this.news[counter] = temp;
          counter++;
        }
      }
      this.news.sort((a, b) => {
        a = new Date(a.date).getTime().toString();
        b = new Date(b.date).getTime().toString();
        return a.localeCompare(b);
      });
      for (let i = 0; i < this.news.length; i++) {
        this.images[i] = this.news[i].images;
        this.headlines[i] = this.news[i].headline;
        this.bylines[i] = this.news[i].byline;
        this.stories[i] = this.news[i].story;
        this.tags[i] = this.news[i].tag;
        this.cards[i] = this.news[i].card;
      }
    });
  }

  goToStory(n: number) {
    this.router.navigate(['News/' + this.headlines[n].split(' ').join('-')]);
  }
}
