import { Component, Input, OnInit } from '@angular/core';
import { BuilderComponent } from '../builder/builder.component';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {

  @Input() builder: BuilderComponent;

  constructor() {}

  ngOnInit(): void {}

  facebook() {
    window.open('http://www.facebook.com/sharer.php?u=' + window.location.href, '_blank');
  }

  twitter() {
    window.open('http://twitter.com/share?text=Ethereal%20Clash%20of%20Souls&url=' + window.location.href.replace('://', '%3A%2F%2F') + '&hashtags=etherealclashofsouls,ecos,undyinggames', '_blank');
  }

  reddit() {
    window.open('http://www.reddit.com/submit?url=' + window.location.href + '&title=Ethereal%20Clash%20of%20Souls', '_blank');
  }

}
