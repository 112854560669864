import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-itembox',
  templateUrl: './itembox.component.html',
  styleUrls: ['./itembox.component.css']
})
export class ItemboxComponent {

@Input() itemData;
@Input() itemComponent;
@Input() selected = false;

hovered = false;

  changeItem() {
    this.itemComponent.selectItem(this.itemData.itemNumber);
  }

  showName() {
    this.hovered = true;
  }

  hideName() {
    this.hovered = false;
  }
}
