import { Injectable } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SwaggerService } from './swagger.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InternalComms {

  user = {
    username: '',
    password: '',
    email: '',
    playerName: '',
    accountId: 0,
    authToken: '',
    expiresInSeconds: 0
  };

  header: HeaderComponent;

  constructor(private swagger: SwaggerService, private route: ActivatedRoute) { }

  getPortrait(user: { username: string; password: string; email: string; playerName: string; accountId: number; authToken: string; }) {
    return this.swagger.getPortrait(user);
  }

  getFriendRequests(authToken) {
    return this.swagger.getFriendRequests(authToken);
  }

  redeemKey(authToken, key) {
    return this.swagger.redeemKey(authToken, key);
  }

  checkPreAlphaStatus(authToken) {
    return this.swagger.checkPreAlphaStatus(authToken);
  }

  getUser() {
    return this.user;
  }

  setUser(value) {
    this.user = value;
  }

  signout() {
    this.user = {
      username: '',
      password: '',
      email: '',
      playerName: '',
      accountId: 0,
      authToken: '',
      expiresInSeconds: 0
    };
  }

  /*
  testAuth(token: string) {
    return this.swagger.checkAuth(token);
  }
  */

  login(username: string, password: string, admin: boolean) {
    return this.swagger.login(username, password);
  }

  forgotPassword(email: string, username: string) {
    return this.swagger.forgotPassword(email, username);
  }

  changePassword(data) {
    return this.swagger.changePassword(data);
  }

  changePasswordAuthenticated(user, data) {
    return this.swagger.changePasswordAuthenticated(user.authToken, data);
  }

  refresh(user) {
    return this.swagger.refresh(user);
  }

  choosePortrait(user, portraitId) {
    return this.swagger.setPortrait(user, portraitId);
  }

  profile(user: { username: string; password: string; email: string; playerName: string; accountId: number}) {
    return this.swagger.profile(user);
  }

  status(user) {
    return this.swagger.status(user.authToken, user.accountId);
  }

  statistics(user) {
    return this.swagger.statistics(user.authToken, user.accountId);
  }

  getFriendsList(user) {
    return this.swagger.getFriendsList(user.authToken);
  }

  getFriendsProfiles(user, ids) {
    return this.swagger.getFriendsProfiles(user.authToken, ids);
  }

  getFriendsStatus(user, ids) {
    return this.swagger.getFriendsStatus(user.authToken, ids);
  }

  getFriendsStats(user, ids) {
    return this.swagger.getFriendsStats(user.authToken, ids);
  }

  signup(user: any) {
    return this.swagger.createAccount(user);
  }

  getPortraits(user: any) {
    return this.swagger.getAllPortraits(user.authToken);
  }

  getCoins(user) {
    return this.swagger.getCoins(user);
  }

  setCurrent(page: string) {
    this.header.toggleView(page);
  }

  navigate(page: string) {
    this.header.navigate(page);
  }

  getPlayerName(value, authToken) {
    return this.swagger.getPlayerName(value, authToken);
  }

  getMatchHistory(user) {
    return this.swagger.getMatchHistory(user.authToken);
  }

  getCoinBundles(user) {
    return this.swagger.getCoinsBundles(user.authToken);
  }

  getOptions(user) {
    return this.swagger.getOptions(user.authToken);
  }

  storeOptions(user, options) {
    return this.swagger.storeOptions(user.authToken, options, user.accountId);
  }

  getAllPortraits(user) {
    return this.swagger.getAllPortraits(user.authToken);
  }

  buySpecialCoins(user, bundleId) {
    return this.swagger.buyCoinsBundle(user.authToken, user.accountId, bundleId);
  }

  confirmCoinPurchase(user, orderId) {
    return this.swagger.confirmPurchase(user.authToken, user.accountId, orderId);
  }

}
