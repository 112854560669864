<div class="section-slideshow" id="slideShowContainer" *ngIf="slideSources.length > 0">
  <div class="slideshow">
    <a class="prev" (click)="changeSlides(1)">&#10094;</a>
    <a class="next" (click)="changeSlides(-1)">&#10095;</a>
    <div [@fade]="change ? 'out' : 'in'" (click)="navigate()">
      <div *ngIf="slideSources[slideIndex][0].includes('webm')" style="position: relative; top: 0; left: 0;">
        <video style="z-index: 2" [src]="slideSources[slideIndex][0]" id="slide" autoplay (ended)="slideAutomatically()"></video>
      </div>
      <img *ngIf="slideSources[slideIndex][0].includes('webp')" alt="Malware was here!" [src]="slideSources[slideIndex][0]" id="slide">
    </div>
    <div class="story" *ngIf="isnews && newsdata != undefined && newsdata.length > 0" [@fade]="change ? 'out' : 'in'">
      <p class="p1">{{ newsdata[slideIndex + (newsdata.length - slideSources.length)].tag }}</p>
      <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" /><br />
      <p class="p2">{{ newsdata[slideIndex + (newsdata.length - slideSources.length)].date }}</p>
      <p class="p3">&nbsp;{{ newsdata[slideIndex + (newsdata.length - slideSources.length)].story[0] }}</p>
      <button (click)="readMore()">Read More</button>
    </div>
  </div>
</div>
<div style="text-align: center; display: inline;" *ngFor="let item of slideSources; index as i">
  <div class="dot" (click)="currentSlide(slideSources.length - 1 - i)"><img></div>
</div>  