<div class="mainSection" (click)="setExpandedandPlayVideo()">
    <div *ngIf="winner" style="color: rgb(202, 166, 91); display: flex; justify-content: space-around; width: 100%;">
        <p>Victory</p>
        <p>{{ matchLength }}</p>
        <p>{{ matchTime }}</p>
        <p>{{ matchDate }}</p>
    </div>
    <div *ngIf="!winner" style="display: flex; justify-content: space-around; width: 100%;">
        <p>Defeat</p>
        <p>{{ matchLength }}</p>
        <p>{{ matchTime }}</p>
        <p>{{ matchDate }}</p>
    </div>
</div>
<!-- <div *ngIf="expanded" style="display: flex; justify-content: center; width: 100%; border: .1em solid white;">
    <div *ngIf="winner" class="playerList">
        <div *ngFor="let item of this.matchData.winnerTeam.members"> {{ item.accountId }}
            <img >
        </div>
    </div>
    <div *ngIf="winner" class="vsVideoPlayerContainer">
        <video [attr.id]="'vsVideoPlayer' + index" class="vsVideoPlayer" [src]="vsVideo" autoplay></video>
    </div>
    <div class="playerList">
        <div *ngFor="let item of this.matchData.loserTeam.members"> {{ item.accountId }}</div>
    </div>
    <div *ngIf="!winner" class="vsVideoPlayerContainer">
        <video [attr.id]="'vsVideoPlayer' + index" class="vsVideoPlayer" [src]="vsVideo" autoplay></video>
    </div>
    <div *ngIf="!winner" class="playerList">
        <div *ngFor="let item of this.matchData.winnerTeam.members"> {{ item.accountId }}</div>
    </div>
</div> -->

