import { Component, Input, OnInit } from '@angular/core';
import { NewsContainerComponent } from '../news-container/news-container.component';

@Component({
  selector: 'app-news-block',
  templateUrl: './news-block.component.html',
  styleUrls: ['./news-block.component.css']
})
export class NewsBlockComponent implements OnInit {

  @Input() container: NewsContainerComponent;
  @Input() image1: string;
  @Input() image2: string;
  @Input() headline: string;
  @Input() story: string;
  @Input() index: number;
  defaultCard = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/News/DefaultNewsCard.webp';

  ngOnInit(): void {
    if (this.story !== undefined) {
      if (this.story.length > 150) {
        this.story = this.story.slice(0, 150);
        if (this.story.includes('?')) {
          this.story = this.story.slice(0, this.story.indexOf('?', 50) + 1);
        } else if (this.story.includes('!')) {
          this.story = this.story.slice(0, this.story.indexOf('!', 50) + 1);
        } else {
          this.story = this.story.slice(0, this.story.indexOf('.', 50) + 1);
        }
      }
    }
  }

  gotoStory() {
    this.container.goToStory(this.index);
  }
}
