import { Component, Input, AfterViewInit } from '@angular/core';
import { MythsComponent } from '../myths/myths.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UniverseComponent } from '../universe/universe.component';
import { BuilderComponent } from '../builder/builder.component';

@Component({
  selector: 'app-myth-button',
  animations: [
    trigger('updown', [
      state('up', style({
        top: '10em'
      })),
      state('down', style({
        top: '12.5em'
      })),
      transition('up => down', [
        animate('.4s')
      ]),
      transition('down => up', [
        animate('.4s')
      ]),
    ]),
    trigger('fade', [
      state('in', style({
        opacity: '1'
      })),
      state('out', style({
        opacity: '0'
      })),
      transition('in => out', [
        animate('.2s')
      ]),
      transition('out => in', [
        animate('.2s .4s ease-in')
      ]),
    ])
  ],
  templateUrl: './myth-button.component.html',
  styleUrls: ['./myth-button.component.css']
})
export class MythButtonComponent implements AfterViewInit {

  @Input() myth;
  @Input() myths: MythsComponent;
  @Input() universe: UniverseComponent;
  @Input() builder: BuilderComponent;
  @Input() index;
  @Input() newest = false;
  @Input() clickable = true;
  @Input() blurb;
  @Input() data;
  @Input() threat = false;
  @Input() synergy = false;

  isHeadless = false;
  isdown = false;
  current = 0;

  ngAfterViewInit(): void {
    const elem = document.getElementById('container ' + this.index);
    if (this.clickable) {
      elem.style.cursor = 'pointer';
    } else {
      elem.style.cursor = 'default';
    }
  }

  slide() {
    if (this.clickable) { this.isdown = !this.isdown; }
    if (this.universe != null && this.isdown) {
      this.universe.setCurrentMyth(this.index);
    } else if (this.universe != null && !this.isdown) {
      this.universe.setCurrentMyth(-1);
    }
  }

  click() {
    if (this.myths !== undefined && this.clickable) {
      this.myths.selectMyth(this.index);
    } else if (this.universe !== undefined && this.clickable) {
      this.universe.selectMyth(this.myth.loreNumbers[0]);
    } else if (this.builder) {
      if (!this.threat && !this.synergy) {
        this.builder.setMyth(this.myth.release);
      } else if (this.threat) {
        this.builder.toggleThreat(this.myth);
      } else if (this.synergy) {
        this.builder.toggleSynergies(this.myth);
      }
    }
  }
}
