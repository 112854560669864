import { Component, Input } from '@angular/core';
import { UniverseComponent } from '../universe/universe.component';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-location-button',
  animations: [
    trigger('updown', [
      state('up', style({
        top: '10em'
      })),
      state('down', style({
        top: '12.5em'
      })),
      transition('up => down', [
        animate('.4s')
      ]),
      transition('down => up', [
        animate('.4s')
      ]),
    ]),
    trigger('fade', [
      state('in', style({
        opacity: '1'
      })),
      state('out', style({
        opacity: '0'
      })),
      transition('in => out', [
        animate('.2s')
      ]),
      transition('out => in', [
        animate('.2s .4s ease-in')
      ]),
    ])
  ],
  templateUrl: './location-button.component.html',
  styleUrls: ['./location-button.component.css']
})
export class LocationButtonComponent {

  @Input() location;
  @Input() universe: UniverseComponent;
  @Input() index;

  isdown = false;

  slideOut() {
    this.isdown = false;
    this.universe.setLocation(-1);
  }

  setLocation() {
    this.isdown = true;
    this.universe.setLocation(this.index);
  }

  navigate() {
    this.universe.showLocationDetail(this.location);
  }
}
