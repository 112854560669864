<div style="width: 100%; display: flex; justify-content: center;">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/nameityoutself.webp" id="framePart">
</div>
<div *ngIf="!isHeadless" class="switches" id="scrollPointRealms">
    <button (click)="navigate('Realms')">Realms</button>
    <button style="color: white; cursor: default;">Library</button>
</div>
<div style="width: 100%; display: flex; justify-content: center;">
<img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" id="bottomFrame">
</div>
<app-share></app-share>
<div style="min-height: 100vh;">
    <div style="display: flex; justify-content: center; width: 100%; margin-top: 1em; margin-bottom: 1em;">
        <div style="width: 85%;">
            <div style="display: inline-flex; justify-content: space-evenly; width: 100%;">
                <div style="width: 9em;">
                    <app-alphabet [lore]="this" [images]="['All', 'All2']" [wide]="5"></app-alphabet>
                </div>
                <div style="color: white; width: 60%; border: .01em solid rgb(202, 166, 91); position: relative; top: 0; left: 0;">
                    <img src="http://undyinggames.com/assets/Images/search.png" class="mag">
                    <input type="text" placeholder="Search the Library" class="searchBar" #searchBar (keyup)="onKeySearch(searchBar.value)">
                </div>
                <div style="width: 9em;">
                    <app-alphabet [lore]="this" [images]="['Other', 'Other2']" [wide]="7"></app-alphabet>
                </div>
            </div>
            <div *ngIf="searchText !== ''" class="displayBox" style="margin: 0; color: white; font-size: 2.5vh;">
                We found {{selectedCodexes.length}} record(s) in the library referencing {{searchText}}.
            </div>
            <div *ngIf="searchText === ''" class="displayBox" style="margin: 0; color: white; font-size: 2.5vh;">
                Feel free to search the stacks and tomes. Please, be mindful of dust.
            </div>
        </div>
    </div>
    <div class="displayBox">{{ displayLetter }}</div>
    <div style="display: flex; justify-content: center; width: 100%; margin-bottom: 1em;" id="scrollPointLibrary">
        <div class="lettersBox">
            <ng-container *ngFor="let item of alphabet; index as i;">
                <app-alphabet [lore]="this" [images]="item"></app-alphabet>
            </ng-container>
        </div>
    </div>
    <div *ngIf="selectedCodexes.length > 0" style="display: flex; justify-content: space-around; flex-wrap: wrap;">
        <div>
            <p style="color: white; font-size: 1.5em;">Books and Scrolls</p>
            <div class="scrollboxes">
                <button *ngFor="let item of selectedCodexes; index as i;" (click)="gotoBook(i)" class="book" [attr.id]="'book ' + i">{{ item.title }}</button>
            </div>
        </div>
        <div class="readingPane">
            <h2 class="title">{{ selectedCodexes[currentCodex].title }}</h2>
            <div style="width: 100%; display: flex; justify-content: center;">
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/nameityoutself.webp" style="width: 100%;">
            </div>
            <div class="scrollboxes2" id="storyPage">
                <ng-container *ngFor="let item of selectedCodexes[currentCodex].paragraphs[currentParagraph];">
                    <p *ngIf="!item.includes('(Bold)') && !item.includes('(Italic)')" class="story">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ item }}
                    </p>
                    <p *ngIf="item.includes('(Bold)') && !item.includes('(Italic)')" class="storyBold">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ item.replace('(Bold)', '') }}
                    </p>
                    <p *ngIf="!item.includes('(Bold)') && item.includes('(Italic)')" class="storyItalic">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ item.replace('(Italic)', '') }}
                    </p>
                    <p *ngIf="item.includes('(Bold)') && item.includes('(Italic)')" class="storyBoldItalic">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ item.replace('(Bold)', '').replace('(Italic)', '') }}
                    </p>
                </ng-container>
            </div>
            <div class="container">
                <div style="width: 100%; display: flex; justify-content: center; margin-bottom: .5em;">
                    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/title-center-line.webp" id="mythCrown">
                </div>
                <div *ngIf="selectedCodexes[currentCodex].paragraphs.length > 1" class="pageBar">
                    <button *ngIf="currentParagraph > 0" style="cursor: pointer;" (click)="changePage(-1)">Prev</button>
                    <button *ngIf="!(currentParagraph > 0)" style="opacity: 0;">Prev</button>
                    <div class="pageButtons">
                        <button *ngFor="let item of selectedCodexes[currentCodex].paragraphs; index as i;" [attr.id]="'page ' + i"
                            style="cursor: pointer;" (click)="gotoPage(i)" class="pB">{{ i + 1 }}
                        </button>
                    </div>
                    <button *ngIf="currentParagraph < (selectedCodexes[currentCodex].paragraphs.length - 1)"
                        style="cursor: pointer;" (click)="changePage(1)">Next</button>
                    <button *ngIf="!(currentParagraph < (selectedCodexes[currentCodex].paragraphs.length - 1))"
                        style="opacity: 0;">Next</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedCodexes.length > 0 && selectedCodexes[currentCodex].images.length > 0" class="imageBox">
    <div class="slides" (click)="changeSlides(1)">
        <img alt="Malware was here!" [src]="selectedCodexes[currentCodex].images[currentImage]" class="overlay">
        <img alt="Malware was here!" [src]="selectedCodexes[currentCodex].images[currentImage]" class="backdrop">
    </div>
    <div class="slide-buttons" *ngIf="selectedCodexes[currentCodex].images.length > 1">
        <a class="prev" (click)="changeSlides(-1)">&#10094;</a>
        {{ currentImage + 1}} / {{ selectedCodexes[currentCodex].images.length }}
        <a class="next" (click)="changeSlides(1)">&#10095;</a>
    </div>
</div>