<div style="display: flex; flex-wrap: wrap; justify-content: space-evenly; align-items: center; width: 100%;">
    <div *ngFor="let item of clean.slice().reverse(); index as i;">
        <div *ngIf="i === 0" class="wrapper2">
            <iframe allow="accelerometer; encrypted-media;" frameborder="0" width="100%" height="100%" [src]="item" allowfullscreen>
            </iframe>
            <div class="longLine"></div>
            <p class="videoTitle">{{ videos[clean.length - 1 - i].title }}</p>
            <div style="display: flex; justify-content: center;"><div class="midLine"></div></div>
            <div style="display: flex; justify-content: center;"><div class="shortLine"></div></div>
        </div>
        <div *ngIf="i > 0" class="wrapper3">
            <iframe allow="accelerometer; encrypted-media;" frameborder="0" width="100%" height="100%" [src]="item" allowfullscreen>
            </iframe>
            <div class="longLine"></div>
            <p class="videoTitle">{{ videos[clean.length - 1 - i].title }}</p>
            <div style="display: flex; justify-content: center;"><div class="midLine"></div></div>
            <div style="display: flex; justify-content: center;"><div class="shortLine"></div></div>
        </div>
    </div>
</div>