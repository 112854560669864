<div style="position: relative; top: 0; left: 0;">
    <div class="overlay">
        <video id="background" autoplay muted loop src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/DustAndStarsBackground.webm"></video>
    </div>
    <app-slideshow id="homeSlides" [slideSources]="slideSources"></app-slideshow>
    <div class="latestNews section">
        <div style="position: relative; top: 0; left: 0;">
            <img src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" style="width: 100%; position: absolute; top: -.2em; left: 0;">
        </div>
    <div style="display: flex; flex-direction: column; justify-content: center; vertical-align: middle;">
        <p style="padding-top: 1em;">Latest News Highlights</p>
        <app-news-container [shortList]="true"></app-news-container>
        <div style="width: 100%; display: flex; justify-content: center;">
            <button class="newsLinkButton" (click)="navNews()">More News</button>
        </div>
    </div>
</div>
<div class="gameWorld">
    <div class="gameWorld-extraBackground"></div>
    <div class="gameWorld-container">
        <div class="imageBlockLeft">
            <!-- Side bar images-->
            <div class="sidebar-container">
                <img alt="Malware was here!" class="sidebarTop" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-03.webp" />
                <img alt="Malware was here!" class="sidebarLeft" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-01.png" />
                <img alt="Malware was here!" [src]="gameWorld1" class="gameWorldOverlay" />
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frameBorder.webp" class="gameFrameBorder" />
                <img alt="Malware was here!" class="sidebarRight" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-02.png" />
                <img alt="Malware was here!" class="sidebarBottom" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-04.webp" />
            </div>
        </div>
        <div class="textBlockRight">
            <p class="p1">{{ gameWorldTitles[0] }}</p><br />
            <p class="p2">{{ gameWorldHeadlines[0] }}</p><br />
            <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" /><br />
            <p class="p3">&nbsp;&nbsp;&nbsp;&nbsp;{{ gameWorldStories[0] }}</p><br>
            <button class="gameWorldLinkButton" (click)="navGame()">Find More</button>
        </div>
    </div>
    <div class="gameWorld-container">
        <div class="textBlockLeft">
        <p class="p1">{{ gameWorldTitles[1] }}</p><br />
        <p class="p2">{{ gameWorldHeadlines[1] }}</p><br />
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" /><br />
        <p class="p3">&nbsp;&nbsp;&nbsp;&nbsp;{{ gameWorldStories[1] }}</p><br>
        <button class="gameWorldLinkButton" (click)="navMyths()">See More</button>
        </div>
        <div class="imageBlockRight">
            <!-- Side bar images-->
            <div class="sidebar-container">
                <img alt="Malware was here!" class="sidebarTop" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-03.webp" />
                <img alt="Malware was here!" class="sidebarLeft" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-01.png" />
                <img alt="Malware was here!" [src]="gameWorld2" class="gameWorldOverlay" />
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frameBorder.webp" class="gameFrameBorder" />
                <img alt="Malware was here!" class="sidebarRight" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-02.png" />
                <img alt="Malware was here!" class="sidebarBottom" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-04.webp" />
            </div>
        </div>
    </div>
    <div class="gameWorld-container">
        <div class="imageBlockLeft">
            <!-- Side bar images-->
            <div class="sidebar-container">
                <img alt="Malware was here!" class="sidebarTop" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-03.webp" />
                <img alt="Malware was here!" class="sidebarLeft" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-01.png" />
                <img alt="Malware was here!" [src]="gameWorld3" class="gameWorldOverlay" />
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frameBorder.webp" class="gameFrameBorder" />
                <img alt="Malware was here!" class="sidebarRight" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-02.png" />
                <img alt="Malware was here!" class="sidebarBottom" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-04.webp" />
            </div>
        </div>
        <div class="textBlockRight">
        <p class="p1">{{ gameWorldTitles[2] }}</p><br />
        <p class="p2">{{ gameWorldHeadlines[2] }}</p><br />
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" /><br />
        <p class="p3">&nbsp;&nbsp;&nbsp;&nbsp;{{ gameWorldStories[2] }}</p><br>
        <button class="gameWorldLinkButton" (click)="navClasses()">Read More</button>
        </div>
    </div>
    <div class="gameWorld-container">
        <div class="textBlockLeft">
        <p class="p1">{{ gameWorldTitles[3] }}</p><br />
        <p class="p2">{{ gameWorldHeadlines[3] }}</p><br />
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" /><br />
        <p class="p3">&nbsp;&nbsp;&nbsp;&nbsp;{{ gameWorldStories[3] }}</p><br>
            <button class="gameWorldLinkButton" (click)="navRealms()">Explore More</button>
        </div>
        <div class="imageBlockRight">
            <!-- Side bar images-->
            <div class="sidebar-container">
                <img alt="Malware was here!" class="sidebarTop" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-03.webp" />
                <img alt="Malware was here!" class="sidebarLeft" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-01.png" />
                <img alt="Malware was here!" [src]="gameWorld4" class="gameWorldOverlay" />
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frameBorder.webp" class="gameFrameBorder" />
                <img alt="Malware was here!" class="sidebarRight" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-02.png" />
                <img alt="Malware was here!" class="sidebarBottom" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-04.webp" />
            </div>
        </div>
    </div>
</div>
<app-gamemodes [homeRef]="this"></app-gamemodes>
<app-trailers></app-trailers>
