import { Component, Input, OnInit } from '@angular/core';
import { MythDetailComponent } from '../myth-detail/myth-detail.component';

@Component({
  selector: 'app-skin-button',
  templateUrl: './skin-button.component.html',
  styleUrls: ['./skin-button.component.css']
})
export class SkinButtonComponent implements OnInit {

  @Input() images;
  @Input() detail: MythDetailComponent;
  @Input() index;

  current = 2;

  ngOnInit(): void {
    if (this.index === 0) { this.current = 1; }
  }

  setCurrent() {
    this.detail.selectSkin(this.index);
    this.current = 1;
  }
}
