<div class="newsletter" id="newsletter">
    <div class="newsletter-container">
        <div class="newsletter-message">
            <p class="p1">Don't miss out!</p><br />
            <p class="p2">Sign up for the Ethereal Newsletter!</p>
        </div>
        <br />
        <br />
        <div *ngIf="checking" id="checkingMessage">
            <p>Checking your email...</p>
        </div>
        <div *ngIf="hasSubmissionError" id="newsletterError">
            <p>{{newsletterError}}</p>
        </div>
        <div class="signup-container">
            <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/envelope.png"
                style="height: 30px; width: auto; margin-top: 2em; margin-right: 1em;" />
            <input id="email" class="newsletter-signup" type="text" placeholder="Enter your email address" />
            <button class="newsletterButton" (click)="signup()" (keyup.enter)="signup()"></button>
        </div>
        <br />
        <p *ngIf="show" class="p3">Join the</p>
        <p *ngIf="show" class="p4">Community</p>
        <br />
        <img alt="Malware was here!" *ngIf="show" class="newsletter-line-bottom" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/title-center-line.webp" />
    </div>
</div>
<div *ngIf="displayConfirmationModal" id="newsletterConfirmationModal">
    <div class="newsletter-modal-header">
        <div>
            <button (click)="closeModal()" id="displayConfirmationModalClose">
                <fa-icon [icon]="['fas', 'times']" ></fa-icon>
            </button>
        </div>
    </div>
    <div class="newsletter-modal-content">
        <div class="newsletter-modal-content-title">Newsletter</div>
        <div class="newsletter-modal-content-success">Success</div>
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/title-center-line.webp" />
        <p class="newsletter-modal-content-thank-you">Thank you for signing up!</p>
    </div>
</div>