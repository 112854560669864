import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  isHeadless = false;

  loggedIn = false;
  showOverview = true;
  showMatchHistory = false;
  showRankedStanding = false;
  showClanInformation = false;
  user = {
    username: '',
    password: '',
    email: '',
    playerName: '',
    accountId: 0,
    authToken: '',
    expiresInSeconds: 0
  };
  profile;
  status;
  statistics;
  coins;
  options;
  friendIds = [];
  friends = [];
  playTime;
  portraitMax = 42;
  portraits = [];
  searchResults;
  matchHistory = [];
  portraitMenu = false;
  statusEnum = [
    'Online',
    'Offline',
    'Queueing',
    'InGame'
  ];
  needKey = true;
  key = '';
  keyRedeemed = false;
  keyFailed = false;
  friendRequestIds = [];
  friendRequests = [];
  showFriendRequests = false;
  needUsername = true;
  updaterLink = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Updater/Ethereal_Installer.exe';
  showLink = false;
  friendsListSub;
  friendRequestSub;
  refreshSub;

  @HostListener('document:keydown', ['$event']) onKeyDown($event) {
    if (this.loggedIn) {
      if ($event.key === 'Enter' && this.needKey) {
        this.submitKey();
      } else if ($event.key === 'Enter' && this.needUsername) {
        this.setName();
      }
    }
  }

  constructor(private comms: InternalComms, private route: ActivatedRoute, private swag: SwaggerService) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.isHeadless = params.isHeadless;
    });

    this.comms.setCurrent('navProfile');
    for (let i = 0; i <= this.portraitMax; i++) {
      this.portraits[i] = i.toString() + '.png';
    }
  }

  login(user, stayLoggedIn) {
    this.user = user;
    this.loggedIn = true;
    if (this.user.playerName !== null) {
      this.needUsername = !(this.user.playerName.length > 3);
    } else {
      this.needUsername = true;
    }
    this.needKey = false;
    // this.comms.checkPreAlphaStatus(this.user.authToken).subscribe(response => {
    //   console.log('Pre-Alpha check passed');
    //   // console.log(response);
    //   this.needKey = false;
    // }, error => {
    //   console.log('Pre-Alpha check failed');
    //   console.log(error);
    // });
    // tslint:disable-next-line: deprecation
    this.comms.profile(this.user).subscribe(responseProfile => {
      this.profile = responseProfile;
      this.refresh();
      this.comms.getCoins(this.user).subscribe(responseCoins => {
        this.coins = responseCoins;
        this.comms.getPortrait(this.user).subscribe(responseGetPortrait => {
          console.log(responseGetPortrait);
        }, error => {
          console.log(error);
        });
      });
      // // tslint:disable-next-line: deprecation
      // this.comms.status(this.user).subscribe(responseStatus => {
      //   this.status = responseStatus.info[0];
      //   // tslint:disable-next-line: deprecation
      //   this.comms.statistics(this.user).subscribe(responseStats => {
      //     this.statistics = responseStats.statistics[0];
      //     // console.log('Statistics');
      //     // console.log(this.statistics);
      //     if (this.statistics !== undefined) {
      //       if (this.statistics.timePlayed !== undefined) {
      //         this.playTime = new Date(this.statistics.timePlayed * 1000).toISOString().split('T')[1].split('.')[0];
      //         // console.log(this.playTime);
      //       }
      //     }
      //     // tslint:disable-next-line: deprecation
      //     this.comms.getFriendsList(this.user).subscribe(responseFriends => {
      //       this.friendIds = [];
      //       responseFriends.friends.forEach(element => {
      //         this.friendIds[this.friendIds.length] = element.friendId;
      //       });
      //       if (this.friendIds.length > 0) {
      //         // tslint:disable-next-line: deprecation
      //         this.comms.getFriendsProfiles(this.user, this.friendIds).subscribe(responseFriendProfiles => {
      //           this.friends = responseFriendProfiles.info;
      //           this.friends.sort((a, b) => a.username.localeCompare(b.username));
      //           this.friends.sort((a, b) => (a.status - b.status));
      //           // tslint:disable-next-line: deprecation
      //           this.comms.getCoins(this.user).subscribe(responseCoins => {
      //             this.coins = responseCoins;
      //             this.refresh();
      //             this.updateFriendsList(false);
      //             this.updateFriendRequests();
      //             if (!this.needKey) {
      //               // tslint:disable-next-line: deprecation
      //               this.comms.getMatchHistory(this.user).subscribe(responseMatchHistory => {
      //                 this.matchHistory = responseMatchHistory.results;
      //                 // console.log('Match History');
      //                 // console.log(this.matchHistory);
      //                 this.comms.getOptions(user).subscribe(responseGetOptions => {
      //                   // console.log('Options');
      //                   // console.log(responseGetOptions.options);
      //                 }, error => {
      //                   console.log(error);
      //                 });
      //               }, error => {
      //                 console.log(error);
      //               });
      //             }
      //           }, error => {
      //             console.log(error);
      //           });
      //         }, error => {
      //           console.log(error);
      //         });
      //       } else {
      //         this.comms.getCoins(this.user).subscribe(responseCoins => {
      //           this.coins = responseCoins;
      //           this.refresh();
      //           this.updateFriendsList(false);
      //           this.updateFriendRequests();
      //           // tslint:disable-next-line: deprecation
      //           this.comms.getMatchHistory(this.user).subscribe(responseMatchHistory => {
      //             this.matchHistory = responseMatchHistory.results;
      //             this.comms.getOptions(user).subscribe(responseGetOptions => {
      //               // console.log(responseGetOptions.options);
      //             }, error => {
      //               console.log(error);
      //             });
      //           }, error => {
      //             console.log(error);
      //           });
      //         }, error => {
      //           console.log(error);
      //         });
      //       }
      //     }, error => {
      //       console.log(error);
      //     });
      //   }, error => {
      //     console.log(error);
      //   });
      // }, error => {
      //   console.log(error);
      // });
    }, error => {
      console.log(error);
      this.login(user, stayLoggedIn);
    });
  }

  logout() {
    this.loggedIn = false;
    this.showOverview = true;
    this.showMatchHistory = false;
    this.showRankedStanding = false;
    this.showClanInformation = false;
    this.user = {
      username: '',
      password: '',
      email: '',
      playerName: '',
      accountId: 0,
      authToken: '',
      expiresInSeconds: 0
    };
    this.profile = undefined;
    this.status = undefined;
    this.statistics = undefined;
    this.coins = undefined;
    this.options = undefined;
    this.friendIds = [];
    this.friends = [];
    this.playTime = undefined;
    this.searchResults = undefined;
    this.matchHistory = [];
    this.portraitMenu = false;
    this.needKey = true;
    this.key = '';
    this.keyRedeemed = false;
    this.keyFailed = false;
    this.friendRequestIds = [];
    this.friendRequests = [];
    this.showFriendRequests = false;
    this.needUsername = true;
    this.showLink = false;
    clearTimeout(this.friendsListSub);
    clearTimeout(this.friendRequestSub);
    clearTimeout(this.refreshSub);
  }

  setName() {
    if (this.user.playerName.length > 3) {
      if (this.user.playerName.length < 21) {
        this.swag.setDisplayName(this.user.playerName, this.user.authToken).subscribe(response => {
          this.needUsername = false;
          this.profile.username = this.user.playerName;
        }, error => {
          console.log(error);
          if (error.status === 403) {
            if (this.user.playerName.includes('UG') || this.user.playerName.includes('ug')
              || this.user.playerName.includes('Ug') || this.user.playerName.includes('uG')) {
              alert('Your displayed name cannot include the Undying Games UG tag. Please choose another name.');
            } else {
              alert('That name is either in use or violates our vulgarity policy. Please choose another name.');
            }
          }
        });
      } else {
        alert('Displayed names cannot be longer than 20 characters.');
      }
    } else {
      alert('Displayed names must be more than 3 characters long.');
    }
  }

  submitKey() {
    this.keyFailed = false;
    this.keyRedeemed = false;
    this.comms.redeemKey(this.user.authToken, this.key). subscribe(keyResponse => {
      console.log(keyResponse);
      if (keyResponse) {
        this.needKey = false;
        this.keyRedeemed = true;
      }
    }, error => {
      console.log(error);
      this.keyFailed = true;
      this.comms.checkPreAlphaStatus(this.user.authToken).subscribe(preAlphaResponse => {
        console.log('Pre-Alpha check passed');
        this.needKey = false;
        this.keyRedeemed = true;
        this.keyFailed = false;
      }, preAlphaError => {
        console.log('Pre-Alpha check failed');
      });
    });
  }

  updateFriendsList(oneCycle) {
    this.friendsListSub = setTimeout( () => {
      this.comms.getFriendsList(this.user).subscribe(responseFriends => {
        this.friendIds = [];
        responseFriends.friends.forEach(element => {
          this.friendIds[this.friendIds.length] = element.friendId;
        });
        if (this.friendIds.length > 0) {
          // tslint:disable-next-line: deprecation
          this.comms.getFriendsProfiles(this.user, this.friendIds).subscribe(responseFriendProfiles => {
            this.friends.forEach(friend => {
              let found = false;
              responseFriendProfiles.info.forEach(updatedFriend => {
                if (updatedFriend.username === friend.username) {
                  found = true;
                }
              });
              if (!found) {
                this.friends.splice(friend, 1);
                console.log('Friend removed from list');
              }
            });
            responseFriendProfiles.info.forEach(updatedFriend => {
              let found = false;
              this.friends.forEach(friend => {
                if (updatedFriend.username === friend.username) {
                  found = true;
                  friend.status = updatedFriend.status;
                  friend.portraitId = updatedFriend.portraitId;
                  return;
                }
              });
              if (!found) {
                this.friends[this.friends.length] = updatedFriend;
              }
            });
            this.friends.sort((a, b) => a.username.localeCompare(b.username));
            this.friends.sort((a, b) => (a.status - b.status));
            if (!oneCycle) {
              this.updateFriendsList(false);
            }
          }, error => {
            console.log(error);
            if (!oneCycle) {
              this.updateFriendsList(false);
            }
          });
        } else {
          this.friends = [];
        }
      }, error => {
        console.log(error);
        if (!oneCycle) {
          this.updateFriendsList(false);
        }
      });
    }, 30000);
  }

  updateFriendRequests() {
    this.friendRequestSub = setTimeout( () => {
      this.comms.getFriendRequests(this.user.authToken).subscribe(responseFriendRequests => {
        this.friendRequestIds = [];
        responseFriendRequests.requests.forEach(friendRequest => {
          if (this.friendRequestIds.indexOf(friendRequest.senderId) < 0) {
            this.friendRequestIds[this.friendRequestIds.length] = friendRequest.senderId;
          }
        });
        if (this.friendRequestIds.length > 0) {
          this.comms.getFriendsProfiles(this.user, this.friendRequestIds).subscribe(responseFriendRequestsProfiles => {
            this.friendRequests = responseFriendRequestsProfiles.info;
          });
        } else {
          this.friendRequests = [];
        }
        this.updateFriendRequests();
      });
    }, 15000);
  }

  acceptFriendRequest(id) {
    this.swag.acceptFriendRequest(this.user.accountId, id, this.user.authToken).subscribe(response => {
      this.friendRequests.forEach(element => {
        if (element.id === id) {
          element.sent = true;
        }
      });
      // console.log(response);
      this.toggleFriendRequestList();
      this.updateFriendsList(true);
    });
  }

  declineFriendRequest(id) {
    this.swag.declineFriendRequest(this.user.accountId, id, this.user.authToken).subscribe(response => {
      // console.log(response);
      this.toggleFriendRequestList();
      this.updateFriendsList(true);
    });
  }

  toggleFriendRequestList() {
    this.showFriendRequests = !this.showFriendRequests;
  }

  refresh() {
    this.refreshSub = setTimeout(() => {
      // tslint:disable-next-line: deprecation
      this.comms.refresh(this.user).subscribe(response => {
        this.user.authToken = response.authToken;
      }, error => {
        console.log(error);

        if(this.isHeadless) {
          this.comms.navigate('Profile?isHeadless=true');
        } else {
          this.comms.navigate('Profile');
        }
      });
      this.refresh();
      // 1000 is 1 second, so delays based on expiresInSeconds field - 5 seconds
    }, (this.user.expiresInSeconds - 5) * 1000);
  }

  showPortraitOptions() {
    // this.portraitMenu = true;
  }

  choosePortrait(portraitId) {
    this.portraitMenu = false;
    // tslint:disable-next-line: deprecation
    this.comms.choosePortrait(this.user, portraitId).subscribe(() => {
      // tslint:disable-next-line: deprecation
      this.comms.profile(this.user).subscribe(response => {
        this.profile = response.info[0];
      });
    });
  }

  changeSection(section) {
    this.showOverview = false;
    this.showMatchHistory = false;
    this.showRankedStanding = false;
    this.showClanInformation = false;
    switch (section) {
      case 'Overview':
        this.showOverview = true;
        break;
      case 'MatchHistory':
        this.showMatchHistory = true;
        break;
      case 'Ranked':
        this.showRankedStanding = true;
        break;
      case 'Clan':
        this.showClanInformation = true;
        break;
    }
    document.getElementById('scrollPointProfile').scrollIntoView(true);
  }

  onKeySearch(value) {
    this.searchResults = undefined;
    // tslint:disable-next-line: deprecation
    this.comms.getPlayerName(value, this.user.authToken).subscribe(response => {
      this.searchResults = response;
      // console.log(this.searchResults);
    }, error => {
      if (error.status === 404) {
        // console.log('Name not found');
      } else {
        console.log(error);
      }
    });
  }

  closePortraitMenu() {
    this.portraitMenu = false;
  }

  sendFriendRequest(id) {
    this.swag.sendFriendRequest(this.user.accountId, id, this.user.authToken).subscribe( () => {
      this.searchResults = undefined;
      (document.getElementById('searchBar') as HTMLInputElement).value = '';
    }, error => {
      console.log(error);
    });
  }
}
