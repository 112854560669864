<div class="change-password-container">
    <div class="account-form">
        <form>
            <div class="form-field">
                <span class="label">New Password</span><br />
                <input type="password" class="form-input" autocomplete="new-password" (input)="data.newPassword = $event.target.value" placeholder=" New Password"><br />
            </div>
            <div class="form-field">
                <span class="label">Confirm Password</span><br />
                <input type="password" class="form-input" autocomplete="confirm-password" (input)="confirmPassword = $event.target.value" placeholder=" Confirm Password"><br />
            </div>
        </form>
    <div>
        <p class="error-message">{{error}}</p>
        <button class="change-button" (click)="changePassword()">Change Password</button>
    </div>
</div>