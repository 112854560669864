<div class="section" style="display: flex; justify-content: center; align-items: center; flex-direction: column;" id="ServiceSection">
    <div class="overlay">
        <video autoplay loop src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/DustAndStarsBackground.webm"></video>
    </div>
    <div style="font-size: 1.5em;">
        <!-- <p>Server: <label style="color: white;">North America</label></p> -->
        <p *ngIf="statusTextNA === 'Offline'">Server status: <label style="color: red;">{{ statusTextNA }}</label></p>
        <p *ngIf="statusTextNA === 'Online'">Server status: <label style="color: green;">{{ statusTextNA }}</label></p>
    </div>
    <!-- <div style="font-size: 1.5em;">
        <p>Server: <label style="color: white;">Europe</label></p>
        <p *ngIf="statusTextEU === 'Offline'">Server status: <label style="color: red;">{{ statusTextEU }}</label></p>
        <p *ngIf="statusTextEU === 'Online'">Server status: <label style="color: green;">{{ statusTextEU }}</label></p>
    </div> -->
    <!-- <div style="font-size: 1.5em;">
        <p>Server: <label style="color: white;">Asia/Pacific</label></p>
        <p *ngIf="statusTextAP === 'Offline'">Server status: <label style="color: red;">{{ statusTextAP }}</label></p>
        <p *ngIf="statusTextAP === 'Online'">Server status: <label style="color: green;">{{ statusTextAP }}</label></p>
    </div> -->
    <app-share></app-share>
</div>