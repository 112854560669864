<div class="newsBlock">
  <img alt="Malware was here!" [src]="image1 || defaultCard" class="newsOverlay" (click)="gotoStory()"/>
  <img alt="Malware was here!" [src]="image2" class="newsFrameBorder" />
  <span class="newsTextBox" (click)="gotoStory()">
      <p class="p1">{{ headline }}</p>
      <div style="display: flex; justify-content: center; width: 100%; margin-top: .25em; margin-bottom: .25em;">
        <div class="dash"></div>
      </div>
      <p class="p2">
          {{ story }}
      </p>
  </span>
</div>
