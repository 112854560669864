<div *ngIf="success">
    <p>Registration successful.</p>
    <p>See you in the Ether!</p>
</div>
<div *ngIf="!success && !failure && !routeError">
    <p>Processing, please wait.</p>
</div>
<div *ngIf="failure">
    <p>Something went wrong. Please contact us via Discord.</p>
</div>
<div *ngIf="routeError">
    <p>Something tells me you don't belong here. -Malware</p>
</div>