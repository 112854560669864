import { Component, Input } from '@angular/core';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent {

  @Input() show = true;
  @Input() hasSubmissionError = false;
  @Input() displayConfirmationModal = false;
  @Input() newsletterError = '';
  checking = false;

  constructor(private swag: SwaggerService) {}

  signup() {
    this.hasSubmissionError = false;
    const emailElement = (document.getElementById('email') as HTMLInputElement);
    if (!this.isValidEmail(emailElement.value)) {
      this.hasSubmissionError = true;
      this.newsletterError = 'Please enter a valid email to receive our newsletter!';
    } else {
      this.checking = true;
      // tslint:disable-next-line: deprecation
      this.swag.sendEmail(emailElement.value, 1).subscribe(response => {
        emailElement.value = '';
        this.openModal();
        this.checking = false;
      }, error => {
        if (Number(error.status) === 409) {
          this.hasSubmissionError = true;
          this.newsletterError = 'This email has previously been used!';
        }
        else {
          this.hasSubmissionError = true;
          this.newsletterError = 'An error has occurred, please try again later or contact our team on the official Discord!';
        }

        this.checking = false;
      });
    }
  }

  isValidEmail(email) {
    if (!email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      return false;
    } else {
      return true;
    }
  }

  openModal() {
    this.displayConfirmationModal = true;
  }

  closeModal() {
    this.displayConfirmationModal = false;
  }
}
