import { Component, Input, AfterViewInit } from '@angular/core';
import { MythDetailComponent } from '../myth-detail/myth-detail.component';
import { UniverseComponent } from '../universe/universe.component';

@Component({
  selector: 'app-ability-button',
  templateUrl: './ability-button.component.html',
  styleUrls: ['./ability-button.component.css']
})
export class AbilityButtonComponent implements AfterViewInit {

  @Input() images: string[];
  @Input() slot;
  @Input() index;
  @Input() detail: MythDetailComponent;
  @Input() universe: UniverseComponent;
  @Input() name;
  @Input() clickable = true;
  @Input() realm;
  @Input() className;
  @Input() selected;
  current = 0;

  ngAfterViewInit(): void {
    const elem = document.getElementById('container ' + this.index);
    if (this.clickable) {
      elem.style.cursor = 'pointer';
    } else {
      elem.style.cursor = 'default';
    }
  }

  set() {
    if (this.detail !== null && this.detail !== undefined) {
      switch (this.index) {
        case -2:
          this.detail.navClass();
          break;
        case -1:
          this.detail.navRealm();
          break;
        default:
          this.detail.set(this.index);
          this.selected = true;
          break;
      }
    } else if (this.universe !== null && this.universe !== undefined) {
      this.universe.selectRealm(this.realm);
    }
  }

  hover() {
    if (this.images.length > 1) {
      this.current = 1;
    }
  }

  unhover() {
    this.current = 0;
  }
}
