import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { InternalComms } from '../internal-comms.service';
import { ProfileComponent } from '../profile/profile.component';
import { StoreComponent } from '../store/store.component';
import { ForumComponent } from '../forum/forum.component';
import { BugReportComponent } from '../bug-report/bug-report.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @Input() profile: ProfileComponent;
  @Input() store: StoreComponent;
  @Input() forum: ForumComponent;
  @Input() bugReport: BugReportComponent;

  user = {
    username: '',
    password: '',
    email: '',
    playerName: '',
    accountId: 0,
    authToken: '',
    expiresInSeconds: 0
  };
  password2 = '';
  isSigningUp = false;
  hasForgotPassword = false;
  wrongPassword = false;
  tosAccepted = false;
  privacyAccepted = false;
  signedIn = false;

  loginButton = [
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/login.png',
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/login_hover.png'
  ];
  currentLoginButton = 0;

  createButton = [
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/create.png',
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/create_hover.png'
  ];
  currentCreateButton = 0;

  backButton = [
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/back.png',
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/back_hover.png'
  ];
  currentBackButton = 0;

  signUpButton = [
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/sign_up.png',
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/sign_up_hover.png'
  ];
  currentSignUpButton = 0;

  resetButton = [
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/reset.png',
    'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/reset_hover.png'
  ];
  currentResetButton = 0;

  @HostListener('document:keydown', ['$event']) onKeyDown($event) {
    if ($event.key === 'Enter' && this.user.username.length > 0 && this.user.password.length > 0 && !this.isSigningUp) {
      this.login();
    } else if ($event.key === 'Enter' && this.isSigningUp) {
      this.signup();
    }
  }

  constructor(private comms: InternalComms, private cookie: CookieService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    setTimeout(() => {
      const elem = document.getElementById('rememberMe') as HTMLInputElement;
      if (this.cookie.get('username').length > 1) {
        this.user.username = this.cookie.get('username');
        if (elem !== null) {
          elem.checked = true;
        }
      }
      if (this.comms.getUser().accountId > 0) {
        this.user = this.comms.getUser();
        this.login();
      }
    }, 500);
    this.route.paramMap.subscribe(params => {
      if (params.has('forgotpassword')) {
        this.hasForgotPassword = true;
      }
    });
  }

  ngAfterViewInit(): void {

  }

  updateSignedInStatus() {
    setTimeout(() => {
      this.signedIn = (this.comms.getUser().accountId > 0);
      if (!this.signedIn) {
        if (this.profile !== undefined) {
          this.profile.logout();
        } else if (this.store !== undefined) {
          // this.store.logout();
        } else if (this.forum !== undefined) {
          // this.forum.logout();
        } else if (this.bugReport !== undefined) {
          // this.bugReport.logout();
        }
        const elem = document.getElementById('rememberMe') as HTMLInputElement;
        if (this.cookie.get('username').length > 1) {
          this.user.username = this.cookie.get('username');
          if (elem !== null) {
            elem.checked = true;
          }
        } else {
          if (elem !== null) {
            if (!elem.checked) {
              this.user.username = '';
              this.cookie.delete('username');
            }
          }
        }
        this.user.password = '';
        this.user.email = '';
        this.user.playerName = '';
        this.user.accountId = 0;
        this.user.authToken = '';
        this.password2 = '';
        this.isSigningUp = false;
        this.hasForgotPassword = false;
        this.wrongPassword = false;
        this.tosAccepted = false;
        this.privacyAccepted = false;
        this.signedIn = false;
      }
      this.updateSignedInStatus();
    }, 500);
  }

  setLoginButton(n) {
    this.currentLoginButton = n;
  }

  setCreateButton(n) {
    this.currentCreateButton = n;
  }

  setSignUpButton(n) {
    this.currentSignUpButton = n;
  }

  setBackButton(n) {
    this.currentBackButton = n;
  }

  setResetButton(n) {
    this.currentResetButton = n;
  }

  login() {
    const elem = document.getElementById('rememberMe') as HTMLInputElement;
    if (elem.checked) {
      this.cookie.set('username', this.user.username);
    } else {
      this.cookie.set('username', '');
    }
    this.comms.login(this.user.username, this.user.password, false).subscribe(response => {
      this.user.accountId = response.accountId;
      this.user.authToken = response.authToken;
      this.user.playerName = response.displayName;
      this.user.password = '';
      this.user.email = '';
      this.user.expiresInSeconds = response.expiresInSeconds;
      this.comms.setUser(this.user);
      if (this.profile !== undefined) {
        this.profile.login(this.user, elem.checked);
      } else if (this.store !== undefined) {
        this.store.login(this.user, elem.checked);
      } else if (this.forum !== undefined) {
        this.forum.login(this.user, elem.checked);
      } else if (this.bugReport !== undefined) {
        this.bugReport.login(this.user, elem.checked);
      }
      this.updateSignedInStatus();
    }, error => {
      if (error.error.error === 'USERNAME_OR_PASSWORD_NOT_CORRECT' || error.error.error === 'WRONG_CREDENTIALS') {
        this.wrongPassword = true;
        this.user.password = '';
        alert('Incorrect password for this account name');
      } else if (error.error.error === 'NOT_ENOUGH_PERMISSIONS')
      {
        alert('Log in unavailable at this time, try again later.')
      }


      if (error.status === 503) {
        alert('Servers are offline, please wait until our next event.');
      }
    });
  }

  signup() {
    // tslint:disable-next-line: max-line-length
    if (this.user.password === this.password2 && this.user.password.length > 7 && this.user.username.length > 3
        && this.user.email.length > 0 && this.user.email.indexOf('@') > 0) {
      // tslint:disable-next-line: deprecation
      this.comms.signup(this.user).subscribe(response => {
        //alert('Please check your email to confirm your account');
        alert('Your account was created successfully, you may now log in.');
        this.login();
      }, error => {
        alert(error.error.error);
      });
    } else if (this.user.password !== this.password2) {
      alert('Your passwords do not match.');
    } else if (this.user.password.length < 8) {
      alert('Your password must be at least 8 characters long.');
    } else if (this.user.username.length < 4) {
      alert('Your account name must be at least 4 characters long.');
    } else if (this.user.email.length === 0 || this.user.email.indexOf('@') < 0) {
      alert('Please provide a valid email address.');
    }
  }

  forgotPassword() {
    if (this.user.email.length > 0 || this.user.username.length > 0) {
      alert('If your account was found, we will send an email with instructions on how to reset your password.');
      this.hasForgotPassword = false;
      this.comms.forgotPassword(this.user.email, this.user.username).subscribe(response => {
      }, error => {
        
      });
    }
  }

  warning() {
    const elem = document.getElementById('rememberMe') as HTMLInputElement;
    if (elem.checked) {
      alert('Do not select if this is a public computer.');
    } else {
      this.cookie.delete('username');
    }
  }

  checkRemember() {
    const elem = document.getElementById('rememberMe') as HTMLInputElement;
    elem.checked = !elem.checked;
    this.warning();
  }

  acceptTerms() {
    this.tosAccepted = true;
  }

  acceptPrivacy() {
    this.privacyAccepted = true;
  }

  cancel() {
    this.tosAccepted = false;
    this.privacyAccepted = false;
    this.isSigningUp = false;
    this.hasForgotPassword = false;
  }
}
