import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  newsLink: number;
  ismobile = false;
  tops = ['https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Top_2.png', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Top.png'];
  currentTop = 0;
  currentVersion = 'Closed Testing';
  currentMessage = 'Stay tuned!';
  showGame = false;
  showOfficial = false;
  showMyAccount = false;
  showCodex = false;
  showAbout = false;
  updaterLink = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Updater/Ethereal_Installer.exe';
  today;
  eventDate;
  eventEnd;
  signedIn = false;

  // this is specifically for the button that will appear so you can jump back to the top
  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    if (!this.ismobile) {
      const elem = document.getElementById('topScroll');
      const elem2 = document.getElementById('bottomScroll');
      if (elem.getBoundingClientRect().top + window.scrollY > 400) {
        elem.style.opacity = '1';
        elem.style.cursor = 'pointer';
        elem2.style.opacity = '1';
        elem2.style.cursor = 'pointer';
      } else {
        elem.style.opacity = '0';
        elem.style.cursor = 'default';
        elem2.style.opacity = '0';
        elem2.style.cursor = 'default';
      }
    }
  }

  constructor(private router: Router, private comms: InternalComms, private route: ActivatedRoute, private swagger: SwaggerService) { }

  ngOnInit(): void {
    this.comms.header = this;
    if (window.screen.availWidth <= 425) {
      this.ismobile = true;
    }
    this.today = new Date();
    // year, month starts at 0 (0-11), day, hours, mins, seconds, milli
    this.eventDate = new Date(2022, 3, 28, 0, 0, 0, 0);
    this.eventEnd = new Date(2022, 4, 2, 0, 0, 0, 0);
    // if (this.today.getTime() >= this.eventDate.getTime()) {
    //   this.currentMessage = 'Join us for Open Testing!';
    //   this.currentVersion = 'Open Testing v2.0';
    // }
    // if (this.today.getTime() >= this.eventEnd.getTime()) {
    //   this.currentMessage = 'Thanks for joining us during our Open Weekend! Stay tuned!';
    //   this.currentVersion = 'Closed Testing';
    // }
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      (document.getElementById('background') as HTMLVideoElement).muted = true;
      (document.getElementById('background') as HTMLVideoElement).play();
      console.log('Website Version 6.2.6');
      if(!window.location.href.includes('localhost:4200')) {
        window.location.href = 'https://etherealcos.com'; 
      }
    }, 2000);
    this.updateSignedInStatus();
  }

  updateSignedInStatus() {
    setTimeout(() => {
      this.signedIn = (this.comms.getUser().accountId > 0);
      this.updateSignedInStatus();
    }, 500);
  }

  signout() {
    this.comms.signout();
    this.signedIn = false;
  }

  merch() {
    window.open('https://undyinggames.company.site/');
  }

  toTop() {
    const elem = document.getElementById('topScroll');
    if (elem.getBoundingClientRect().top + window.scrollY > 400) {
      window.scrollTo(0, 0);
    }
  }

  toBottom() {
    window.scrollTo(0, document.body.offsetHeight);
  }

  hover() {
    this.currentTop = 1;
  }

  unhover() {
    this.currentTop = 0;
  }

  toggleView(path: string) {
    const elem = document.getElementById(path);
    const elems = document.getElementsByClassName('nav');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < elems.length; i++) {
      if (elem.id !== elems[i].id) {
        document.getElementById(elems[i].id).style.color = 'silver';
        document.getElementById(elems[i].id).style.borderBottom = 'none';
      }
    }
    if (elem !== null) {
      elem.style.color = 'rgb(202, 166, 91)';
      elem.style.borderBottom = '.2em solid rgb(202, 166, 91)';
    }
  }

  navigate(page: string) {
    this.showGame = false;
    this.showOfficial = false;
    this.showMyAccount = false;
    this.showCodex = false;
    this.showAbout = false;
    this.router.navigate(['Home']);
    this.router.navigate([page]);
  }

  showMenu(menu: string) {
    this.showGame = false;
    this.showOfficial = false;
    this.showMyAccount = false;
    this.showCodex = false;
    this.showAbout = false;
    switch (menu) {
      case 'Game':
        this.showGame = true;
        break;
      case 'Codex':
        this.showCodex = true;
        break;
      case 'Official':
        this.showOfficial = true;
        break;
      case 'My Account':
        this.showMyAccount = true;
        break;
      case 'About':
        this.showAbout = true;
        break;
    }
  }

  jumpToRegister() {
    const elem = document.getElementById('newsletter');
    window.scrollTo(0, elem.offsetTop);
  }

  // social functions, these always open in a new window

  trailer() {
    window.open('https://www.youtube.com/embed/bPfS7XiIihU');
  }

  facebook() {
    window.open('https://www.facebook.com/EtherealClashOfSouls/');
  }

  reddit() {
    window.open('https://www.reddit.com/r/Undying_Games/');
  }

  instagram() {
    window.open('https://www.instagram.com/ug_ethereal/');
  }

  discord() {
    window.open('https://discord.gg/HBKbtFm');
  }

  youtube() {
    window.open('https://www.youtube.com/channel/UCBuULwnagsHgSZeEKHoodNQ');
  }

  twitter() {
    window.open('https://twitter.com/ug_ethereal?lang=en');
  }

  twitch() {
    window.open('https://www.twitch.tv/undying_games');
  }

  patreon() {
    window.open('https://www.patreon.com/UndyingGames');
  }
}
