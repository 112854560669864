import { Component, OnInit } from '@angular/core';
import { InternalComms } from '../internal-comms.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SwaggerService } from '../swagger.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-myths',
  templateUrl: './myths.component.html',
  styleUrls: ['./myths.component.css']
})
export class MythsComponent implements OnInit {

  isHeadless = false;

  showDetail = false;
  currentMyth = 0;
  myths = [];
  lore = [];
  selected;
  filtered = false;
  currentClass = undefined;
  allDataLoaded = false;
  currentDifficulty = 0;

  filters = [
    [['Class'], ['https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/MythFilterIcons/classes.png'], []],
    [['Combat Style'], ['https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/MythFilterIcons/combat_style.png'], []],
    [['Damage Type'], ['https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/MythFilterIcons/damage_type.png'], []],
    [['Realm'], ['https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/MythFilterIcons/realm.png'], []],
  ];

  difficulty = [
    'None',
    'Basic',
    'Moderate',
    'Veteran'
  ];

  constructor(private comms: InternalComms, private router: Router, private route: ActivatedRoute, private swagger: SwaggerService) {}

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.isHeadless = params.isHeadless;
    });

    this.comms.setCurrent('navGame');
    // get all myth data
    this.swagger.getAllMyths().subscribe(response => {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < response.length; i++) {
        const temp = JSON.parse(response[i].data);
        if (temp.released === 'Yes') {
          this.myths[this.myths.length] = JSON.parse(response[i].data);
        }
      }
      this.swagger.setMyths(response);
      // sort myths by name
      this.myths.sort((a, b) => a.mythname.localeCompare(b.mythname));
      for (const myth of this.myths) {
        if (myth.release === this.myths.length) {
          const newest = myth;
          this.myths.splice(this.myths.indexOf(newest));
          this.myths.unshift(newest);
        }
      }
      // set the selected myths to all
      this.selected = this.myths;
      // add filters
      const classesFilters = [];
      const combatStyleFilters = [];
      const damageTypeFilters = [];
      const realmFilters = [];
      for (const myth of this.myths) {
        if (classesFilters.findIndex((subArray) => (subArray.indexOf(myth.class) !== -1)) < 0) {
          classesFilters.push([myth.class, myth.classimage[0]]);
        }
        if (combatStyleFilters.findIndex((subArray) => (subArray.indexOf(myth.combatstyle) !== -1)) < 0) {
          combatStyleFilters.push([myth.combatstyle, myth.combatstyleimage[0]]);
        }
        if (damageTypeFilters.findIndex((subArray) => (subArray.indexOf(myth.movement) !== -1)) < 0) {
          damageTypeFilters.push([myth.DamageType, myth.DamageTypeIcon]);
        }
        if (realmFilters.findIndex((subArray) => (subArray.indexOf(myth.realm) !== -1)) < 0) {
          realmFilters.push([myth.realm, myth.realmimage[0]]);
        }
      }
      this.filters[0][2] = classesFilters;
      this.filters[1][2] = combatStyleFilters;
      this.filters[2][2] = damageTypeFilters;
      this.filters[3][2] = realmFilters;
      for (const filter of this.filters) {
        filter[2].sort((a, b) => a[0].localeCompare(b[0]));
      }
      //////////////////////////////////////////////////////////////////////
      // tslint:disable-next-line: deprecation
      this.route.paramMap.subscribe(params => {
        if (params.has('id') && +params.get('id') < this.myths.length) {
          this.showDetail = true;
          this.currentMyth = +params.get('id');
        } else if (params.has('id')) {
          for (let i = 0; i < this.myths.length; i++) {
            if (this.myths[i].mythname === params.get('id')) {
              this.showDetail = true;
              this.currentMyth = i;
            }
          }
        }
        this.getAllLore();
      });
    });
  }

  getAllLore() {
    // tslint:disable-next-line: deprecation
    this.swagger.getAllCodex().subscribe(response3 => {
      this.swagger.setCodex(response3);
      for (let i = 0; i < response3.length; i++) {
        this.lore[i] = JSON.parse(response3[i].data);
      }
      this.allDataLoaded = true;
    });
  }

  findLore(n: number) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.lore.length; i++) {
      if (this.lore[i].loreIndex === n) {
        return this.lore[i];
      }
    }
    return null;
  }

  reset() {
    this.router.navigate(['Myths']);
  }

  navigate(page) {
    if(this.isHeadless) {
      this.router.navigate([page], {
        queryParams: {
          isHeadless: true
        }
      });
    } else {
      this.router.navigate([page]);
    }
  }

  selectMyth(mythRelease) {
    let navRoute = 'Myths/';

    if (mythRelease > 0) {
      let temp;
      this.myths.forEach(myth => {
        if (myth.release === mythRelease) {
          temp = myth.mythname;
        }
      });

      navRoute += temp
    }

    if(this.isHeadless) {
      this.router.navigate([navRoute], {
        queryParams: {
          isHeadless: true
        }
      });
    }
    else {
      this.router.navigate([navRoute]);
    }
  }

  navLore(myth: string) {
    const navRoute = 'Codex/Lore/' + myth

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.myths.length; i++) {
      if (this.myths[i].mythname === myth) {
        if(this.isHeadless) {
          this.router.navigate([navRoute], {
            queryParams: {
              isHeadless: true
            }
          });
        }
        else {
          this.router.navigate([navRoute]);
        }
      }
    }
  }

  navRealm(realm: string) {
    const navRoute = 'Realms/' + realm;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.myths.length; i++) {
      if (this.myths[i].realm === realm) {
        if(this.isHeadless) {
          this.router.navigate([navRoute], {
            queryParams: {
              isHeadless: true
            }
          });
        }
        else {
          this.router.navigate([navRoute]);
        }
      }
    }
  }

  navClass(navClass: string) {
    const navRoute = 'Classes/' + navClass;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.myths.length; i++) {
      if (this.myths[i].class === navClass) {
        if(this.isHeadless) {
          this.router.navigate([navRoute], {
            queryParams: {
              isHeadless: true
            }
          });
        }
        else {
          this.router.navigate([navRoute]);
        }        
      }
    }
  }

  onKeySearch(value) {
    const elems = document.getElementsByClassName('checkbox-filter');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < elems.length; i++) {
      const elem = document.getElementById(elems[i].id) as HTMLInputElement;
      if (elem) {
        elem.checked = true;
      }
    }
    this.selected = [];
    for (const myth of this.myths) {
      if (this.selected.indexOf(myth) < 0 && myth.mythname.toLowerCase().includes(value.toLowerCase())) {
        this.selected.push(myth);
      }
    }
  }

  toggleFilterMenus(menu) {
    let elem = null;
    let visible = '';
    if (menu !== '') {
      elem = document.getElementById(menu);
      visible = elem.style.visibility;
    }
    let elems = document.getElementsByClassName('dropdown');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < elems.length; i++) {
      if (elems[i].id !== menu) {
        const temp = document.getElementById(elems[i].id);
        temp.style.visibility = 'collapse';
        const connector = document.getElementById(elems[i].id + 'Connector');
        if (connector) {
          connector.style.visibility = 'collapse';
        }
      }
    }
    if (elem !== null) {
      if (visible === 'visible') {
        elem.style.visibility = 'collapse';
        const connector = document.getElementById(elem.id + 'Connector');
        if (connector) {
          connector.style.visibility = 'collapse';
        }
      } else {
        elem.style.visibility = 'visible';
        const connector = document.getElementById(elem.id + 'Connector');
        if (connector) {
          connector.style.visibility = 'visible';
        }
      }
    }
    elems = document.getElementsByClassName('dropdownSmall');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < elems.length; i++) {
      if (elems[i].id !== menu) {
        const temp = document.getElementById(elems[i].id);
        temp.style.visibility = 'collapse';
      }
    }
    if (elem) {
      if (visible === 'visible') {
        elem.style.visibility = 'collapse';
      } else {
        elem.style.visibility = 'visible';
      }
    }
  }

  changeDifficulty(n) {
    this.currentDifficulty = n;
    if (n === 0) {
      this.clearFilters();
    } else {
      this.filter();
    }
  }

  toggleFilter(filterName) {
    if (filterName === 'None') {
      this.clearFilters();
    } else {
      const elem = document.getElementById(filterName + 'Check') as HTMLInputElement;
      if (elem.checked) {
        elem.checked = false;
      } else {
        elem.checked = true;
      }
    }
    this.filter();
  }

  filter() {
    const searchBar = document.getElementById('searchBar') as HTMLInputElement;
    if (searchBar) {
      searchBar.value = '';
    }
    this.selected = [];
    for (const myth of this.myths) {
      const classCheck = document.getElementById(myth.class + 'Check') as HTMLInputElement;
      const combatCheck = document.getElementById(myth.combatstyle + 'Check') as HTMLInputElement;
      const damageCheck = document.getElementById(myth.DamageType + 'Check') as HTMLInputElement;
      const realmCheck = document.getElementById(myth.realm + 'Check') as HTMLInputElement;
      let difficultyCheck;
      if (this.currentDifficulty === 0) {
        difficultyCheck = true;
      } else {
        difficultyCheck = (myth.difficulty === this.difficulty[this.currentDifficulty]);
      }
      if (classCheck.checked && combatCheck.checked && damageCheck.checked && realmCheck.checked && difficultyCheck) {
        this.selected.push(myth);
      }
    }
  }

  resetCheck(value) {
    if (value === 'Clear') {
      this.clearFilters();
    } else {
      this.toggleFilter(value);
    }
  }

  clearFilters() {
    this.selected = this.myths;
    const elems = document.getElementsByClassName('checkbox-filter');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < elems.length; i++) {
      const elem = document.getElementById(elems[i].id) as HTMLInputElement;
      if (elem) {
        elem.checked = true;
      }
    }
    const searchBar = document.getElementById('searchBar') as HTMLInputElement;
    if (searchBar) {
      searchBar.value = '';
    }
  }

  flipSelection(n) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.filters[n][2].length; i++) {
      const box = document.getElementById(this.filters[n][2][i][0] + 'Check') as HTMLInputElement;
      box.checked = true;
    }
    this.filter();
  }

  showToolTip(value) {
    const elems = document.getElementsByClassName('flipToolTip');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < elems.length; i++) {
      const temp = document.getElementById(elems[i].id);
      temp.style.visibility = 'collapse';
    }
    if (value) {
      const elem = document.getElementById(value + 'Flip');
      elem.style.visibility = 'visible';
    }
  }
}
