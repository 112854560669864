import { Component, OnDestroy, OnInit } from '@angular/core';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit, OnDestroy {

  statusTextNA = 'Offline';
  statusTextEU = 'Offline';
  statusTextAP = 'Offline';
  timeout;

  constructor(private swagger: SwaggerService, private comms: InternalComms) { }

  ngOnInit(): void {
    this.comms.setCurrent('navCommunity');
    // this.updateHealth();
  }

  ngOnDestroy(): void {
      clearTimeout(this.timeout);
  }

  // TODO: Implement with new services
  updateHealth() {
    /*
    this.timeout = setTimeout( () => {
      if (document.getElementById('ServiceSection')) {
        this.swagger.checkServerOptions('NA').subscribe(optionsResponse => {
          if (optionsResponse.isMaintenanceMode) {
            this.statusTextNA = 'Offline';
          } else {
            this.swagger.checkServerStatus('NA').subscribe(serviceResponse => {
              if (!serviceResponse.accountsServiceHealth || !serviceResponse.chatServiceHealth
                    || !serviceResponse.matchmakingServiceHealth || !serviceResponse.sessionStorageHealth) {
                  this.statusTextNA = 'Offline';
                } else {
                  this.statusTextNA = 'Online';
                }
              this.swagger.checkMatchmakingService().subscribe(matchmakingResponse => {
                if (!matchmakingResponse.api)
                {
                  this.statusTextNA = 'Offline';
                } else {
                  this.statusTextNA = 'Online';
                }
              });
            });
          }
        });
        this.swagger.checkServerOptions('EU').subscribe(optionsResponse => {
          if (optionsResponse.isMaintenanceMode) {
            this.statusTextEU = 'Offline';
          } else {
            this.swagger.checkServerStatus('EU').subscribe(serviceResponse => {
              if (!serviceResponse.accountsServiceHealth || !serviceResponse.chatServiceHealth
                    || !serviceResponse.matchmakingServiceHealth || !serviceResponse.sessionStorageHealth) {
                  this.statusTextEU = 'Offline';
                } else {
                  this.statusTextEU = 'Online';
                }
              this.swagger.checkMatchmakingService().subscribe(matchmakingResponse => {
                  if (!matchmakingResponse.api)
                  {
                    this.statusTextNA = 'Offline';
                  } else {
                    this.statusTextNA = 'Online';
                  }
                });
            });
          }
        });
        this.swagger.checkServerOptions('AP').subscribe(optionsResponse => {
          if (optionsResponse.isMaintenanceMode) {
            this.statusTextAP = 'Offline';
          } else {
            this.swagger.checkServerStatus('AP').subscribe(serviceResponse => {
              if (!serviceResponse.accountsServiceHealth || !serviceResponse.chatServiceHealth
                    || !serviceResponse.matchmakingServiceHealth || !serviceResponse.sessionStorageHealth) {
                  this.statusTextAP = 'Offline';
                } else {
                  this.statusTextAP = 'Online';
                }
              this.swagger.checkMatchmakingService().subscribe(matchmakingResponse => {
                  if (!matchmakingResponse.api)
                  {
                    this.statusTextNA = 'Offline';
                  } else {
                    this.statusTextNA = 'Online';
                  }
               });
            });
          }
        });
        this.updateHealth();
      }
    }, 1000);
    */
  }
}
