<div style="background-color: rgba(24,24,24,1)">
  <div *ngIf="realms.length > 0" style="display: flex; justify-content: center; width: 100%;">
    <div id="realm-container">
      <canvas id="shootingStarCanvas" height="10" width="10" ></canvas>
      <app-realm-button *ngFor="let item of realms; index as i;" [realm]="item" [universe]="this" [index]="i">
      </app-realm-button>
      <div id="realmCard" *ngIf="realmCard.length > 0">
        <img alt="Malware was here!" [src]="realmCard" style="width: 10vw; position: absolute; top: 70%; left: 85%;">
      </div>
    </div>
  </div>
  <div style="width: 100%; display: flex; justify-content: center;">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/nameityoutself.webp" id="framePart">
  </div>
  <div *ngIf="!isHeadless" class="switches" id="scrollPointRealms">
    <button style="color: white; cursor: default;">Realms</button>
    <button (click)="navigate('Library')">Library</button>
  </div>
  <div style="width: 100%; display: flex; justify-content: center;">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" id="bottomFrame">
  </div>
  <app-share></app-share>
  <app-realm-detail *ngIf="locationDetail" [data]="currentLocationData" [universe]="this" [mythList]="locationMyths" [loreList]="lore">
  </app-realm-detail>
  <div *ngIf="realms.length > 0" class="realm-container2">
    <app-ability-button class="realm-button" *ngFor="let item of realms; index as i;" [selected]="true" 
      [realm]="item" [images]="[item.icon]" [universe]="this" [index]="i" [slot]="item.realm"></app-ability-button>
  </div>
  <div class="section1" style="display: inline-flex;" *ngIf="realms[currentRealm] !== undefined && locationDetail === false">
    <div style="width: 25%; position: relative; top: 0; left: 0; margin: 2em;">
      <img alt="Malware was here!" [src]="realms[currentRealm].icon" style="width: 100%; box-shadow: 0 0 1vw 1vw rgba(24,24,24,1) inset;">
      <p id="realmname" class="dp2">{{ realms[currentRealm].realm }}</p>
    </div>
    <div class="realm-div">
      <p class="dp1">{{ realms[currentRealm].tag[0] }}</p>
      <p class="dp2">{{ realms[currentRealm].headline[0] }}</p>
      <img alt="Malware was here!" class="dash" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" />
      <div>
        <p *ngFor="let item of realms[currentRealm].paragraphs[0]" style="font-family: 'Raleway'; color: silver; font-size: 1.5vw;">
          &nbsp;&nbsp;&nbsp;&nbsp; {{ item }}
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="showDetail && realms[currentRealm].myths.length > 0 && realms[currentRealm].myths !== undefined && locationDetail === false"
    class="section2">
    <div class="realm-div">
      <p class="dp1">{{ realms[currentRealm].tag[1] }}</p>
      <p class="dp2">{{ realms[currentRealm].headline[1] }}</p>
      <img alt="Malware was here!" class="dash" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" />
      <div>
        <p *ngFor="let item of realms[currentRealm].paragraphs[1][currentMyth]" style="font-family: 'Raleway'; color: silver; font-size: 1.5vw; height: 10em;">
          &nbsp;&nbsp;&nbsp;&nbsp; {{ item }}
        </p>
      </div>
    </div>
    <div class="myth-container" *ngIf="realms[currentRealm].myths !== undefined">
      <div class="scrollboxes">
        <div *ngFor="let item of realms[currentRealm].myths; index as i">
          <app-myth-button [myth]="item" [universe]="this" [index]="i"></app-myth-button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showDetail && realms[currentRealm].locations.length > 0 && locationDetail === false" class="section3">
    <div style="width: 30%;">
      <div class="scrollboxes">
        <app-location-button *ngFor="let item of realms[currentRealm].locations; index as i;" [location]="item" [universe]="this" [index]="i"></app-location-button>
      </div>
    </div>
    <div class="realm-div">
      <p class="dp1">{{ realms[currentRealm].tag[2] }}</p>
      <p class="dp2">{{ realms[currentRealm].headline[2] }}</p>
      <img alt="Malware was here!" class="dash" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" />
      <div style="width: 100%; display: flex; flex-wrap: wrap;">
        <p style="font-family: 'Raleway'; color: silver; font-size: 1.5vw; height: 10em;" *ngFor="let item of realms[currentRealm].paragraphs[2][currentLocation]">
          &nbsp;&nbsp;&nbsp;&nbsp; {{ item }}
        </p>
      </div>
    </div>
  </div>
</div>
