import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UniverseComponent } from '../universe/universe.component';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-realm-button',
  animations: [
    trigger('fade', [
      state('in', style({
        opacity: '1'
      })),
      state('out', style({
        opacity: '.5'
      })),
      transition('in => out', [
        animate('3s')
      ]),
      transition('out => in', [
        animate('2s ease-in')
      ]),
    ])
  ],
  templateUrl: './realm-button.component.html',
  styleUrls: ['./realm-button.component.css']
})
export class RealmButtonComponent implements OnInit, AfterViewInit {

  @Input() realm;
  @Input() universe: UniverseComponent;
  @Input() index;

  currentStar = 0;
  rotation = 0;
  fade = false;

  ngOnInit(): void {
    if (this.index !== 0) {
      this.currentStar = Math.floor(Math.random() * this.realm.stars.length);
      this.flicker();
      this.rotate();
    }
  }

  ngAfterViewInit(): void {
    const star = document.getElementById('star' + this.index);
    star.style.width = this.realm.size + 'vw';
    star.style.top = (50 - (this.realm.size)).toString() + '%';
    star.style.left = (50 - (this.realm.size / 1.5)).toString() + '%';
    if (this.index !== 0) {
      this.rotation = Math.random() * 360;
      star.style.transform = 'rotate(' + this.rotation + 'deg) translateX(' + this.realm.offset + '%)';
    }
  }

  rotate() {
    setTimeout(() => {
      const star = document.getElementById('star' + this.index);
      if (star !== null && star !== undefined) {
        star.style.transform = 'rotate(' + this.rotation + 'deg) translateX(' + this.realm.offset + '%)';
        this.rotation += .2;
        if (this.rotation === 360) {
          this.rotation = 0;
        }
        this.rotate();
      }
    }, this.realm.speed - 100);
  }

  flicker() {
    setTimeout(() => {
      this.fade = !this.fade;
      const overlay = document.getElementById('overlay' + this.index);
      if (overlay !== undefined && overlay !== null && overlay.style.opacity === '1') {
        this.currentStar = Math.floor(this.realm.stars.length * Math.random() || 0);
      }
      this.flicker();
    }, Math.random() * 3000);
  }

  setRealm() {
    this.universe.selectRealm(this.realm);
  }

  hoverRealm() {
    if (this.index !== 0) {
      this.universe.showRealm(this.realm.card);
    }
  }

  unhoverRealm() {
    this.universe.clearRealm();
  }
}
