<app-login *ngIf="!loggedIn" [bugReport]="this"></app-login>
<div class="overlay" *ngIf="loggedIn">
    <div>
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/close_icon.webp"
            style="width: 2.5%; cursor: pointer; position: absolute; left: 90%; top: 5%; z-index: 2;" (click)="navigate('Profile')">
    </div>
    <div class="section">
        <video autoplay loop src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/DustAndStarsBackground.webm"></video>
        <div id="survey">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSew6NDzNjpqcxrm9hEXBWrgR0C7JA2hP4kZLbRNhgfDM8ctKA/viewform?embedded=true" width="100%" height="3000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    </div>
</div>