import { Component, Input, OnInit } from '@angular/core';
import { LoreComponent } from '../lore/lore.component';

@Component({
  selector: 'app-alphabet',
  templateUrl: './alphabet.component.html',
  styleUrls: ['./alphabet.component.css']
})
export class AlphabetComponent implements OnInit {

  @Input() lore: LoreComponent;
  @Input() images;
  @Input() wide;
  current = 0;
  selected = false;

  constructor() { }

  ngOnInit(): void {
  }

  hover() {
    this.current = 1;
    this.lore.showLetter(this.images[0]);
  }

  unhover() {
    this.current = 0;
    this.lore.hideLetter();
  }

  select() {
    this.lore.filter(this.images[0]);
  }
}
