<div class="gameModes">
    <div class="gameModes-container">
        <img id="gameMode1" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-mode-1.webp" />
        <img id="gameMode2" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-mode-2.webp" />
        <img id="gameMode3" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-mode-3.webp" />
    </div>
    <div class="gameModes-text-box">
        <p class="p1">Game Modes</p><br />
        <p class="p2"><span class="gold">Clash</span> is the main game mode</p>
        <p class="p3">New ways to test the mettle of your Myths and new ways to play with your friends are coming soon!</p><br />
        <img src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" style="max-width: 100%; " /><br />
        <div class="bigButton"><button (click)="navGame()">See More</button></div>
    </div>
</div>