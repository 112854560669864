import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacybox',
  templateUrl: './privacybox.component.html',
  styleUrls: ['./privacybox.component.css']
})
export class PrivacyboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
