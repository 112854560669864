import { Component, Input, OnInit } from '@angular/core';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-matchresult',
  templateUrl: './matchresult.component.html',
  styleUrls: ['./matchresult.component.css']
})
export class MatchresultComponent implements OnInit {

  @Input() matchData;
  @Input() user;
  @Input() index;
  matchLength;
  matchDate;
  matchTime;
  winner = false;
  spellSlotImage = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/SpellSlot.webp';
  petSlotImage = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/base_pet_incard.webp';
  laneSlotImage = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/icon_lane_empty.webp';
  goldImage = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/cost.webp';
  expanded = false;
  playerCharacterPortraitMap = [];
  matchAccountIds = [];
  vsVideo = null;
  vsStart = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/vsStart.webm';
  vsLoop = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/vsLoop.webm';

  constructor(private swag: SwaggerService, private comms: InternalComms) { }

  ngOnInit(): void {
    this.matchLength = new Date(this.matchData.gameDurationInSeconds * 1000).toISOString().split('T')[1].split('.')[0];
    this.matchDate = new Date(this.matchData.matchEndingDate).toLocaleString().split(',')[0];
    this.matchTime = new Date(this.matchData.matchEndingDate).toLocaleString().split(',')[1];
    this.winner = this.checkForWinner();
    this.getCharacterPortraitMap();
  }

  checkForWinner() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.matchData.winnerTeam.members.length; i++) {
      if (this.user.accountId === this.matchData.winnerTeam.members[i].accountId) {
        return true;
      }
    }
    return false;
  }

  getCharacterPortraitMap() {
    this.matchAccountIds = [];
    this.matchData.winnerTeam.members.forEach(element => {
      this.matchAccountIds.push(element.accountId);
    });
    this.matchData.loserTeam.members.forEach(element => {
      this.matchAccountIds.push(element.accountId);
    });
    this.comms.getFriendsProfiles(this.user, this.matchAccountIds).subscribe( response => {
      response.info.forEach(element => {
        this.playerCharacterPortraitMap.push(
          {
            id: element.id,
            portrait: element.portraitId
          }
        );
      });
    });
  }

  setExpandedandPlayVideo() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.triggerStart();
    }
  }

  triggerStart() {
    this.vsVideo = this.vsStart;
    setTimeout( () => {
      if (this.expanded) {
        const temp = document.getElementById('vsVideoPlayer' + this.index) as HTMLVideoElement;
        if (temp) {
          temp.play();
          document.getElementById('vsVideoPlayer' + this.index).addEventListener('ended', this.toggleLoop.bind(this), false);
        }
      } else {
        this.vsVideo = null;
      }
    }, 500);
  }

  toggleLoop() {
    if (this.expanded) {
      this.vsVideo = this.vsLoop;
      (document.getElementById('vsVideoPlayer' + this.index) as HTMLVideoElement).loop = true;
    }
  }
}
