import { Component, AfterViewInit, Input, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { SwaggerService } from '../swagger.service';
import { InternalComms } from '../internal-comms.service';

@Component({
  selector: 'app-slideshow',
  animations: [
    trigger('fade', [
    state('in', style({
      opacity: '1'
    })),
    state('out', style({
      opacity: '0'
    })),
    transition('in => out', [
      animate('.9s ease-in')
    ]),
    transition('out => in', [
      animate('1.25s ease-in')
    ]),
  ])
],
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.css'],
})
export class SlideshowComponent implements OnInit, AfterViewInit, OnDestroy {

  sub: Subscription;
  slideIndex = 0;
  autoSlide = true;
  change = true;
  newsdata = [];
  startSliding = false;
  today = new Date();
  timeout1;
  timeout2;
  timeout3;
  timeout4;
  timeout5;
  timeout6;
  timeout7;
  timeout8;
  timeout9;
  timeout10;

  @Input() slideSources: string[][] = [[]];
  @Input() isnews = false;

  constructor(private router: Router, private swagger: SwaggerService, private comms: InternalComms) {}

  ngOnInit(): void {
    if (this.isnews) {
      // tslint:disable-next-line: deprecation
      this.swagger.getAllNews().subscribe(response => {
        this.swagger.setNews(response);
        let counter = 0;
        this.slideSources = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < response.length; i++) {
          const temp = JSON.parse(response[i].data);
          const tempDate = new Date(temp.date);
          if (tempDate < this.today) {
            this.newsdata[counter] = temp;
            counter++;
          } else if (tempDate === this.today && this.today.getUTCHours() > 17) {
            this.newsdata[counter] = temp;
            counter++;
          }
        }
        this.newsdata.sort((a, b) => {
          a = new Date(a.date).getTime().toString();
          b = new Date(b.date).getTime().toString();
          return a.localeCompare(b);
        });
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.newsdata.length; i++) {
          this.slideSources.push([this.newsdata[i].images[0]]);
        }
        if (this.slideSources.length > 10) {
          this.slideSources = this.slideSources.slice(this.slideSources.length - 10);
        }
        this.startSlides();
      });
    }
  }

  ngAfterViewInit(): void {
    if (!this.isnews) {
      this.timeout1 = setTimeout(() => {
        this.startSlides();
      }, 500);
    }
    if (this.isnews) {
    this.timeout2 = setTimeout(() => {
        const temp = document.getElementById('slideShowContainer');
        if (temp !== null && temp !== undefined) {
          temp.scrollIntoView();
        }
      }, 500);
    }
  }

  ngOnDestroy(): void {
      clearTimeout(this.timeout1);
      clearTimeout(this.timeout2);
      clearTimeout(this.timeout3);
      clearTimeout(this.timeout4);
      clearTimeout(this.timeout5);
      clearTimeout(this.timeout6);
      clearTimeout(this.timeout7);
      clearTimeout(this.timeout8);
      clearTimeout(this.timeout9);
      clearTimeout(this.timeout10);
      this.sub.unsubscribe();
  }

  navigate() {
    if (this.slideSources[this.slideIndex][1] !== undefined) {
      this.comms.navigate(this.slideSources[this.slideIndex][1]);
    }
  }

  readMore() {
    this.router.navigate(['News/' +
    this.newsdata[this.slideIndex + (this.newsdata.length - this.slideSources.length)].headline.split(' ').join('-')]);
  }

  startSlides() {
    const dots = document.getElementsByClassName('dot');
    if (dots[this.slideIndex] !== undefined) {
      dots[this.slideIndex].className += ' active';
    }
    this.slideAutomatically();
  }

  slideAutomatically() {
    // if autoSlide is enabled
    if (this.autoSlide === true) {
      if (this.slideSources[this.slideIndex][0].includes('webm')) {
        let slide = document.getElementById('slide') as HTMLVideoElement;
        // only change slides if the video has finished playing, this method is bound to the ended event on the slide video element
        if (slide !== undefined && slide !== null && slide.ended) {
          this.change = true;
          let dots = document.getElementsByClassName('dot');
          // tslint:disable-next-line: prefer-for-of
          for (let index = 0; index < dots.length; index++) {
            if (dots[index] !== undefined && dots[index] !== null) {
              dots[index].className = 'dot';
            }
          }
          const active = document.getElementsByClassName('dot active');
          // tslint:disable-next-line: prefer-for-of
          for (let index = 0; index < active.length; index++) {
            if (active[index] !== undefined && active[index] !== null) {
              active[index].className = 'dot';
            }
          }
          this.timeout3 = setTimeout(() => {
            this.slideIndex -= 1;
            if (this.slideIndex >= this.slideSources.length) { this.slideIndex = 0; }
            if (this.slideIndex < 0) { this.slideIndex = this.slideSources.length - 1; }
            dots = document.getElementsByClassName('dot');
            if (dots[(this.slideSources.length - 1 - this.slideIndex)] !== undefined) {
              dots[(this.slideSources.length - 1 - this.slideIndex)].className += ' active';
            }
            this.change = false;
            if (this.slideSources[this.slideIndex][0].includes('webm')) {
              this.timeout4 = setTimeout( () => {
                slide = document.getElementById('slide') as HTMLVideoElement;
                if (slide !== undefined && slide !== null && (slide.currentTime === 0 || slide.paused || slide.readyState < 2)) {
                  slide.muted = true;
                  slide.play();
                }
              }, 500);
            }
          }, 1250);
        } else if (slide !== undefined && slide !== null && slide.currentTime === 0 && slide.readyState > 0) {
          slide.muted = true;
          slide.play();
        }
      } else {
        this.change = true;
        let dots = document.getElementsByClassName('dot');
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < dots.length; index++) {
          if (dots[index] !== undefined && dots[index] !== null) {
            dots[index].className = 'dot';
          }
        }
        const active = document.getElementsByClassName('dot active');
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < active.length; index++) {
          if (active[index] !== undefined && active[index] !== null) {
            active[index].className = 'dot';
          }
        }
        this.timeout5 = setTimeout(() => {
          this.slideIndex -= 1;
          if (this.slideIndex >= this.slideSources.length) { this.slideIndex = 0; }
          if (this.slideIndex < 0) { this.slideIndex = this.slideSources.length - 1; }
          dots = document.getElementsByClassName('dot');
          if (dots[(this.slideSources.length - 1 - this.slideIndex)] !== undefined) {
            dots[(this.slideSources.length - 1 - this.slideIndex)].className += ' active';
          }
          this.change = false;
          if (this.slideSources[this.slideIndex][0].includes('webm')) {
            this.timeout6 = setTimeout( () => {
              const slide = document.getElementById('slide') as HTMLVideoElement;
              if (slide.currentTime === 0 || slide.paused || slide.readyState < 2) {
                slide.muted = true;
                slide.play();
              }
            }, 500);
          }
        }, 1250);
      }
    } else {
      this.autoSlide = true;
    }
    // this is called every run of this function, starts a timer to change slides if one doesn't exist
    if (this.sub == null || this.sub.closed) {
      // tslint:disable-next-line: deprecation
      this.sub = interval(8000).subscribe(x => {
        this.slideAutomatically();
      });
    }
  }

  changeSlides(n: number) {
    this.autoSlide = false;
    this.change = true;
    if (this.slideSources[this.slideIndex][0].includes('webm')) {
      const slide = document.getElementById('slide') as HTMLVideoElement;
      slide.pause();
      slide.currentTime = 0;
    }
    let dots = document.getElementsByClassName('dot');
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < dots.length; index++) {
      if (dots[index] !== undefined && dots[index] !== null) {
        dots[index].className = 'dot';
      }
    }
    const active = document.getElementsByClassName('dot active');
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < active.length; index++) {
      if (active[index] !== undefined && active[index] !== null) {
        active[index].className = 'dot';
      }
    }
    this.timeout7 = setTimeout(() => {
      this.slideIndex += n;
      if (this.slideIndex >= this.slideSources.length) { this.slideIndex = 0; }
      if (this.slideIndex < 0) { this.slideIndex = this.slideSources.length - 1; }
      dots = document.getElementsByClassName('dot');
      if (dots[(this.slideSources.length - 1 - this.slideIndex)] !== undefined) {
        dots[(this.slideSources.length - 1 - this.slideIndex)].className += ' active';
      }
      if (this.slideSources[this.slideIndex][0].includes('webm')) {
        this.timeout8 = setTimeout( () => {
          const slide = document.getElementById('slide') as HTMLVideoElement;
          if (slide.currentTime === 0 || slide.paused || slide.readyState < 2) {
            slide.muted = true;
            slide.play();
          }
        }, 500);
      }
      this.change = false;
    }, 1250);
  }

  currentSlide(n: number) {
    this.autoSlide = false;
    this.change = true;
    if (this.slideSources[this.slideIndex][0].includes('webm')) {
      const slide = document.getElementById('slide') as HTMLVideoElement;
      slide.pause();
      slide.currentTime = 0;
    }
    let dots = document.getElementsByClassName('dot');
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < dots.length; index++) {
      if (dots[index] !== undefined && dots[index] !== null) {
        dots[index].className = 'dot';
      }
    }
    const active = document.getElementsByClassName('dot active');
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < active.length; index++) {
      if (active[index] !== undefined && active[index] !== null) {
        active[index].className = 'dot';
      }
    }
    this.timeout9 = setTimeout(() => {
      this.slideIndex = n;
      if (this.slideIndex >= this.slideSources.length) { this.slideIndex = 0; }
      if (this.slideIndex < 0) { this.slideIndex = this.slideSources.length - 1; }
      dots = document.getElementsByClassName('dot');
      if (dots[(this.slideSources.length - 1 - this.slideIndex)] !== undefined) {
        dots[(this.slideSources.length - 1 - this.slideIndex)].className += ' active';
      }
      if (this.slideSources[this.slideIndex][0].includes('webm')) {
        this.timeout10 = setTimeout( () => {
          const slide = document.getElementById('slide') as HTMLVideoElement;
          if (slide.currentTime === 0 || slide.paused || slide.readyState < 2) {
            slide.muted = true;
            slide.play();
          }
        }, 500);
      }
      this.change = false;
    }, 1250);
  }
}
