import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-register-confirmation',
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['./register-confirmation.component.css']
})
export class RegisterConfirmationComponent implements OnInit {

  success = false;
  failure = false;
  routeError = false;

  constructor(private route: ActivatedRoute, private swagger: SwaggerService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.has('id') && params.has('code')) {
        this.swagger.verifyRegistration(params.get('id'), params.get('code')).subscribe(confirmed => {
          if (confirmed) {
            this.success = true;
            this.failure = false;
            this.routeError = false;
          } else {
            this.success = false;
            this.failure = true;
            this.routeError = false;
          }
        }, error => {
          console.log(error);
          this.success = false;
          this.failure = true;
          this.routeError = false;
          alert(error.error.error);
        });
      } else {
        this.success = false;
        this.failure = false;
        this.routeError = true;
      }
    });
  }

}
