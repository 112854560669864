import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable, of } from 'rxjs';
import { Console } from 'console';

// TODO: Check for memory stored codex when filtering by ID

@Injectable({
  providedIn: 'root'
})
export class SwaggerService {
  apiService = 'api-ecos-undyinggames.com';

  // Domains
  accountsDomain = `https://accounts-dev.${this.apiService}`;
  authDomain = `https://auth-dev.${this.apiService}`;
  codexDomain = `https://codex-dev.${this.apiService}`;
  friendsDomain = `https://friends-dev.${this.apiService}`;
  inventoryDomain = `https://inventory-dev.${this.apiService}`;
  matchmakingDomain = `https://matchmaking-dev.${this.apiService}`;
  storeDomain = `https://store-dev.${this.apiService}`;

  salt = 'hfowQjqptyE';

  mythsResponse;
  realmsResponse;
  classesResponse;
  newsResponse;
  codexResponse;  
  patchNotesResponse;
  itemsResponse;
  familiarResponse;
  multimediaResponse;
  roadmapResponse;

  constructor(private http: HttpClient) {}

  buildHeaders(authToken = null): HttpHeaders {
    if(authToken != null) {
      return new HttpHeaders({
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Authorization': `bearer ${authToken}`
      });
    }
    
    return new HttpHeaders({
      Accept: '*/*',
      'Content-Type': 'application/json'
    });
  }
  
  checkServiceStatus(domain): Observable<any> {
    return this.http.get(domain + '/Status/health', { headers: this.buildHeaders()});
  }

  /*
  checkServerStatus(region): Observable<any> {
    switch (region) {
      case 'NA':
        return this.http.get(this.frontEndBaseURL + '/Connectivity');
      case 'EU':
        return this.http.get(this.frontEndBaseURL + '/Connectivity');
      case 'AP':
        return this.http.get(this.frontEndBaseURL + '/Connectivity');
    }
  }

  checkServerOptions(region): Observable<any> {
    switch (region) {
      case 'NA':
        return this.http.get(this.frontEndBaseURL + '/Connectivity/optionFlags');
      case 'EU':
        return this.http.get(this.frontEndBaseURL + '/Connectivity/optionFlags');
      case 'AP':
        return this.http.get(this.frontEndBaseURL + '/Connectivity/optionFlags');
    }
  }

  getKey(): Observable<any> {
    return this.http.get(this.backEndBaseURL + '/Store/getKey');
  }
  */

  redeemKey(authToken, key): Observable<any> {
    return this.http.get(this.accountsDomain + '/Keys/redeemKey' + '/' + key, { headers: this.buildHeaders(authToken)} );
  }

  checkPreAlphaStatus(authToken): Observable<any> {
    return this.http.get(this.accountsDomain + '/Auth/checkValidity', { headers: this.buildHeaders(authToken)} );
  }

  verifyRegistration(accountId, code): Observable<any> {
    return this.http.get(this.accountsDomain + '/Registration/verify?accountId=' + accountId + '&verifyCode=' + code, { headers: this.buildHeaders()});
  }

  getAllMyths(): Observable<any> {
    if (this.mythsResponse !== undefined) {
      return of(this.mythsResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/myths',  { headers: this.buildHeaders()});
    }
  }
                                                                                                              
  setMyths(response): void {
    this.mythsResponse = response;
  }

  getAllPatchNotes(): Observable<any> {
    if (this.patchNotesResponse !== undefined) {
      return of(this.patchNotesResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/patchnotes',   { headers: this.buildHeaders()});
    }
  }

  setPatchNotes(response): void {
    this.patchNotesResponse = response;
  }

  getAllRealms(): Observable<any> {
    if (this.realmsResponse !== undefined) {
      return of(this.realmsResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/realms',   { headers: this.buildHeaders()});
    }
  }

  setRealms(response): void {
    this.realmsResponse = response;
  }

  getAllNews(): Observable<any> {
    if (this.newsResponse !== undefined) {
      return of(this.newsResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/news', { headers: this.buildHeaders()});
    }
  }

  setNews(response): void {
    this.newsResponse = response;
  }

  getAllClassData(): Observable<any> {
    if (this.classesResponse !== undefined) {
      return of(this.classesResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/classes',  { headers: this.buildHeaders()});
    }
  }

  setClassData(values): void {
    this.classesResponse = values;
  }

  getAllCodex(): Observable<any> {
    if (this.codexResponse !== undefined) {
      return of(this.codexResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/codexes', { headers: this.buildHeaders()});
    }
  }

  setCodex(response): void {
    this.codexResponse = response;
  }

  getCodex(codexID): Observable<any> {
    return this.http.get(this.codexDomain + '/v0/lore/codexes/' + codexID, { headers: this.buildHeaders()});
  }

  getAllFamiliars(): Observable<any> {
    if (this.familiarResponse !== undefined) {
      return of(this.familiarResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/familiars', { headers: this.buildHeaders()});
    }
  }

  setFamiliar(response): void {
    this.familiarResponse = response;
  }

  getFamiliar(familiarID): Observable<any> {
    return this.http.get(this.codexDomain + '/v0/lore/familiars/' + familiarID, { headers: this.buildHeaders()});
  }

  getAllMultimedia(): Observable<any> {
    if (this.multimediaResponse !== undefined) {
      return of(this.multimediaResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/multimedia', { headers: this.buildHeaders()});
    }
  }

  setMultimedia(response): void {
    this.multimediaResponse = response;
  }

  getMultimedia(multimediaID): Observable<any> {
    return this.http.get(this.codexDomain + '/v0/lore/multimedia/' + multimediaID, { headers: this.buildHeaders()});
  }

  getAllItems(): Observable<any> {
    if (this.itemsResponse !== undefined) {
      return of(this.itemsResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/items', { headers: this.buildHeaders()});
    }
  }

  setItems(response): void {
    this.itemsResponse = response;
  }

  getItem(itemID): Observable<any> {
    return this.http.get(this.codexDomain + '/v0/lore/items/' + itemID, { headers: this.buildHeaders()});
  }

  getAllRoadmap(): Observable<any> {
    if (this.roadmapResponse !== undefined) {
      return of(this.roadmapResponse);
    } else {
      return this.http.get(this.codexDomain + '/v0/lore/roadmap', { headers: this.buildHeaders()});
    }
  }

  setRoadmap(response): void {
    this.roadmapResponse = response;
  }

  getRoadMap(roadmapID): Observable<any> {
    return this.http.get(this.codexDomain + '/v0/lore/roadmap/' + roadmapID, { headers: this.buildHeaders()});
  }

  // Player Profile calls ********************************************************************* //

  login(username: string, password: string): Observable<any> {
    const hash = Md5.hashStr(password + this.salt);
    const data = {
      username,
      password: hash.toString()
    };
    return this.http.post(this.authDomain + '/Auth/login', data, { headers: this.buildHeaders()});
  }

  setDisplayName(playerName: string, authToken: string): Observable<any> {
    const data = {
      playerName
    };

    return this.http.patch(this.accountsDomain + '/Profile/setPlayerName', data, { headers: this.buildHeaders(authToken)});
  }

  forgotPassword(email: string, username: string): Observable<any> {
    const data = {
      email,
      username
    };
    return this.http.post(this.accountsDomain + '/Password/reset/request', data, { headers: this.buildHeaders()});
  }

  changePassword(data: any): Observable<any> {
    const hash = Md5.hashStr(data.newPassword + this.salt);
    const newData = {
      resetCode: data.code,
      accountId: data.accountId,
      newPassword: hash.toString()
    };
    return this.http.post(this.accountsDomain + '/Password/reset/change', newData, { headers: this.buildHeaders()});
  }

  changePasswordAuthenticated(authToken: string, data: any): Observable<any> {
    const oldPasswordHash = Md5.hashStr(data.oldPassword + this.salt);
    const newPasswordHash = Md5.hashStr(data.newPassword + this.salt);
    const newData = {
      oldPassword: oldPasswordHash.toString(),
      newPassword: newPasswordHash.toString()
    };
    return this.http.post(this.accountsDomain + '/Password/change', newData, { headers: this.buildHeaders(authToken)});
  }

  // TODO: implement
  /*
  checkAuth(authToken: string): Observable<any> {
    return this.http.get(this.backEndBaseURL + '/Admin/testToken', { headers: this.buildHeaders(this.???, authToken)});
  }
  */

  createAccount(user: any): Observable<any>{
    const hash = Md5.hashStr(user.password + this.salt);
    const data = {
      username: user.username,
      password: hash.toString(),
      email: user.email
    };
    return this.http.post(this.accountsDomain + '/Registration/new', data, { headers: this.buildHeaders()});
  }

  refresh(user): Observable<any>{
    return this.http.post(this.authDomain + '/Auth/refresh', {}, { headers: this.buildHeaders(user.authToken)});
  }

  sendEmail(userEmail, optionPicked): Observable<any>{
    const body = {
      email: userEmail,
      options: JSON.stringify({survey: optionPicked})
    };
    return this.http.post(this.accountsDomain + '/Preregister/new', body, { headers: this.buildHeaders()});
  }

  // tslint:disable-next-line: max-line-length
  profile(user: { username?: string; password?: string; email?: string; playerName?: string; authToken?: any; accountId?: any; }): Observable<any> {
    return this.http.get(this.accountsDomain + '/Search/id/' + user.accountId, { headers: this.buildHeaders(user.authToken)});
  }

  status(authToken: any, accountId: any): Observable<any>{
    const data = {
      ids: [ accountId ]
    };
    return this.http.post(this.accountsDomain + '/Accounts/infoStatus', data, { headers: this.buildHeaders(authToken)});
  }

  statistics(authToken: any, accountId: number): Observable<any>{
    const data = {
      ids: [ accountId ]
    };
    return this.http.post(this.accountsDomain + '/Accounts/infoStatistics', data, { headers: this.buildHeaders(authToken)});
  }

  getPortrait(user): Observable<any>{
    return this.http.get(this.inventoryDomain + '/Items/equipped', { headers: this.buildHeaders(user.authToken)});
  }

  // TODO: This endpoint isn't correct and should be inventory service
  setPortrait(user, portraitId): Observable<any>{
    const body = {
      portraitId: [ portraitId ]
    };
    return this.http.put(this.accountsDomain + '/Profile/setPortrait/', body, { headers: this.buildHeaders(user.authToken)});
  }

  getAllPortraits(authToken): Observable<any>{
    return this.http.post(this.inventoryDomain + '/Store/Portraits/getAll', { headers: this.buildHeaders(authToken)});
  }

  getCoins(user): Observable<any>{
    return this.http.get(this.inventoryDomain + '/Coins/balance?accountId=' + user.accountId, { headers: this.buildHeaders(user.authToken)});
  }

  getOptions(authToken): Observable<any>{
    return this.http.get(this.accountsDomain + '/Accounts/getOptions', { headers: this.buildHeaders(authToken)});
  }

  storeOptions(authToken, options, accountId): Observable<any>{
    const body = {
      accountId,
      options
    };
    return this.http.post(this.accountsDomain + '/Accounts/storeOptions', body, { headers: this.buildHeaders(authToken)});
  }

  getMatchHistory(authToken): Observable<any>{
    return this.http.get(this.matchmakingDomain + '/Matchmaking/getMatchResults', { headers: this.buildHeaders(authToken)});
  }

  // Friend calls  ****************************************************************** //

  getPlayerName(value, authToken): Observable<any> {
    return this.http.get(this.accountsDomain + '/Accounts/searchUsername/' + value, { headers: this.buildHeaders(authToken)});
  }

  getFriendsStats(authToken: any, ids: [number]): Observable<any> {
    const body = {
      ids
    };
    return this.http.post(this.accountsDomain + '/Accounts/infoStatistics', body, { headers: this.buildHeaders(authToken)});
  }

  getFriendsList(authToken): Observable<any> {
    return this.http.get(this.accountsDomain + '/Friends/getAll', { headers: this.buildHeaders(authToken)});
  }

  getFriendRequests(authToken): Observable<any> {
    return this.http.get(this.accountsDomain + '/Friends/getRequests', { headers: this.buildHeaders(authToken)});
  }

  getFriendsProfiles(authToken: any, ids: [number]): Observable<any> {
    const body = {
      ids
    };
    return this.http.post(this.accountsDomain + '/Accounts/infoProfile', body, { headers: this.buildHeaders(authToken)});
  }

  getFriendsStatus(authToken: any, ids: [number]): Observable<any> {
    const body = {
      ids
    };
    return this.http.post(this.accountsDomain + '/Accounts/infoStatus', body, { headers: this.buildHeaders(authToken)});
  }

  sendFriendRequest(senderId, receiverId, authToken): Observable<any> {
    const body = {
      senderId,
      receiverId
    };
    return this.http.post(this.accountsDomain + '/Friends/sendRequest', body, { headers: this.buildHeaders(authToken)});
  }

  sendBulkFriendRequests(senderId, ids, authToken): Observable<any> {
    const body = {
      senderId,
      ids
    };
    return this.http.post(this.accountsDomain + '/Friends/sendRequests', body, { headers: this.buildHeaders(authToken)});
  }

  acceptFriendRequest(senderId, receiverId, authToken): Observable<any> {
    const body = {
      senderId,
      receiverId
    };
    return this.http.post(this.accountsDomain + '/Friends/acceptRequest', body, { headers: this.buildHeaders(authToken)});
  }

  declineFriendRequest(senderId, receiverId, authToken): Observable<any> {
    const body = {
      senderId,
      receiverId
    };
    return this.http.post(this.accountsDomain + '/Friends/declineRequest', body, { headers: this.buildHeaders(authToken)});
  }

  removeFriend(senderId, friendId, authToken): Observable<any> {
    const body = {
      senderId,
      friendId
    };
    return this.http.post(this.accountsDomain + '/Friends/remove', body, { headers: this.buildHeaders(authToken)});
  }

  // Shop calls ****************************************************************** //

  // Announcer calls ****************************************************************** //

  getAllAnnouncers(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Announcer/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedAnnouncers(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Announcer/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasAnnouncers(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Announcer/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyAnnouncer(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/Announcer/buy', body, { headers: this.buildHeaders(authToken)});
  }

  // Bundles   ****************************************************************** //

  getCoinsBundles(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Bundles/getCoinsBundles', { headers: this.buildHeaders(authToken)});
  }

  buyCoinsBundle(authToken, accountId, bundleId): Observable<any> {
    const body = {
      accountId,
      bundleId
    };
    return this.http.post(this.inventoryDomain + '/Store/Bundles/buyCoinsBundle', body, { headers: this.buildHeaders(authToken)});
  }

  confirmPurchase(authToken, accountId, orderId): Observable<any> {
    const body = {
      accountId,
      orderId
    };
    return this.http.post(this.inventoryDomain + '/Store/Bundles/confirmBundlePurchase', body, { headers: this.buildHeaders(authToken)});
  }

  PayPalSuccess(authToken, paymentId, playerId, token): Observable<any> {
    const body = '?paymentId=' + paymentId + '&token=' + token + '&playerId=' + playerId;
    return this.http.get(this.inventoryDomain + '/Store/Bundles/PayPal/success' + body, { headers: this.buildHeaders(authToken)});
  }

  PayPalCancel(authToken, paymentId, playerId, token): Observable<any> {
    const body = '?paymentId=' + paymentId + '&token=' + token + '&playerId=' + playerId;
    return this.http.get(this.inventoryDomain + '/Store/Bundles/PayPal/cancel' + body, { headers: this.buildHeaders(authToken)});
  }

  // Characters   ****************************************************************** //

  getAllCharacters(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Characters/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedCharacters(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Characters/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasCharacters(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Characters/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyCharacter(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/Characters/buy', body, { headers: this.buildHeaders(authToken)});
  }

  // Character Skins   ****************************************************************** //

  getAllCharacterSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/CharacterSkins/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedCharacterSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/CharacterSkins/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasCharacterSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/CharacterSkins/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyCharacterSkin(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/CharacterSkins/buy', body, { headers: this.buildHeaders(authToken)});
  }

  // Emotes   ****************************************************************** //
  getAllEmotes(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Emotes/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedEmotes(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Emotes/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasEmotes(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Emotes/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyEmote(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/Emotes/buy', body, { headers: this.buildHeaders(authToken)});
  }

  // Guild calls ****************************************************************** //

  // TODO: what service?
  /*
  getGuildInfo(authToken, guildId): Observable<any> {
    return this.http.get(this.???Domain + '/Guilds/getInfo/' + guildId, { headers: this.buildHeaders(authToken)});
  }

  createGuild(authToken, name, ownerId): Observable<any> {
    const body = {
      name,
      ownerId
    };
    return this.http.post(this.???Domain + '/Guilds/create', body, { headers: this.buildHeaders(authToken)});
  }

  deleteGuild(authToken, ownerId): Observable<any> {
    const body = {
      ownerId
    };
    return this.http.post(this.???Domain + '/Guilds/delete', body, { headers: this.buildHeaders(authToken)});
  }

  joinGuild(authToken, guildId, accountId): Observable<any> {
    const body = {
      accountId,
      guildId
    };
    return this.http.post(this.???Domain + '/Guilds/join', body, { headers: this.buildHeaders(authToken)});
  }

  quitGuild(authToken, accountId): Observable<any> {
    const body = {
      accountId
    };
    return this.http.post(this.???Domain + '/Guilds/quit', body, { headers: this.buildHeaders(authToken)});
  }

  transferGuild(authToken, ownerId, targetId): Observable<any> {
    const body = {
      ownerId,
      targetId
    };
    return this.http.post(this.???Domain + '/Guilds/transferOwnership', body, { headers: this.buildHeaders(authToken)});
  }
  */

  // Loadout calls ****************************************************************** //

  /*
  getPages(authToken, accountId): Observable<any> {
    return this.http.get(this.???Domain + '/Loadout/getPages/' + accountId, { headers: this.buildHeaders(authToken)});
  }

  getLoadouts(authToken): Observable<any> {
    return this.http.get(this.???Domain + '/Loadout/getLoadouts/', { headers: this.buildHeaders(authToken)});
  }

  updateLoadout(authToken, accountId, loadout): Observable<any> {
    const body = {
      accountId,
      loadout
    };
    return this.http.post(this.???Domain + '/Loadout/updateLoadout', body, { headers: this.buildHeaders(authToken)});
  }

  createLoadout(authToken, accountId, loadout): Observable<any> {
    const body = {
      accountId,
      loadout
    };
    return this.http.post(this.???Domain + '/Loadout/createLoadout', body, { headers: this.buildHeaders(authToken)});
  }

  deleteLoadout(authToken, accountId, loadoutId): Observable<any> {
    const body = {
      accountId,
      loadoutId
    };
    return this.http.post(this.???Domain + '/Loadout/deleteLoadout', body, { headers: this.buildHeaders(authToken)});
  }

  getAllLoadouts(authToken): Observable<any> {
    return this.http.get(this.???Domain + '/Store/Loadouts/getAll', { headers: this.buildHeaders(authToken)});
  }

  buyLoadout(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.???Domain + '/Store/Loadouts/buy', body, { headers: this.buildHeaders(authToken)});
  }
  */

  // Pet calls ****************************************************************** //

  getAllPets(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Pets/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedPets(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Pets/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasPets(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Pets/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyPet(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/Pets/buy', body, { headers: this.buildHeaders(authToken)});
  }

  // Pet skin calls ****************************************************************** //

  getAllPetSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/PetSkins/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedPetSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/PetSkins/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasPetSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/PetSkins/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyPetSkin(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/PetSkins/buy', body, { headers: this.buildHeaders(authToken)});
  }

  // Tag skin calls ****************************************************************** //

  getAllTags(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Tags/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedTags(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Tags/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasTags(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/Tags/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyTag(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/Tags/buy', body, { headers: this.buildHeaders(authToken)});
  }

  // UISkins skin calls ****************************************************************** //

  getAllUISkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/UISkins/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedUISkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/UISkins/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasUISkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/UISkins/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyUISkin(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/UISkins/buy', body, { headers: this.buildHeaders(authToken)});
  }

  // WardSkins skin calls ****************************************************************** //

  getAllWardSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/WardSkins/getAll', { headers: this.buildHeaders(authToken)});
  }

  getOwnedWardSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/WardSkins/getOwned', { headers: this.buildHeaders(authToken)});
  }

  getHasWardSkins(authToken): Observable<any> {
    return this.http.get(this.inventoryDomain + '/Store/WardSkins/hasItems', { headers: this.buildHeaders(authToken)});
  }

  buyWardSkin(authToken, accountId, itemId, currencyCode): Observable<any> {
    const body = {
      accountId,
      itemId,
      currencyCode
    };
    return this.http.post(this.inventoryDomain + '/Store/WardSkins/buy', body, { headers: this.buildHeaders(authToken)});
  }
}