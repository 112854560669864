import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';
import { ShootingStarsComponent , ShootingStar} from '../shooting-stars/shooting-stars.component';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-universe',
  templateUrl: './universe.component.html',
  styleUrls: ['./universe.component.css']
})

export class UniverseComponent implements OnInit, AfterViewInit {
  isHeadless = true;
  
  showDetail = false;
  locationDetail = false;
  realms = [];
  myths = [];
  lore = [];
  currentRealm = 0;
  currentLocation = 0;
  realmCard = '';
  currentMyth = 0;
  stars = [];
  starfield;
  canvasOb: ShootingStarsComponent;
  tryCanvasInterval: Subscription;
  starTryCounter = 0;
  currentLocationData;
  locationMyths = [];

  constructor(private comms: InternalComms, private route: ActivatedRoute, private swagger: SwaggerService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.isHeadless = params.isHeadless;
    });

    this.comms.setCurrent('navCodex');
    this.swagger.getAllRealms().subscribe(responseRealms => {
      for (let i = 0; i < responseRealms.length; i++) {
        this.realms[i] = JSON.parse(responseRealms[i].data);
      }
      this.swagger.setRealms(responseRealms);
      this.realms[0].realm = 'AAAAAAAAAA Ethereal';
      this.realms.sort((a, b) => a.realm.localeCompare(b.realm));
      this.realms[0].realm = 'Ethereal';
      this.swagger.getAllMyths().subscribe(responseMyths => {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < responseMyths.length; i++) {
          const temp = JSON.parse(responseMyths[i].data);
          if (temp.released === 'Yes') {
            this.myths[this.myths.length] = JSON.parse(responseMyths[i].data);
          }
        }
        this.swagger.setMyths(responseMyths);
        this.myths.sort((a, b) => a.mythname.localeCompare(b.mythname));
        // tslint:disable-next-line: deprecation
        this.swagger.getAllCodex().subscribe(responseCodex => {
          this.swagger.setCodex(responseCodex);
          for (let i = 0; i < responseCodex.length; i++) {
            this.lore[i] = JSON.parse(responseCodex[i].data);
          }
          // tslint:disable-next-line: deprecation
          this.route.paramMap.subscribe(params => {
            if (params.has('id') && +params.get('id') < this.realms.length) {
              this.currentRealm = +params.get('id');
              this.showDetail = true;
            } else if (params.has('id')) {
              for (let i = 0; i < this.realms.length; i++) {
                if (this.realms[i].realm === params.get('id')) {
                  this.currentRealm = i;
                  this.showDetail = true;
                }
              }
            }
            this.setMyths();

            if(this.isHeadless) {
              window.history.pushState({}, '', `/#/Realms/${this.currentRealm}/${this.realms[this.currentRealm].realm.split(' ').join('-')}?isHeadless=true`);
            }
            else {
              window.history.pushState({}, '', `/#/Realms/${this.currentRealm}/${this.realms[this.currentRealm].realm.split(' ').join('-')}`);
            }
            
            this.looperFunc();
          });
        });
      });
    });
  }

  looperFunc() {
    // tslint:disable-next-line: deprecation
    this.tryCanvasInterval = interval(2000).subscribe(() => {
      if (this.tryGetCanv()) {
        this.tryCanvasInterval.unsubscribe();
      }
    });
  }

  ngAfterViewInit(): void {
    this.starfield = document.getElementById('realm-container');
    setTimeout(() => {
      const elem = document.getElementById('realm-container');
      window.scrollBy(0, elem.getBoundingClientRect().top);
    }, 500);
  }

  tryGetCanv() {
    const elem = document.getElementById('shootingStarCanvas');
    if (elem.nodeName !== null && elem.nodeName === 'CANVAS') {
      elem.setAttribute('width', document.getElementById('realm-container').clientWidth as any);
      elem.setAttribute('height', document.getElementById('realm-container').clientHeight as any);
      this.canvasOb = new ShootingStarsComponent(document.getElementById('shootingStarCanvas') as HTMLCanvasElement);
      // tslint:disable-next-line: deprecation
      interval(10000).subscribe(() => {
        this.shootingStar();
      });
      // tslint:disable-next-line: deprecation
      interval(40).subscribe(() => {
        this.stepStars();
      });
      return true;
    }
    return false;
  }

  stepStars() {
    if (!this.stars) {
      return;
    }
    this.canvasOb.clear();
    for (let i = 0 ; i < this.stars.length; i++) {
      this.stars[i].moveSteps(40);
      if (this.stars[i] !== undefined && this.stars[i].dead === true) {
        this.stars.splice(i, 1);
      } else {
        this.stars[i].draw();
      }
    }
  }

  shootingStar() {
    if (Math.random() > 0.5) {
      this.stars.push(this.canvasOb.generateStar());
    } else {
      this.starTryCounter++;
    }
    if (this.starTryCounter >= 100) {
      const rand = Math.random() * 20;
      for (let i = 0; i < rand; i++) {
        this.stars.push(this.canvasOb.generateStar());
      }
      this.starTryCounter = 0;
    }
  }

  setMyths() {
    // tslint:disable-next-line: prefer-for-of
    for (let k = 0; k < this.realms.length; k++) {
      let j = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.myths.length; i++) {
        // tslint:disable-next-line: prefer-for-of
        for (let m = 0; m < this.realms[k].mythnames.length; m++) {
          if (this.realms[k].mythnames[m] === this.myths[i].mythname) {
            this.realms[k].myths[j] = this.myths[i];
            j++;
          }
        }
      }
    }
  }

  selectRealm(realm) {
    this.locationDetail = false;
    let n = 0;
    for (let i = 0; i < this.realms.length; i++) {
      if (this.realms[i] === realm) {
        n = i;
        break;
      }
    }
    this.currentRealm = n;
    if (n === 0) {
      this.showDetail = false;
    } else {
      this.showDetail = true;
    }

    if(this.isHeadless) {
      window.history.pushState({}, '', `/#/Realms/${this.currentRealm}/${this.realms[this.currentRealm].realm.split(' ').join('-')}?isHeadless=true`);
    } else {
      window.history.pushState({}, '', `/#/Realms/${this.currentRealm}/${this.realms[this.currentRealm].realm.split(' ').join('-')}`);
    }

    document.getElementById('scrollPointRealms').scrollIntoView();
  }

  selectMyth(index: number) {
    if(this.isHeadless) {
      this.comms.header.navigate('Library/' + index);
    } else {
      this.router.navigate(['Library/' + index], {
        queryParams: {
          isHeadless: true
        }
      });
    }
  }

  showLocationDetail(location) {
    let k = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.myths.length; i++) {
      if (location[0] === this.myths[i].location) {
        this.locationMyths[k] = this.myths[i];
        k++;
      }
    }
    this.currentLocationData = location;
    this.locationDetail = true;
    window.scrollTo(0, 0);
  }

  setLocation(index: number) {
    this.currentLocation = index + 1;
  }

  showRealm(realm: string) {
    this.realmCard = realm;
  }

  clearRealm() {
    this.realmCard = '';
  }

  setCurrentMyth(n: number) {
    this.currentMyth = n + 1;
  }

  navigate(page) {
    if(this.isHeadless) {
      this.router.navigate([page], {
        queryParams: {
          isHeadless: true
        }
      });
    } else {
      this.router.navigate([page]);
    }
  }
}
