import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testers',
  templateUrl: './testers.component.html',
  styleUrls: ['./testers.component.css']
})
export class TestersComponent implements OnInit, AfterViewInit {

  canvas; // not a real canvas element
  dustMaker;
  minParticles = 200;
  maxParticles = 500;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('dustCanvas') as HTMLCanvasElement;
    if (this.canvas !== null && this.canvas !== undefined) {
      this.canvas.setAttribute('width', document.getElementById('canvasWrapper').clientWidth as any);
      this.canvas.setAttribute('height', document.getElementById('canvasWrapper').clientHeight as any);
    }
    this.dustMaker = new DustParticleMaker(this.canvas);
    for (let i = 0; i < this.minParticles; i++) {
      const dust = this.dustMaker.drawObject(true);
      this.canvas.appendChild(dust);
      this.dustMaker.animateMe(this.canvas.children[i]);
    }
    this.makeDust();
  }

  makeDust() {
    const dust = this.dustMaker.drawObject(false);
    this.canvas.appendChild(dust);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.canvas.children.length; i++) {
      const chance = Math.random();
      if (chance > .5) {
        setTimeout(() => {
          this.dustMaker.animateMe(this.canvas.children[i]);
        }, Math.random() * 1000);
      }
    }
    const limit = Math.floor(Math.random() * 10) + this.maxParticles;
    if (this.canvas.children.length > limit) {
      const rand = Math.floor(Math.random() * this.canvas.children.length);
      this.canvas.removeChild(this.canvas.children[rand]);
    }
    setTimeout(() => {
      this.makeDust();
    }, Math.random() * 2000 + 10000);
  }
}

export class DustParticleMaker {
  scale = 10;
  canvas: any; // not a real canvas element
  readonly availableColors = [
    'rgba(88,228,247,',
    'rgba(147,128,69,',
    'rgba(99,74,44,',
    'rgba(29,66,55,',
    'rgba(158,61,71,',
    'rgba(51,102,147,',
    'rgba(251,201,65,',
    'rgba(79,61,102,',
    'rgba(255,255,255,',
    'rgba(205,204,208,'
  ];
  readonly availableStartingPoints = [
    ['-10px', '-10px'],
    ['50%', '50%'],
    ['50%', '50%'],
    ['50%', '50%'],
    ['50%', '50%'],
    ['50%', '50%'],
    ['0', '100%'],
    ['100%', '0'],
    ['100%', '100%']
  ];
  constructor(canvas: any) {
    this.canvas = canvas;
  }
  drawObject(startingDust: boolean) {
    if (startingDust) {
      const rgb = Math.floor(Math.random() * this.availableColors.length);
      let size;
      let opacity;
      if (rgb >= this.availableColors.length - 2) {
        size = Math.random() * (this.scale * 2.5);
        opacity = Math.random() * .5;
      } else {
        size = Math.random() * this.scale;
        opacity = Math.random();
      }
      const color = this.availableColors[rgb] + opacity + ')';
      const temp = document.createElement('div');
      temp.style.width = size + 'px';
      temp.style.height = size + 'px';
      temp.style.backgroundColor = color;
      temp.style.position = 'absolute';
      const width = this.canvas.getAttribute('width');
      const height = this.canvas.getAttribute('height');
      temp.style.top = (Math.random() * height) + 'px';
      temp.style.left = (Math.random() * width) + 'px';
      temp.style.clipPath = 'circle(50% at 50% 50%)';
      temp.style.opacity = String((Math.random() * .5) + .5);
      return temp;
    } else {
      const rgb = Math.floor(Math.random() * this.availableColors.length);
      let size;
      let opacity;
      if (rgb >= this.availableColors.length - 2) {
        size = Math.random() * (this.scale * 2.5);
        opacity = Math.random() * .5;
      } else {
        size = Math.random() * this.scale;
        opacity = Math.random();
      }
      const color = this.availableColors[rgb] + opacity + ')';
      const temp = document.createElement('div');
      temp.style.width = size + 'px';
      temp.style.height = size + 'px';
      temp.style.backgroundColor = color;
      temp.style.position = 'absolute';
      const startingPoint = Math.floor(Math.random() * this.availableStartingPoints.length);
      temp.style.top = this.availableStartingPoints[startingPoint][0];
      temp.style.left = this.availableStartingPoints[startingPoint][1];
      temp.style.clipPath = 'circle(50% at 50% 50%)';
      temp.style.opacity = String((Math.random() * .5) + .5);
      return temp;
    }
  }
  animateMe(elem) {
    const width = this.canvas.getAttribute('width');
    const height = this.canvas.getAttribute('height');
    const directionX = Math.random() * width * (Math.random() > .5 ? 1 : -1);
    const directionY = Math.random() * height * (Math.random() > .5 ? 1 : -1);
    if (elem !== null && elem !== undefined) {
      let clipPath = 'circle(50% at 50% 50%)';
      if (Math.random() > .5) {
        clipPath = 'circle(' + (Math.random() * 50) + '% at 50% 50%)';
      }
      elem.animate([
        { transform: 'translate(' + elem.style.left + ', ' + elem.style.top + ')',
          opacity: elem.style.opacity,
          offset: 0 },
        { transform: 'translate(' + (directionX) + 'px, ' + (directionY) + 'px)',
          opacity: '0',
          clipPath,
          offset: 1 }
      ], {
        duration: Math.random() * 5000 + 100000
      });
    }
  }
}
