import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-trailers',
  templateUrl: './trailers.component.html',
  styleUrls: ['./trailers.component.css']
})
export class TrailersComponent {

  mainTrailer = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/dGqwnohV0hg');
  miniTrailer1 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/dGqwnohV0hg');
  miniTrailer2 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/dGqwnohV0hg');
  miniTrailer3 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/dGqwnohV0hg');

  constructor(private sanitizer: DomSanitizer) { }

  youtube() {
    window.open('https://www.youtube.com/channel/UCBuULwnagsHgSZeEKHoodNQ');
  }

}
