import { Component, OnInit } from '@angular/core';
import { InternalComms } from '../internal-comms.service';

@Component({
  selector: 'app-bug-report',
  templateUrl: './bug-report.component.html',
  styleUrls: ['./bug-report.component.css']
})
export class BugReportComponent implements OnInit {

  loggedIn = false;
  user = {
    username: '',
    password: '',
    email: '',
    playerName: '',
    accountId: 0,
    authToken: ''
  };
  profile;

  constructor(private comms: InternalComms) { }

  ngOnInit(): void {
    this.comms.setCurrent('navProfile');
  }

  login(user, stayLoggedIn) {
    this.user = user;
    this.loggedIn = true;
    // tslint:disable-next-line: deprecation
    this.comms.profile(this.user).subscribe(responseProfile => {
      this.profile = responseProfile.info[0];
      this.refresh();
    }, error => {
      this.login(user, stayLoggedIn);
    });
  }

  refresh() {
    setTimeout(() => {
      // tslint:disable-next-line: deprecation
      this.comms.refresh(this.user).subscribe(response => {
        this.user.authToken = response.authToken;
      });
      this.refresh();
    }, 60000 * 4);
  }

  navigate(page) {
    this.comms.navigate(page);
  }

}
