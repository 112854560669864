<ng-container *ngIf="!showDetail">
  <div style="width: 100%; display: flex; justify-content: center;">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/nameityoutself.webp" id="framePart">
  </div>
  <div *ngIf="!isHeadless" class="switches" id="scrollPointClasses">
    <!-- <button (click)="navigate('Elysium')">Elysium</button> -->
    <button style="color: white; cursor: default;">Myths</button>
    <!-- <button (click)="navigate('Familiars')">Familiars</button> -->
    <button (click)="navigate('Items')">Items</button>
    <button (click)="navigate('Classes')">Classes</button>
    <!--<button (click)="navigate('Builder')">Builder</button>-->
  </div>
  <div style="width: 100%; display: flex; justify-content: center;">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" id="bottomFrame">
  </div>
</ng-container>
<app-share *ngIf="!showDetail"></app-share>
<div *ngIf="allDataLoaded && !showDetail">
  <div class='section' id="mythList">
    <div id="filterContainer">
      <div class="filterButton" *ngFor="let item of filters; index as i;" (mouseenter)="toggleFilterMenus(item[0])" (mouseleave)="toggleFilterMenus('')">
        <img [src]="item[1]" (click)="flipSelection(i)" (mouseenter)="showToolTip(item[1])" (mouseleave)="showToolTip('')">
        <!-- <p [attr.id]="item[1] + 'Flip'" class="flipToolTip">Flip</p> -->
        <div [attr.id]="item[0] + 'Connector'" class="connector"></div>
        <div [attr.id]="item[0]" class="dropdown">
          <div style="position: absolute; top: -20px; left: 0; width: 100%; height: 20px;"></div>
          <div *ngFor="let subItem of item[2]" class="filterCatagory">
            <img [src]="subItem[1]" style="width: 4em; cursor: pointer;" (click)="toggleFilter(subItem[0])">
            <label class="container">
              {{ subItem[0] }}
              <input type="checkbox" checked="checked" class="checkbox-filter" [attr.id]="subItem[0] + 'Check'" (change)="filter()">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="filterButtonLong">
        <div *ngFor="let subItem of difficulty; index as i;" class="filterCatagorySmall" (mouseenter)="toggleFilterMenus(subItem)" (mouseleave)="toggleFilterMenus('')" (click)="changeDifficulty(i)">
          <img *ngIf="currentDifficulty === i" [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/MythFilterIcons/Selected_' + subItem + '.png'">
          <img *ngIf="currentDifficulty !== i" [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/MythFilterIcons/' + subItem + '.png'">
          <div [attr.id]="subItem" class="dropdownSmall">
            <label class="container" style="padding-left: 0;">
              {{ subItem }}
            </label>
          </div>
        </div>
      </div> 
      <div style="color: white; width: 18em; border: .01em solid rgb(198, 169, 83); position: relative; top: 0; left: 0;">
        <img src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Buttons/Overview.png" class="mag">
        <input type="text" placeholder="Search by Name" class="searchBar" #searchBar id="searchBar" (keyup)="onKeySearch(searchBar.value)">
      </div>
    </div>
    <div style="height: 2em;"></div>
    <div id="mythListContainer">
      <div *ngIf="selected.length === 0">
        <p>No Myths fit the filter/search criterea</p>
      </div>
      <app-myth-button class="mythButton" *ngFor="let item of selected;" [id]="item.mythname" [myth]="item" [myths]="this" [index]="item.release"></app-myth-button>
    </div>
  </div>
</div>
<div *ngIf="allDataLoaded && showDetail">
  <app-myth-detail [myth]="myths[currentMyth]" [myths]="this"></app-myth-detail>
</div>