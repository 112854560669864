import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, AfterViewInit {

  news = [];
  showDetail = false;
  currentStory = -1;
  today = new Date();

  // tslint:disable-next-line: max-line-length
  constructor(private route: ActivatedRoute, private comms: InternalComms, private swagger: SwaggerService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.comms.setCurrent('navCommunity');
    // tslint:disable-next-line: deprecation
    this.swagger.getAllNews().subscribe(response => {
      this.swagger.setNews(response);
      let counter = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < response.length; i++) {
        const temp = JSON.parse(response[i].data);
        const tempDate = new Date(temp.date);
        if (tempDate < this.today) {
          this.news[counter] = temp;
          if (this.news[counter].localVideo) {
            this.news[counter].localVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.news[counter].localVideo);
          }
          counter++;
        } else if (tempDate === this.today && this.today.getUTCHours() > 17) {
          this.news[counter] = temp;
          if (this.news[counter].localVideo) {
            this.news[counter].localVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.news[counter].localVideo);
          }
          counter++;
        }
      }
      this.news.sort((a, b) => {
        a = new Date(a.date).getTime().toString();
        b = new Date(b.date).getTime().toString();
        return a.localeCompare(b);
      });
      // tslint:disable-next-line: deprecation
      this.route.paramMap.subscribe(params => {
        if (params.has('id') && +params.get('id') < this.news.length) {
          this.currentStory = +params.get('id');
          this.showDetail = true;
        } else if (params.has('id')) {
            for (let i = 0; i < this.news.length; i++) {
              if (this.news[i].headline.split(' ').join('-') === params.get('id')) {
                this.currentStory = i;
                this.showDetail = true;
              }
            }
        }
      });
    });
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      (document.getElementById('background') as HTMLVideoElement).muted = true;
      (document.getElementById('background') as HTMLVideoElement).play();
    }, 2000);
  }

  reset() {
    this.showDetail = false;
    this.currentStory = -1;
    window.history.pushState({}, '', `/#/News/`);
  }
}
