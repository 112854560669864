<div class="about-container2">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Dante.webp" class="dante" style="width: 80%; margin-left: -60%; overflow: hidden;">
    <div class="wrapper1">
        <div class="about1">
            <p class="p1">THE GAME</p>
            <p class="p3">A new way to play</p><br />
            <div class="sub-wrapper">
                <div class="centerBanner"><img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp"></div>
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" class="leftLine"><br />
                <div>
                <p class="p2">&nbsp;&nbsp;&nbsp; Immerse yourself in an intense battle between light and dark, as you
                fight to restore balance within the realms of Ethereal! Wage war in action packed, 6 vs. 6 strategic
                warfare, as you fight to control the battlefield, and destroy the opposing Elder. Choose from an
                array of classes, each with their own unique abilities, discovering and mastering the potential
                they wield.
                </p>
                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
        <div class="wrapper2">
            <div class="about1">
                <p class="p1">Myths</p>
                <p class="p3">Test your skills</p><br />
                <div class="sub-wrapper">
                      <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" style="margin-bottom: .5em; width: 100%; transform: scaleX(-1);"/><br />
                    <div>
                      <p class="p2">&nbsp;&nbsp;&nbsp; Each Myth has an exclusive set of skills and abilities they can command to help
                          them control the battlefield in their own unique way. Passive abilities, Primary and Secondary attacks,
                          three unique active abilities, and a powerful Ultimate ability that can help turn the tide
                          for your team at any moment.
                      </p>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-container3">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/faded-background.webp" style="width: 100%; height: 100%;">
    <div class="faded-container">
        <p class="p1">The Goal</p>
        <p class="p3">Path to Victory</p>
        <div><img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" style="width: 100%;"></div>
        <div>
            <p class="p2">&nbsp;&nbsp;&nbsp; The path to victory is simple. Push with your allies and destroy the enemy's Elder; 
                the heart of the enemy team's defenses. This task however, will be one of possible peril if not careful. Disperse your forces across three 
                distinct lanes, maximizing the efficiency of your team combination. Allied minions will march from your base, and it is your mission to 
                escort them down each lane through the enemy forces. Standing in your way across the Ether's expansive map will be many obstacles; objectives 
                to control, neutral monsters to tame, and enemy minions and Myths to slay. The journey to glory and honor will assuredly not come easily. 
            </p>
        </div>
    </div>
</div>
<div class="about-container4">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Empower_Background.webp" style="width: 100%; height: 100%; opacity: .5;">
    <div class="faded-container">
        <p class="p1">Strength</p>
        <p class="p3">Empower your Myth</p>
        <div><img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" style="width: 100%;"></div>
        <div>
            <p class="p2">&nbsp;&nbsp;&nbsp; At the start of every match, each Myth enters the battlefield on a level playing field. 
                Starting at level one, you must power up your Myth by killing enemies; the differing types of enemies determining the reward. 
                This will grant you gold to aid in buying items for yourself, and gain experience to passively level up your Myth; unlocking 
                new abilities and increasing your statistics as you progress.
            </p>
            <p class="p2">&nbsp;&nbsp;&nbsp; Your abilities will be unique to your Myth, which you can level up as your character does, 
                but your items however, will not be prohibitive. Without restriction, you can purchase items to structure your build to your 
                personal preference. You will be able to purchase items to equip; rewarding your Myth with statistical boosts, a unique passive, 
                or possible game changing active effects.
            </p>
        </div>
    </div>
</div>
<div class="about-container5">
    <div style="position: absolute; top: 25%; left: 0; z-index: -1; width: 100%;">
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/top-footer-frame.webp" style="width: 100%;"/>
    </div>
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/item_design_line.webp" style="width: 100%;"/>
    <div class="gameModes-container">
        <div class="gameMode">
            <div style="visibility: hidden;"><p class="p1">Assault</p></div>
            <div style="visibility: hidden;"><p class="p2">3v3 Match</p></div>
            <div class="mode-wrapper" id="mode3">
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-mode-1.webp" />
            </div>
            <div class="hoverText">{{ hover3 }}</div>
            <div style="visibility: hidden;" class="mode-button">
                <button (mouseenter)="blind3()" (mouseleave)="clearText()">Blind</button>
                <button (mouseenter)="draft3()" (mouseleave)="clearText()">Draft</button>
            </div>
        </div>
        <div class="gameMode">
            <div><p class="p1">Clash</p></div>
            <div><p class="p2">6v6 Match</p></div>
            <div class="mode-wrapper" id="mode6">
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-mode-2.webp" />
            </div>
            <div class="hoverText">{{ hover6 }}</div>
            <div class="mode-button">                
                <button (mouseenter)="blind6()" (mouseleave)="clearText()">Blind</button>
                <button (mouseenter)="draft6()" (mouseleave)="clearText()">Draft</button>
            </div>
        </div>
        <div class="gameMode">
            <div style="visibility: hidden;"><p class="p1">Skirmish</p></div>
            <div style="visibility: hidden;"><p class="p2">1v1 / 2v2</p></div>
            <div class="mode-wrapper" id="mode1">
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-mode-3.webp" />
            </div>
            <div class="hoverText">{{ hover1 }}</div>
            <div style="visibility: hidden;" class="mode-button">
                <button (mouseenter)="solo()" (mouseleave)="clearText()">Solo</button>
                <button (mouseenter)="duo()" (mouseleave)="clearText()">Duo</button>
            </div>
        </div>
    </div>
</div>
<div class="about-container4">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Ways_to_play.webp" style="width: 100%; height: 100%;">
        <div class="faded-container">
            <p class="p1" style="margin: 0;">Game Modes</p>
            <p class="p3">Play your way</p>
            <div><img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" style="width: 100%;"></div>
            <div>
                <p class="p2">&nbsp;&nbsp;&nbsp; Offering many unique options to command and control the battlefield, practice and perfect your strategies 
                    and team combinations, dominating the Ether. Explore a new perspective on a traditional MOBA formula, competiting in 
                    intense 6 vs 6 warfare of epic proportions. Dominate your lanes. Prioritize nuetral objectives. Teamwork is paramount, 
                    fight to gain every advantage you can in your quest to vanquish the enemy Elder on your way to victory!  
                </p>
        </div>
    </div>
</div>
<div class="latestNews">
    <p>Latest News Highlights</p>
    <app-news-container [shortList]="true"></app-news-container>
    <button class="newsLinkButton" (click)="navNews()">More News</button>
</div>
<app-trailers></app-trailers>