<div *ngIf="showDetail" class="backButton" (click)="reset()">
  <span id="back10">&#10094;</span>
  <span id="back8">&#10094;</span>
  <span id="back6">&#10094;</span>
  <span id="back4">&#10094;</span>
  <span id="back2">&#10094;</span>
</div>
<div *ngIf="!showDetail && (news.length > 0)" style="position: relative; top: 0; left: 0;">
  <div class="overlay">
    <video id="background" autoplay muted loop src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/DustAndStarsBackground.webm"></video>
  </div>
  <app-slideshow [isnews]="true"></app-slideshow>
</div>
<br>
<br>
<app-news-container [shortList]="false" *ngIf="!showDetail"></app-news-container>
<app-news-detail *ngIf="showDetail" [news]="news[currentStory]" [storyIndex]="currentStory">
</app-news-detail>
<app-news-container [shortList]="true" *ngIf="showDetail"></app-news-container>
