<app-login *ngIf="!loggedIn" [profile]="this"></app-login>
<div style="display: flex; justify-content: center;">
    <div *ngIf="loggedIn && (profile != undefined) && user.playerName !== null && user.playerName.length > 3 && !needUsername" class="topBar" id="scrollPointProfile">
        <img alt="Malware was here!" [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Profile/' + 0 + '.png'" 
            style="width: 10vh; height: 10vh; cursor: pointer;" (click)="showPortraitOptions()">
        <!-- <img alt="Malware was here!" [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Profile/' + profile.portraitId + '.png'" 
            style="width: 10vh; height: 10vh; cursor: pointer;" (click)="showPortraitOptions()"> -->
        <div style="color: white;">
            <p style="margin: 0;">{{ profile.playerName }}</p>
        </div>
        <div>
            <span class="white">Level:</span>
            <span class="gold">{{ profile.level }}</span>
        </div>
        <div>
            <span class="white">Experience:</span>
            <span class="gold">{{ profile.experience }}</span>
        </div>
        <div>
            <div style="display: flex; justify-content: center; font-size: 2vw;">
                <p *ngIf="coins !== undefined" style="color: white; margin: 0;">{{ coins.coins }} Essence</p>
                <p *ngIf="coins === undefined" style="color: white; margin: 0;">0</p>
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/essence.webp" style="height: 4vh;">
              </div>
              <div style="display: flex; justify-content: center; font-size: 2vw;">
                <p *ngIf="coins !== undefined" style="color: white; margin: 0;">{{ coins.specialCoins }} Ether Crystals</p>
                <p *ngIf="coins === undefined" style="color: white; margin: 0;">0</p>
                <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/ether_crystals.webp" style="height: 4vh;">
              </div>
        </div>
    </div>
</div>
<div *ngIf="portraitMenu" class="portraitMenu">
    <div style="width: 100%; display: flex; justify-content: flex-end;">
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/close_icon.webp" style="width: 2.5em; height: 2.5em; cursor: pointer;" (click)="closePortraitMenu()">
    </div>
    <img alt="Malware was here!" [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Profile/' + profile.portraitId + '.png'"
        style="width: 15vh; height: 15vh; cursor: pointer; border: .1em solid rgb(202, 166, 91);" 
        (click)="choosePortrait(profile.portraitId)">
    <ng-container *ngFor="let item of portraits.slice().reverse(); index as i;">
        <img alt="Malware was here!" *ngIf="item != profile.portraitId + '.png'" (click)="choosePortrait(portraits.length - 1 - i)"
        [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Profile/' + item" style="width: 15vh; height: 15vh; cursor: pointer;">
    </ng-container>
</div>
<div class="navBarProfile" *ngIf="loggedIn && (profile != undefined) && user.playerName !== null && user.playerName.length > 3 && !needUsername">
    <button (click)="changeSection('Overview')" id="OverviewButton">Overview</button>
    <button (click)="changeSection('MatchHistory')">Match History</button>
    <button (click)="changeSection('Ranked')">Ranked</button>
    <button (click)="changeSection('Clan')">Guild</button>
</div>
<div style="width: 100%; display: flex; justify-content: flex-start; flex-wrap: wrap; margin-bottom: 1em;">
    <div *ngIf="loggedIn && (statistics != undefined) && showOverview" class="statsTableContainer">
        <div class="statsTable">
            <p style="color: silver; font-size: 3vh; margin: 0;">Your statistics</p>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Time Played:</p>
                </div>
                <p class="gold">{{ playTime }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Total Games:</p>
                </div>
                <p class="gold">{{ statistics.totalGames }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Wins:</p>
                </div>
                <p class="gold">{{ statistics.totalWins }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Losses:</p>
                </div>
                <p class="gold">{{ statistics.totalLost }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">First Blood:</p>
                </div>
                <p class="gold">{{ statistics.totalFirstBloods }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Kills:</p>
                </div>
                <p class="gold">{{ statistics.totalKills }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Deaths:</p>
                </div>
                <p class="gold">{{ statistics.totalDeaths }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Assists:</p>
                </div>
                <p class="gold">{{ statistics.totalAssists }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Creep Score:</p>
                </div>
                <p class="gold">{{ statistics.totalMinions }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Towers Destroyed:</p>
                </div>
                <p class="gold">{{ statistics.totalTowers }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Spires Destroyed:</p>
                </div>
                <p class="gold">{{ statistics.totalSpires }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Obelisks Destroyed:</p>
                </div>
                <p class="gold">{{ statistics.totalObelisks }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Wyverns Slain:</p>
                </div>
                <p class="gold">{{ statistics.totalWyvern }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Objectives Stolen:</p>
                </div>
                <p class="gold">{{ statistics.totalStolenObjectives }}</p>
            </div>
            <div class="tableRow">
                <div style="display: flex;">
                    <p class="diamond"></p>
                    <p class="white">Atropos Slain:</p>
                </div>
                <p class="gold">{{ statistics.totalAtropos }}</p>
            </div>
        </div>
    </div>
    <div *ngIf="loggedIn && statistics === undefined && showOverview" class="statsTableContainer">
        <div *ngIf="needUsername">
            <p>What name would you like displayed in game?</p>
            <input type="text" id="displayName" [value]="user.playerName" (input)="user.playerName = $event.target.value" autocomplete="displayName">
            <p style="font-size: .5em; color: white;">*Displayed names must be between 4 and 20 characters. Vulgar words are not allowed.*</p>
            <button class="setNameButton" (click)="setName()" id="setNameButton">Set Name</button>
        </div>
        <!-- <div *ngIf="!needKey && !keyRedeemed && !keyFailed && !needUsername"> -->
        <div *ngIf="!needUsername">
            <p>You don't have any statistics yet.</p>
            <p>Go play some Ethereal!</p>
        </div>
        <!-- <div *ngIf="needKey">
            <p>Redeem a Pre-Alpha Key:</p>
            <input type="text" id="key" [value]="key" (input)="key = $event.target.value" autocomplete="key">
            <div>
                <button class="redeemKeyButton" (click)="submitKey()" id="redeemKeyButton">Redeem</button>
            </div>
        </div>
        <div *ngIf="keyRedeemed">
            <p>Success! Time to enter Elysium!</p>
        </div>
        <div *ngIf="keyFailed">
            <p>This key has already been redeemed.</p>
            <p>If you feel like this is an error, please reach out to our Website support team on Discord. Link above.</p>
        </div> -->
    </div>
    <div *ngIf="loggedIn && showMatchHistory" class="matchHistoryContainer">
        <div *ngIf="matchHistory.length > 0">
            <div class="scrollboxes" style="height: 78vh;">
                <div style="display: flex; justify-content: space-around; color: silver">
                    <p>Result</p>
                    <p>Match Length</p>
                    <p>Ended At</p>
                    <p>Date of Match</p>
                </div>
                <app-matchresult *ngFor="let item of matchHistory.slice().reverse(); index as i;" [index]="i" [matchData]="item" [user]="user"></app-matchresult>
            </div>
        </div>
        <p *ngIf="!(matchHistory.length > 0)" class="gold">Hmmm, it seems you haven't spent any time in Elysium yet.</p>
    </div>
    <div *ngIf="loggedIn && showRankedStanding" class="rankedContainer">
        <p class="gold">Ranked is coming soon!</p>
    </div>
    <div *ngIf="loggedIn && showClanInformation" class="clanContainer">
        <p class="gold">Guilds are coming soon!</p>
    </div>
    <div *ngIf="loggedIn && user.playerName !== null && user.playerName.length > 3 && !needUsername" class="friendsListContainer">
        <div *ngIf="showFriendRequests" class="friendRequestContainer">
            <div *ngFor="let item of friendRequests; index as i;" class="tableRow">
                <div style="display: flex;" *ngIf="item.sent === undefined || !item.sent">
                    <img alt="Malware was here!" [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Profile/' + item.portraitId + '.png'" style="width: 1em;" >
                    <div style="display: flex; justify-content: flex-start; align-items: center;">
                        <p class="white" style="font-size: .75em;">{{ item.username }}</p>
                    </div>
                    <button (click)="acceptFriendRequest(item.id)">Accept</button>
                    <button (click)="declineFriendRequest(item.id)">Decline</button>
                </div>
            </div>
        </div>
        <div class="tableRow">
            <p style="color: silver; font-size: 3vh;">Friend <button *ngIf="friendRequests.length > 0" (click)="toggleFriendRequestList()">Requests</button></p>
            <p style="color: silver; font-size: 3vh;">Status</p>
        </div>
        <div class="friendsList">
            <div *ngFor="let item of friends" class="tableRow">
                <div style="display: flex;">
                    <img alt="Malware was here!" [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Profile/' + item.portraitId + '.png'" style="width: 1em;" >
                    <div style="display: flex; justify-content: flex-start; align-items: center;">
                        <p class="white" style="font-size: .75em;">{{ item.username }}</p>
                    </div>
                </div>
                <p class="gold">{{ statusEnum[item.status] }}</p>
            </div>
            <div *ngIf="searchResults !== undefined" id="searchResultsPanel" (click)="sendFriendRequest(searchResults.accountId)">
                <div class="tableRow">
                    <div style="display: flex;">
                        <img alt="Malware was here!" [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Profile/' + searchResults.portraitId + '.png'" style="width: 1em;" >
                        <p class="white">{{ searchResults.username }}</p>
                    </div>
                    <p class="gold">Level: {{ searchResults.level }}</p>
                </div>
                <p class="gold" style="font-size: 1.5vh;">Send {{ searchResults.username }} a friend request!</p>
            </div>
        </div>
        <div>
            <input type="text" placeholder="Search" class="searchBar" #searchBar id="searchBar" (keyup)="onKeySearch(searchBar.value)">
        </div>
    </div>
</div>