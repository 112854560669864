import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { InternalComms } from '../internal-comms.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private comms: InternalComms, private cookie: CookieService) { }

  error = '';
  securityQuestion = 'Riddle me this, Batman!';
  data = {
    code: '',
    accountId: '',
    newPassword: '',
  };
  confirmPassword = '';

  ngOnInit(): void {
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe(params => {
      this.data.accountId = params.id;
      this.data.code = params.code;
    });
  }

  changePassword() {
    this.error = '';
    if (this.data.newPassword !== this.confirmPassword) {
      this.error = 'Password fields do not match';
      return;
    }
    if (this.data.accountId.length === 0) {
      this.error = 'Unable to reset your password. Please try the reset link received in your email';
      return;
    }
    if (this.data.code.length === 0) {
      this.error = 'Please provide the code received in your email';
      return;
    }
    this.comms.changePassword(this.data).subscribe( response => {
        alert('Password reset was successful');
        window.location.href = 'https://etherealcos.com/login'
      }, error => {
        this.error = error.error.message;
      });
  }
}
