<div *ngIf="!selected" style="position: relative; top: 0; left: 0; color: white; margin-bottom: .5em;">
    <div class="centerBox">
        <div class="itemButton" (click)="changeItem()" (mouseover)="showName()" (mouseout)="hideName()">
            <img alt="Malware was here!" [src]="itemData.image" style="width: 100%; z-index: 1;">
            <img alt="Malware was here!" *ngIf="(itemData.tier == 2) || (itemData.tier == 3)" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/upgrade_tier.webp" class="tierImg">
            <img alt="Malware was here!" *ngIf="itemData.tier == 1" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/tier1.webp" class="tierImg">
            <p class="tier">Tier {{ itemData.tier }}</p>
        </div>
    </div>
    <div *ngIf="hovered" class="itemName"> {{ itemData.name }}</div>
</div>
<div *ngIf="selected" style="position: relative; top: 0; left: 0; color: white; margin: 0;">
    <div class="centerBox">
        <img alt="Malware was here!" [src]="itemData.image" style="width: 100%; z-index: 1;">
    </div>
</div>