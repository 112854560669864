<div style="width: 100%; display: flex; justify-content: center;">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/nameityoutself.webp" id="framePart">
</div>
<div *ngIf="!isHeadless" class="switches" id="scrollPointClasses">
    <!-- <button (click)="navigate('Elysium')">Elysium</button> -->
    <button (click)="navigate('Myths')">Myths</button>
    <!-- <button (click)="navigate('Familiars')">Familiars</button> -->
    <button (click)="navigate('Items')">Items</button>
    <button style="color: white; cursor: default;">Classes</button>
    <!--<button (click)="navigate('Builder')">Builder</button>-->
</div>
<div style="width: 100%; display: flex; justify-content: center;">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" id="bottomFrame">
</div>
<app-share></app-share>
<div *ngIf="classes.length > 0" style="display: flex; justify-content: center; width: 100%;">
    <div class="class-container">
        <app-class-button *ngFor="let item of classes; index as i;" [class]="item" [classes]="this" [index]="i"></app-class-button>
    </div>
</div>
<div *ngIf="classes.length > 0" style="display: flex; justify-content: center; flex-wrap: wrap;">
    <div class="subContainer1">
        <img alt="Malware was here!" [src]="classes[currentClass].classimage" style="width: 100%;">
    </div>
    <div class="subContainer2">
        <div id="currentClassName" style="font-size: 3em;">{{ classes[currentClass].className }}</div>
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" style="width: 80%;">
        <p style="font-size: 2em;">
            <span style="font-size: .8em; color: rgb(202, 166, 91);">Class Ability: </span>
            <span>{{ classes[currentClass].classAbility }}</span> - <span style="font-family: 'Raleway'; font-size: .8em;">{{ classes[currentClass].classAbilityText }}</span></p>
        <p style="font-size: 1.5em; font-family: 'Raleway'; font-style: italic;">&nbsp;&nbsp;&nbsp;{{ classes[currentClass].classDescription }}</p>
    </div>
</div>