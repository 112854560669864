import { Component, Input } from '@angular/core';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-gamemodes',
  templateUrl: './gamemodes.component.html',
  styleUrls: ['./gamemodes.component.css']
})
export class GamemodesComponent {

  @Input() homeRef: HomeComponent;

  navGame() { this.homeRef.navGame(); }
}
