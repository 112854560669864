import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NewsBlockComponent } from './news-block/news-block.component';
import { TrailersComponent } from './trailers/trailers.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { NewsComponent } from './news/news.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NewsContainerComponent } from './news-container/news-container.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { InternalComms } from './internal-comms.service';
import { ShareComponent } from './share/share.component';
import { GamemodesComponent } from './gamemodes/gamemodes.component';
import { MythsComponent } from './myths/myths.component';
import { MythDetailComponent } from './myth-detail/myth-detail.component';
import { UniverseComponent } from './universe/universe.component';
import { MythButtonComponent } from './myth-button/myth-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AbilityButtonComponent } from './ability-button/ability-button.component';
import { MultimediaComponent } from './multimedia/multimedia.component';
import { ProfileComponent } from './profile/profile.component';
import { StoreComponent } from './store/store.component';
import { LoginComponent } from './login/login.component';
import { LoreComponent } from './lore/lore.component';
import { ClassesComponent } from './classes/classes.component';
import { PatchComponent } from './patch/patch.component';
import { NoteComponent } from './note/note.component';
import { SkinButtonComponent } from './skin-button/skin-button.component';
import { RealmButtonComponent } from './realm-button/realm-button.component';
import { ClassButtonComponent } from './class-button/class-button.component';
import { ItemsComponent } from './items/items.component';
import { NewSkinButtonComponent } from './new-skin-button/new-skin-button.component';
import { LocationButtonComponent } from './location-button/location-button.component';
import { ItemboxComponent } from './itembox/itembox.component';
import { CookieService } from 'ngx-cookie-service';
import { TosComponent } from './tos/tos.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { ForumComponent } from './forum/forum.component';
import { SupportComponent } from './support/support.component';
import { ServiceComponent } from './service/service.component';
import { FamiliarComponent } from './familiar/familiar.component';
import { CommunityComponent } from './community/community.component';
import { CodexheaderComponent } from './codexheader/codexheader.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AlphabetComponent } from './alphabet/alphabet.component';
import { RealmDetailComponent } from './realm-detail/realm-detail.component';
import { ObjectivesComponent } from './objectives/objectives.component';
import { TermsboxComponent } from './termsbox/termsbox.component';
import { PrivacyboxComponent } from './privacybox/privacybox.component';
import { TestersComponent } from './testers/testers.component';
import { RegisterConfirmationComponent } from './register-confirmation/register-confirmation.component';
import { BuilderComponent } from './builder/builder.component';
import { BugReportComponent } from './bug-report/bug-report.component';
import { RoadmapComponent } from './roadmap/roadmap.component';
import { MatchresultComponent } from './matchresult/matchresult.component';
import { RoadmapGoalComponent } from './roadmap-goal/roadmap-goal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    NewsBlockComponent,
    TrailersComponent,
    SlideshowComponent,
    NewsComponent,
    NewsletterComponent,
    NewsContainerComponent,
    NewsDetailComponent,
    ShareComponent,
    GamemodesComponent,
    MythsComponent,
    MythDetailComponent,
    UniverseComponent,
    MythButtonComponent,
    AbilityButtonComponent,
    MultimediaComponent,
    ProfileComponent,
    StoreComponent,
    LoginComponent,
    LoreComponent,
    ClassesComponent,
    PatchComponent,
    NoteComponent,
    SkinButtonComponent,
    RealmButtonComponent,
    ClassButtonComponent,
    ItemsComponent,
    NewSkinButtonComponent,
    LocationButtonComponent,
    ItemboxComponent,
    TosComponent,
    PrivacyComponent,
    ContactComponent,
    FaqComponent,
    ForumComponent,
    SupportComponent,
    ServiceComponent,
    FamiliarComponent,
    CommunityComponent,
    CodexheaderComponent,
    ChangePasswordComponent,
    AlphabetComponent,
    RealmDetailComponent,
    ObjectivesComponent,
    PrivacyboxComponent,
    TermsboxComponent,
    TestersComponent,
    RegisterConfirmationComponent,
    BuilderComponent,
    BugReportComponent,
    RoadmapComponent,
    MatchresultComponent,
    RoadmapGoalComponent
  ],
  imports: [
    FontAwesomeModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    HttpClient,
    InternalComms,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
