<div id="privacyBox">
    <h1 style="text-align: center;">PRIVACY POLICY</h1>
    <p>This Privacy Policy discloses the privacy practices for Undying Games, LLC’ game, Ethereal: Clash of Souls, apps, website and various related services (together referred to as the "Site"). Undying Games, LLC, the provider of the Site (referred to as "us " or "we"), is committed to protecting your privacy online. Please read the information below to learn the following regarding your use of this Site.</p>
    <p>You acknowledge that this Privacy Policy is part of our Site Terms of Use, and by accessing or using our Site, you agree to be bound by all of its terms and conditions. If you do not agree to these terms, please do not access or use this Site.</p>
    <p>We reserve the right to change this Privacy Policy at any time. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by means including, but not limited to, issuing an email to the email address listed by registered users and posting the revised Policy on this page. You acknowledge and agree that it is your responsibility to maintain a valid email address as a registered user, review this Site and this Policy periodically and to be aware of any modifications. Your continued use of the Site after such modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) agreement to abide and be bound by the modified Policy.</p>
    <p><span class="bolded">1. Types of Information Collected</span></p>
    <p>In order to better provide you with our numerous services, we collect two types of information about our users: Personally Identifiable Information and Non-Personally Identifiable Information. Our primary goal in collecting information from you is to provide you with a smooth, efficient, and customized experience while using our Site.</p>
    <p>Personally Identifiable Information: This refers to information that lets us know the specifics of who you are. When you engage in certain activities on this Site ordering a product or service, submitting content and/or posting content in discussion forums or other public areas, entering a contest or sweepstakes, filling out a survey, or sending us feedback, we may ask you to provide certain information about yourself by filling out and submitting an online form. It is completely optional for you to elect to engage in these activities, however, we may ask that you provide us personal information, such as your first and last name, mailing address (including zip code), email address, and other personal identifying information. When ordering products or services on the Site, you may be asked to provide a credit card number. Depending upon the activity, some of the information we ask you to provide is identified as mandatory and some as voluntary. If you do not provide the mandatory data with respect to a particular activity, you will not be able to engage in that activity.</p>
    <p>Non-Personally Identifiable Information: This refers to information that does not by itself identify a specific individual. We gather certain information about you based upon where you visit on our Site in several ways. This information is compiled and analyzed on both a personal and an aggregated basis. This information may include the website's Uniform Resource Locator ("URL") that you just came from, which URL you next go to, what browser you are using, and your Internet Protocol ("IP") address. A URL is the global address of documents and other resources on the World Wide Web. An IP address is an identifier for a computer or device on a Transmission Control Protocol/Internet Protocol ("TCP/IP") network, such as the World Wide Web. Networks like the Web use the TCP/IP protocol to route information based on the IP address of the destination. In other words, an IP address is a number that is automatically assigned to your computer whenever you are surfing the web, allowing web servers to locate and identify your computer. Computers require IP addresses in order for users to communicate on the Internet.</p>
    <p><span class="bolded">2. Collection Methods and Use of Information</span></p>
    <p>You provide certain Personally Identifiable Information to us when you (a) register for our services and register your email address with us; (b) sign up for special offers from selected third parties; (c) send email messages, submit forms or transmit other information by telephone or letter; or (d) submit your credit card or other payment information when ordering and purchasing products and services on our Site; (e) your support-related info, including the nature of your concern and your account or order details; (f) your preferences, interests, and general demographic information (such as your hobbies, favorite games, or other information you share, like when you respond to a survey); (g) your Site account information (like your preferred game settings, key bindings, friends list, etc.); and (h) information you decide to give us (which may include information about your health and other “sensitive” info) so that we can better make the Site accessible to you by accommodating specific needs you may have, such as being deaf, hard of hearing, blind or visually impaired. We may also collect information from you at other points on our Site that state that such information is being collected.</p>
    <p>The Site may also offer social features like voice and text chat. When you use these features, the information you share may be seen or overheard by others. We may collect and use the information you provide, including to enforce our Terms of Service, and in some cases it may also be collected or used by others without your knowledge (for example, if you’re chatting with another player while they stream a match).</p>
    <p>When you play multiplayer games like Ethereal: Clash of Souls, note that some information will be publicly accessible to others, such as your game statistics and performance. We may record gameplay and related info, and replay or otherwise make it available to other players without further notice to you. For example, players may be able to watch each other play our games using different, and we may use gameplay footage to help promote or improve the Site.</p>
    <p>If you disclose any information relating to other people to us or to our service providers in connection with the Site, you represent that you have the authority to do so and to permit us to use the information in accordance with this privacy notice.</p>
    <p>We may automatically collect some information about how you interact with and navigate the Site, as well as the device and software you use to do so.</p>
    <p>In addition, we may also collect, or our third-party ad server and/or content server may collect, certain Non-Personally Identifiable Information. This information is ultimately stored in the form of store categories, and, in some cases, specific URLs. We use your IP address to diagnose problems with our servers, software, to administer our Site and to gather demographic information. Our third-party ad servers will also provide us with summary, but not individual, reports that will tell us how many ads were presented and clicked upon at our Site.</p>
    <p>We will primarily use your Personally Identifiable Information to provide our services to you, as required by our agreement(s) with you. We will also use Personally Identifiable Information to enhance the operation of our Site, fill orders, improve our marketing and promotional efforts, statistically analyze site use, improve our product and service offerings, and customize our Site's content, layout, and services. We may use Personally Identifiable Information to deliver information to you and to contact you regarding administrative notices. We may also use Personally Identifiable Information to resolve disputes, troubleshoot problems and enforce our agreements with you, including our Site Terms of Use, Sales Terms and Conditions, and this Private Policy.</p>
    <p>We may also collect information from third parties to supplement the information you provide and the information we collect automatically for our legitimate business purposes. For example, (i) we may obtain information from third parties if you use their services in connection with the Site (such as linking your Facebook account with the Site via the Add Friends feature). Your privacy settings on the third party’s service control what information they can share with us—it can include information like your name, profile picture, gender, age range, username, language, country, your friends and other information you have made available on the platform. Please make sure you’re comfortable with what they might share by reviewing their posted policies, and if applicable, modifying your privacy settings directly on their service. (ii) We gather advertising and analytics information from third parties to help support our marketing initiatives, improve the Site, and better manage our ad campaigns (like by displaying more personalized ads and measuring how effective they are). (iii) We collect information from third parties to help us identify from where you’re using the Site (like your approximate location based on your IP address). This helps us, for example, process purchases, prevent fraud or abuse of the Site, and customize your visit. (iv) We may receive information from third-party platforms if you download the Site on your mobile device or laptop. This might include, for example, technical details and the fact that you’ve downloaded one of our apps. (v) Facebook may provide us with information about your use of our fan pages.</p>
    <p><span class="bolded">3. Privacy of Children</span></p>
    <p>Undying Games, LLC does not knowingly collect personal information from children under thirteen (13). If a child under thirteen (13) submits personal information to us and we learn that personal information is the information of a child under thirteen (13), we will attempt to delete the information as soon as possible. It is our policy to comply with the Children’s Online Privacy Protection Act of 1998 and all other applicable laws. Therefore, we restrict our website to persons eighteen (18) years or older.</p>
    <p class="upper">USER MUST BE EIGHTEEN (18) YEARS OR OLDER TO ACCESS OUR WEBSITE. IF USER IS UNDER EIGHTEEN YEARS OF AGE, USER IS NOT PERMITTED TO ACCESS OUR WEBSITE FOR ANY REASON WITHOUT THE CONSENT OF THEIR PARENT OR LEGAL GUARDIAN.</p>
    <p><span class="bolded">4. Release of Information</span></p>
    <p>We do not sell, trade, or rent your Personally Identifiable Information to others. We do provide some of our services through contractual arrangements with affiliates, services providers, partners and other third parties. We and our service partners use your Personally Identifiable Information to operate our Sites and to deliver their services. For example, we must release your credit card information to the card-issuing bank to confirm payment for products and services purchased on this Site and provide order information to third parties that help us provide customer service.</p>
    <p>If we ever discontinue offering the Site or certain parts of it, we may share your information with a buyer. Also, remember that anything you share in social features, like voice and text chat, is public, so please be careful about how you use them. We may also disclose player information in the event of any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings). This means your information may be one of the assets transferred to the acquiring entity in such situations, according to the Site’s legitimate interest in engaging in such transactions and operating and expanding our business. Please note that your in-game name and statistics are viewable to others and may be shared in-game and out-of-game with players and other third parties.</p>
    <p>We will encourage our service partners to adopt and post privacy policies. However, the use of your Personally Identifiable Information by our service partners is governed by the privacy policies of those service partners and is not subject to our control.</p>
    <p>Occasionally we may be required by law enforcement or judicial authorities to provide Personally Identifiable Information to the appropriate governmental authorities. We will disclose Personally Identifiable Information upon receipt of a court order, subpoena, or to cooperate with a law enforcement investigation. We fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful.</p>
    <p>We may also provide Non-Personally Identifiable Information about our customers' sales, traffic patterns, and related site information to third party advertisers, but these statistics do not include any Personally Identifiable Information.</p>
    <p><span class="bolded">5. Updating and Correcting Information</span></p>
    <p>We believe you should have the ability to access and edit the Personally Identifiable Information that you have provided to us. You may change any of your Personally Identifiable Information in your account online at any time by linking to your account in accordance with instructions posted elsewhere on this Site. You may also access and correct your personal information and privacy preferences by writing us at:</p>
    <p class="noMargin">Undying Games, LLC</p>
    <p class="noMargin">3306 Signal Hill Dr</p>
    <p class="noMargin">Friendswood, TX 77546</p>
    <p>Please include your name, address, and/or email address when you contact us.</p>
    <p>We encourage you to promptly update your Personally Identifiable Information if it changes. You may ask to have the information on your account deleted or removed; however, because we keep track of past transactions, you cannot delete information associated with past transactions on this Site. In addition, it may be impossible to completely delete your information without some residual information because of backups.</p>
    <p><span class="bolded">6. User Choices on Collection and Use of Information</span></p>
    <p>We may, from time to time, send you email regarding our products and services. In addition, if you indicated upon registration that you are interested in receiving offers or information from us and our partners. Only Undying Games, LLC (or agents working on behalf of Undying Games, LLC and under confidentiality agreements) will send you these direct mailings and only if you indicated that you do not object to these offers. If you do not want to receive such mailings, simply tell us when you give us your personal information. Or, at any time you can easily edit your account information to no longer receive such offers and mailings.</p>
    <p>You also have choices with respect to cookies, as described below. By modifying your browser preferences, you have the choice to accept all cookies, to be notified when a cookie is set, or to reject all cookies. If you choose to reject all cookies some parts of our Site may not work properly in your case.</p>
    <p><span class="bolded">7. Security of Information</span></p>
    <p>At our Site you can be assured that your Personally Identifiable Information is secure, consistent with current industry standards. The importance of security for all Personally Identifiable Information associated with our user is of utmost concern to us. Your Personally Identifiable Information is protected in several ways. Access by you to your Personally Identifiable Information is available through a password and unique customer ID selected by you. This password is encrypted. We recommend that you do not divulge your password to anyone. In addition, your Personally Identifiable Information resides on a secure server that only selected Undying Games, LLC personnel and contractors have access to via password. We encrypt your Personally Identifiable Information and thereby prevent unauthorized parties from viewing such information when it is transmitted to us.</p>
    <p>Personal information that you provide that is not Personally Identifiable Information also resides on a secure server and is only accessible via password. Since this information is not accessible from outside Undying Games, LLC you will not be asked to select a password in order to view or modify such information.</p>
    <p>In order to most efficiently serve you, credit card transactions and order fulfillment are handled by established third party banking, processing agents and distribution institutions. They receive the information needed to verify and authorize your credit card or other payment information and to process and ship your order.</p>
    <p>Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your Personally Identifiable Information, you acknowledge that: (a) there are security and privacy limitations of the Internet which are beyond our control; (b) the security, integrity and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed; and (c) any such information and data may be viewed or tampered with in transit by a third party.</p>
    <p><span class="bolded">8. Cookies</span></p>
    <p>When you use our Site we will store cookies on your computer in order to facilitate and customize your use of our Site. A cookie is a small data text file, which a website stores on your computer's hard drive (if your web browser permits) that can later be retrieved to identify you to us. Our cookies store randomly assigned user identification numbers, the country where you are located, and your first name to welcome you back to our Site. The cookies make your use of the Site easier, make the Site run more smoothly and help us to maintain a secure site. You are always free to decline our cookies if your browser permits, but some parts of our Site may not work properly in that case.</p>
    <p>We may use cookies, web beacons, and similar technologies to automatically collect, store, and read files on your device. They may be delivered in a first-party (i.e., us) or third-party (i.e., by other companies) context. These tools help us work and operate more efficiently, as well as capture information about how you interact with us.</p>
    <p>We may also use our servers in connection with these technologies to collect information about the ways you interact with us and store it in log files. This may include, for example, information that your device, browser, or operating system sends to us when you access the Site and details about how you use your Site account.</p>
    <p>We may use an outside ad serving company to display banner advertisements on our Site. As part of their service, they will place a separate cookie on your computer. We will not provide any third-party ad server with any of your Personally Identifiable Information or information about your purchases. We and our third party ad server will collect and use Non-Personally Identifiable Information about you, such as your IP address, browser type, the server your computer is logged onto, the area code and zip code associated with your server and whether you responded to a particular ad. Other advertisers may also place banner ads on our Site in the same manner as above, but we will not disclose any Personally Identifiable Information to them.</p>
    <p>Please note that disabling technologies like cookies may prevent you from using the full functionality of the Site and the Site may not work as we intended.</p>
    <p><span class="bolded">9. Privacy Policies of Third-Party Sites</span></p>
    <p>Except as otherwise discussed in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. Other sites accessible through our Site have their own privacy policies and data collection, use and disclosure practices. Please consult each site's privacy policy. We are not responsible for the policies or practices of third parties. Additionally, other companies which place advertising on our Site may collect information about you when you view or click on their advertising through the use of cookies. We cannot control this collection of information. You should contact these advertisers directly if you have any questions about their use of the information that they collect.</p>
    <p><span class="bolded">10. Your General Data Protection Regulation (“GDPR”) Rights</span></p>
    <p>If you are located within the European Union (“EU”), you are entitled to certain rights under the GDPR. You have the right to: (i) Know how long we will keep your information. We will keep your information until the earlier of: (a) your request to delete your information or (b) the company decides it no longer needs the data and the cost of retaining it outweighs the value to keeping it; (ii) Access, rectify or erase your personal information; (iii) Withdraw your consent to the company’s processing of your data, which shall have no effect on the lawfulness of the processing of your personal information prior to your withdrawal; (iv) Lodge a complaint with a supervisory authority that has jurisdiction over GDPR issues; and (v) Provide only your personal information which is reasonably required to enter into a contract with us. We will not ask for your consent to provide unnecessary personal information on the condition of entering into a contractual relationship with the company.</p>
    <p><span class="bolded">11. California Consumer Privacy Act</span></p>
    <p>This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the information contained in the Privacy Policy and applies solely to visitors, users, and others who reside in the State of California (“consumers” or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (“CCPA”) and other California privacy laws. Any terms defined in the CCPA have the same meaning when used in this notice.</p>
    <p class="underlined">Information We Collect</p>
    <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”). In particular, we have collected the following categories of personal information from consumers within the last twelve (12) months:</p>
    <table id="californiaCodeTable">
        <tr>
            <th>Category</th>
            <th>Examples</th>
            <th>Collected</th>
        </tr>
        <tr>
            <td>A. Identifiers.</td>
            <td>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers.</td>
            <td>YES</td>
        </tr>
        <tr>
            <td>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
            <td>A name, signature, address, telephone number, bank account number, credit card number, debit card number, or any other financial information. Some personal information included in this category may overlap with other categories.</td>
            <td>YES</td>
        </tr>
        <tr>
            <td>C. Protected classification characteristics under California or federal law.</td>
            <td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>D. Commercial information.</td>
            <td>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>E. Biometric information.</td>
            <td>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>F. Internet or other similar network activity.</td>
            <td>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</td>
            <td>YES</td>
        </tr>
        <tr>
            <td>G. Geolocation data.</td>
            <td>Physical location or movements.</td>
            <td>YES</td>
        </tr>
        <tr>
            <td>H. Sensory data.</td>
            <td>Audio, electronic, visual, thermal, olfactory, or similar information.</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>I. Professional or employment-related information.</td>
            <td>Current or past job history or performance evaluations.</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
            <td>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</td>
            <td>NO</td>
        </tr>
        <tr>
            <td>K. Inferences drawn from other personal information.</td>
            <td>Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
            <td>YES</td>
        </tr>
    </table>
    <p>Personal information does not include:</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Publicly available information from government records.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;De-identified or aggregated consumer information.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Information excluded from the CCPA's scope, like:</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&ordm;&nbsp;health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&ordm;&nbsp;personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</p>
    <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Directly from our clients or their agents. For example, from documents that our clients provide to us related to the services for which they engage us.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Indirectly from our clients or their agents. For example, through information we collect from our clients in the course of providing services to them.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Directly and indirectly from activity on our website. For example, from submissions through our website portal or website usage details collected automatically.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;From third parties that interact with us in connection with the services we perform. For example, from third party medical suppliers.</p>
    <p class="underlined">Use of Personal Information</p>
    <p>We may use or disclose the personal information we collect for one or more of the following business purposes:</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;To fulfill or meet the reason for which the information is provided.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;To provide you with information, products or services that you request from us.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;To provide you with email alerts, event registrations and other notices concerning our products or services, or events or news, that may be of interest to you.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;To improve our website and present its contents to you.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;For testing, research, analysis and product development.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;As necessary or appropriate to protect the rights, property or safety of us, our clients or others.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;As described to you when collecting your personal information or as otherwise set forth in the CCPA.</p>
    <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
    <p class="underlined">Sharing Personal Information</p>
    <p>We may disclose your personal information to a third party for a business purpose. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</p>
    <p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
    <p>Category A:	Identifiers.</p>
    <p>Category B: 	California Customer Records personal information categories.</p>
    <p>Category C: 	Protected classification characteristics under California or federal law.</p>
    <p>Category I: 	Professional or employment-related information.</p>
    <p>We disclose your personal information for a business purpose to the following categories of third parties:</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Our affiliates.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Service providers.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you.</p>
    <p>In the preceding twelve (12) months, we have not sold any personal information.</p>
    <p class="underlined">Your Rights and Choices</p>
    <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
    <p class="italisized">Access to Specific Information and Data Portability Rights</p>
    <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past twelve (12) months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;The categories of personal information we collected about you.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;The categories of sources for the personal information we collected about you.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Our business or commercial purpose for collecting or selling that personal information.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;The categories of third parties with whom we share that personal information.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;The specific pieces of personal information we collected about you (also called a data portability request).</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&ordm;&nbsp;sales, identifying the personal information categories that each category of recipient purchased; and</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&ordm;&nbsp;disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</p>
    <p class="italisized">Deletion Request Rights</p>
    <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
    <p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
    <p>&nbsp;&nbsp;1.&nbsp;Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</p>
    <p>&nbsp;&nbsp;2.&nbsp;Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</p>
    <p>&nbsp;&nbsp;3.&nbsp;Debug products to identify and repair errors that impair existing intended functionality.</p>
    <p>&nbsp;&nbsp;4.&nbsp;Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</p>
    <p>&nbsp;&nbsp;5.&nbsp;Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 <span class="italisized">seq.</span>).</p>
    <p>&nbsp;&nbsp;6.&nbsp;Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</p>
    <p>&nbsp;&nbsp;7.&nbsp;Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</p>
    <p>&nbsp;&nbsp;8.&nbsp;Comply with a legal obligation.</p>
    <p>&nbsp;&nbsp;9.&nbsp;Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</p>
    <p class="italisized">Exercising Access, Data Portability, and Deletion Rights</p>
    <p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to owwen@undyinggames.com. Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</p>
    <p>You may only make a verifiable consumer request for access or data portability twice within a twelve (12) month period. The verifiable consumer request must:</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</p>
    <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
    <p class="italisized">Response Timing and Format</p>
    <p>We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the twelve (12) month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily usable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
    <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
    <p class="underlined">Non-Discrimination</p>
    <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Deny you goods or services.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Provide you a different level or quality of goods or services.</p>
    <p>&nbsp;&nbsp;&#8226;&nbsp;Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>
    <p class="underlined">Changes to Our Privacy Notice</p>
    <p>We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will notify you by email or through a notice on our website homepage.</p>
    <p class="underlined">Contact Information</p>
    <p>If you have any questions or comments about this notice, our Privacy Statement, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:</p>
    <p class="noMargin">Undying Games, LLC</p>
    <p class="noMargin">Attn: Privacy Compliance Officer</p>
    <p class="noMargin">3306 Signal Hill Dr.</p>
    <p class="noMargin">Friendswood, TX 77546</p>
    <p class="noMargin">808-498-5685</p>
    <p>owwen@undyinggames.com</p>
    <p><span class="bolded">12. Miscellaneous Privacy Issues</span></p>
    <p>You should also be aware that when Personally Identifiable Information is voluntarily disclosed (i.e. your name, email address, etc.) in the discussion forums or other public areas on this Site, that information, along with any information disclosed in your communication, can be collected and used by third parties and may result in unsolicited messages from third parties. Such activities are beyond our control and this Policy does not apply to such information. Any submissions to chat rooms or other public areas on this Site are accepted with the understanding that they are accessible to all third parties. If you do not want your comments to be viewed by third parties, you are advised not to make any submissions. Ultimately, you are solely responsible for maintaining the secrecy of your password and/or account information. Please be careful and responsible whenever you are online.</p>
    <p>If you have any questions about this Privacy Policy, the practices of this Site, or your dealings with this Site, please contact us by sending a letter to:</p>
    <p class="noMargin">Undying Games, LLC</p>
    <p class="noMargin">Attn: Privacy Compliance Officer</p>
    <p class="noMargin">3306 Signal Hill Dr.</p>
    <p class="noMargin">Friendswood, TX 77546</p>
    <p>You may also contact us by email at owwen@undyinggames.com</p>
</div>