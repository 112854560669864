<div [id]="'container ' + index" class="container" (click)="set()" (mouseover)="hover()" (mouseleave)="unhover()">
  <ng-container *ngIf="!selected; else elseBlock">
    <img alt="Malware was here!" style="opacity: .5;" [src]="images[current]">
  </ng-container>
  <ng-template #elseBlock>
    <img alt="Malware was here!" style="opacity: 1;" [src]="images[current]">
  </ng-template>
  <p>{{ slot }}</p>
  <p class="p2">{{ name }}</p>
</div>
