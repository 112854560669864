import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

export class ShootingStarsComponent{

  readonly availableColors = [
    'rgba(88,228,247,1.0)',
    'rgba(147,128,69,1.0)',
    'rgba(99,74,44,1.0)',
    'rgba(29,66,55,1.0)',
    'rgba(158,61,71,1.0)',
    'rgba(51,102,147,1.0)',
    'rgba(251,201,65,1.0)',
    'rgba(79,61,102,1.0)',
    'rgba(255,255,255,1.0)'
  ];
  size = [1, 0];
  elem: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;

  constructor(element: HTMLCanvasElement) {
    this.elem = element;
    this.ctx = element.getContext('2d');
    this.size[0] = element.clientWidth;
    this.size[1] = element.clientHeight;
  }

  generateRandomColor() {
    return this.availableColors[Math.floor(Math.random() * (this.availableColors.length))];
  }

  clear() {
    this.ctx.clearRect(0, 0, this.size[0], this.size[1]);
  }

  generateStar(): ShootingStar {
    let x;
    let y;
    const dir = [];
    let len;
    let sp;
    if (Math.random() > 0.5) {
      if (Math.random() > 0.5) {
        x = 0;
        dir[0] = Math.random() * 0.6 + 0.2;
      } else {
        x = this.size[0];
        dir[0] = -(Math.random() * 0.6 + 0.2);
      }
      dir[1] = Math.random() - 0.5;
      y = Math.floor((Math.random() * 0.6 + 0.2) * this.size[1]);
    } else {
      if (Math.random() > 0.5) {
        y = 0;
        dir[1] = Math.random() * 0.6 + 0.2;
      } else {
        y = this.size[1];
        dir[1] = -(Math.random() * 0.6 + 0.2);
      }
      dir[0] = Math.random() - 0.5;
      x = Math.floor((Math.random() * 0.6 + 0.2) * this.size[0]);
    }
    const mag = Math.sqrt(Math.pow(dir[0], 2) + Math.pow(dir[1], 2));
    dir[0] /= mag;
    dir[1] /= mag;
    len = 20 + Math.floor(Math.random() * 50);
    sp = 3 + Math.floor(Math.random() * 3);
    return new ShootingStar(this.ctx, x, y, dir, len, sp, this.generateRandomColor());
  }

}

export class ShootingStar{
  lifetime = 0;
  lifeSpent = 0;
  dead = false;
  constructor(public canvasContext: CanvasRenderingContext2D, public xCoord: number, public yCoord: number,
              public direction: number[], public length: number, public speed: number, public color: string) {
    this.lifetime = 15000;
  }

  moveSteps(ms: number, steps: number = 1) {
    this.xCoord += (100 * this.speed * steps * this.direction[0] / ms);
    this.yCoord += (100 * this.speed * steps * this.direction[1] / ms);
    this.lifeSpent += ms;
    if (this.lifeSpent > this.lifetime) {
      this.dead = true;
    }
  }

  drawCircle(x: number, y: number) {
    const grad = this.canvasContext.createRadialGradient(x, y, 1, x, y, 3);
    grad.addColorStop(0, this.color);
    grad.addColorStop(1, 'rgba(0,0,0,0.0)');
    this.canvasContext.fillStyle = grad;
    this.canvasContext.arc(x, y, 3, 0, 2 * Math.PI);
    this.canvasContext.fill();
  }

  drawTrail(start, end, length) {
    // const step = [end[0] - start[0], end[1] - start[1]];
    this.canvasContext.strokeStyle = this.color;
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(start[0], start[1]);
    this.canvasContext.lineTo(end[0], end[1]);
    this.canvasContext.stroke();
    this.canvasContext.fill();
  }

  draw() {
    this.drawCircle(this.xCoord, this.yCoord);
    this.drawTrail([this.xCoord, this.yCoord], [this.xCoord - this.direction[0] * this.length,
        this.yCoord - this.direction[1] * this.length], this.length);
  }
}
