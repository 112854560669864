<ng-container >
    <div style="width: 100%; display: flex; justify-content: center;">
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/nameityoutself.webp" id="framePart">
    </div>
    <div *ngIf="!isHeadless" class="switches">
        <!-- <button (click)="navigate('Elysium')">Elysium</button> -->
        <button (click)="navigate('Myths')">Myths</button>
        <!-- <button (click)="navigate('Familiars')">Familiars</button> -->
        <button style="color: white; cursor: default;">Items</button>
        <button (click)="navigate('Classes')">Classes</button>
        <!--<button (click)="navigate('Builder')">Builder</button>-->
    </div>
    <div style="width: 100%; display: flex; justify-content: center;">
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" id="bottomFrame">
    </div>
</ng-container>
<app-share></app-share>
<div *ngIf="tooltip !== '' && !tooltip.includes('http');" class="tooltip">
    <span>&nbsp;{{ tooltip }}&nbsp;</span>
</div>
<div *ngIf="tooltip.includes('http');" class="tooltip" style="background-color: transparent; border: none;">
    <img [src]="tooltip" style="width: 50%;">
</div>
<div class="overallContainer">
    <div class="filters">
        <div *ngFor="let item of filters; index as i;" style="width: 8em;">
            <p class="filterButtons" (click)="massClick(i)" (mouseenter)="showCatToolTip(item[0])" (mouseleave)="hideToolTip()">
             &emsp;{{ item[0] }}
            </p>
            <div class="divider"></div>
            <br>
            <div *ngFor="let subitem of item[1]; index as j;" style="font-size: .8em;">
                <label class="container" (mouseenter)="showToolTip(i, j)" (mouseleave)="hideToolTip()">{{ subitem.replace('Cooldown', 'Cooldown Reduction') }}
                    <input type="checkbox" checked="checked" class="checkbox-filter" [attr.id]="subitem" (change)="filterItems()">
                    <span class="checkmark"></span>
                </label>
            </div>
            <br>
        </div>
        <div style="width: 8em;">
            <div>
                <p class="filterButtons" (click)="massClick(filters.length + 1)" (mouseenter)="showCatToolTip('Tiers')"
                    (mouseleave)="hideToolTip()">&emsp;Tiers
                </p>
            </div>
            <div class="divider"></div>
            <br>
            <div *ngFor="let item of tiers" style="font-size: .8em;">
                <label class="container">{{ item }}
                    <input type="checkbox" checked="checked" class="tier-filter" [attr.id]="item" (change)="filterItems()">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div>
                <label class="container">All
                    <input type="checkbox" checked="checked" class="tier-filter" [attr.id]="'all'" (change)="setAll()">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div>
                <label class="container" (mouseenter)="showInlcusiveToolTip()" (mouseleave)="hideToolTip()">Inclusive
                    <input type="checkbox" checked="checked" class="tier-filter" [attr.id]="'andor'" (change)="setInclusive()">
                    <span class="checkmark"></span>
                </label>
            </div>
            <br>
        </div>
        <br>
        <div style="width: 8em;">
            <div>
                <p class="filterButtons" (click)="massClick(filters.length)" (mouseenter)="showCatToolTip('Type')"
                    (mouseleave)="hideToolTip()">&emsp;Type
                </p>
            </div>
            <div class="divider"></div>
            <br>
            <div>
                <div *ngFor="let item of types" style="font-size: .8em;">
                    <label class="container"> {{ item }}
                        <input type="checkbox" checked="unchecked" class="type-filter" [attr.id]="item" (change)="filterItems()">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="overallItemsContainer">
        <div style="color: white; width: 18em; border: .01em solid rgb(198, 169, 83); position: relative; top: 0; left: 0;">
            <img src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Buttons/Overview.png" class="mag">
            <input type="text" placeholder="Search by Name" class="searchBar" #searchBar id="searchBar" (keyup)="onKeySearch(searchBar.value)">
        </div>
        <div *ngIf="itemdata.length > 0" style="display: flex; justify-content: center; width: 100%; color: white;" id="countbox">
            Item Count: {{ selected.length }}
        </div>
        <div style="display: flex; justify-content: center;" id="itemlistbox">
            <div class="itemlist">
                <div *ngFor="let item of selected;" class="itemButton" (click)="selectItem(item.itemNumber)">
                    <div><img class="itemImage" alt="Malware was here!" [src]="item.image" style="width: 5em; height: 5em;"></div>
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="height: .25em; width: 100%; background: rgb(202,166,91); margin-bottom: 1em;"></div>
<div *ngIf="showItemDetail" class="itemDetail">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/close_icon.webp" style="width: 5%; cursor: pointer; position: absolute; left: 90%; top: 5%; z-index: 2;" (click)="closeDetail()">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/item_design_line.webp" style="width: 100%;">
    <img alt="Malware was here!" *ngIf="showTree" class="fullTree" [src]="fullTree">
    <div style="margin-bottom: 2vh;">
        <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly;">
            <div class="detailsBox">
                <div *ngIf="itemdata[currentItem].parents.length > 0" class="buildFrom">
                    Builds from:
                    <div class="treeTier">
                        <app-itembox *ngFor="let item of parents" style="transform: scale(.75);" [itemComponent]="this" [itemData]="item"></app-itembox>
                    </div>
                </div>
                <div class="detailsBox">
                    <p *ngIf="(itemdata[currentItem].children.length > 0 || itemdata[currentItem].parents > 0)" class="treelink" (mouseover)="showFullTree()" (mouseout)="hideFullTree()">View Full Tree</p>
                    <app-itembox [itemComponent]="this" [itemData]="itemdata[currentItem]" [selected]="true"></app-itembox>
                </div>
                <p class="nameBox">{{ itemdata[currentItem].name }}</p>
                <div class="detailsScrollBox">
                    <div *ngFor="let item of itemdata[currentItem].details; index as i;">
                        <p *ngIf="i%2 == 0 && item.length > 0" class="ability">{{ item }}</p>
                        <p *ngIf="i%2 != 0" class="description">&nbsp;{{ item }}</p>
                    </div>
                </div>
                <div class="divider2"></div>
                <div *ngFor="let item of itemdata[currentItem].category; index as i;">
                    <p class="stats">{{ itemdata[currentItem].stats[i] }} {{ item.replace('Cooldown', 'Cooldown Reduction') }}</p>
                </div>
                <p style="margin: 0">*Item values subject to change*</p>
                <div class="divider2"></div>
                <div *ngIf="itemdata[currentItem].cost <= 0">
                    <p style="margin: 0; font-size: .8vw; color: white;">*The cost is a mystery of the Ether*</p>
                </div>
                <div *ngIf="itemdata[currentItem].cost > 0" style="color: rgb(202,166,91); font-size: 2vw;">
                    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/cost.webp" style="width: 2vw;"> {{ itemdata[currentItem].cost }}
                    <p style="margin: 0; font-size: .7vw; color: white;">*Gold cost subject to change*</p>
                </div>
                <div *ngIf="itemdata[currentItem].children.length > 0">
                    Builds to:
                    <div class="treeTier">
                        <app-itembox *ngFor="let item of children" style="width: 10em;" [itemComponent]="this" [itemData]="item"></app-itembox>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-share></app-share>
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/item_design_line.webp" style="width: 100%; -webkit-transform: scaleY(-1);
    transform: scaleY(-1); -ms-transform: scaleY(-1);">
</div>