import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { MythsComponent } from './myths/myths.component';
import { MultimediaComponent } from './multimedia/multimedia.component';
import { ProfileComponent } from './profile/profile.component';
import { LoreComponent } from './lore/lore.component';
import { PatchComponent } from './patch/patch.component';
import { StoreComponent } from './store/store.component';
import { TosComponent } from './tos/tos.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { ForumComponent } from './forum/forum.component';
import { SupportComponent } from './support/support.component';
import { ServiceComponent } from './service/service.component';
import { FamiliarComponent } from './familiar/familiar.component';
import { CommunityComponent } from './community/community.component';
import { ItemsComponent } from './items/items.component';
import { ClassesComponent } from './classes/classes.component';
import { UniverseComponent } from './universe/universe.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ObjectivesComponent } from './objectives/objectives.component';
import { TestersComponent } from './testers/testers.component';
import { RegisterConfirmationComponent } from './register-confirmation/register-confirmation.component';
//import { BuilderComponent } from './builder/builder.component';
import { BugReportComponent } from './bug-report/bug-report.component';
//import { RoadmapComponent } from './roadmap/roadmap.component';

const routes: Routes = [
  { path: '', redirectTo: 'Home', pathMatch: 'full'},
  { path: 'Home', component: HomeComponent },
  { path: 'About', component: AboutComponent },

  { path: 'News', component: NewsComponent },
  { path: 'News/:id', component: NewsComponent, children: [{ path: '**', component: NewsComponent }] },
  { path: 'CC', component: CommunityComponent },
  // { path: 'Wallpapers', component: MultimediaComponent },
  { path: 'Service', component: ServiceComponent },
  //{ path: 'Roadmap', component: RoadmapComponent },
  // { path: 'Patch', component: PatchComponent },

  { path: 'Myths', component: MythsComponent },
  { path: 'Myths/:id', component: MythsComponent, children: [{ path: '**', component: MythsComponent }] },
  { path: 'Classes', component: ClassesComponent },
  { path: 'Classes/:id', component: ClassesComponent, children: [{ path: '**', component: ClassesComponent }] },
  { path: 'Items', component: ItemsComponent },
  { path: 'Items/:id', component: ItemsComponent, children: [{ path: '**', component: ItemsComponent }] },
  // { path: 'Familiars', component: FamiliarComponent },
  // { path: 'Elysium', component: ObjectivesComponent },

  { path: 'Realms', component: UniverseComponent },
  { path: 'Realms/:id', component: UniverseComponent, children: [{ path: '**', component: UniverseComponent }] },
  { path: 'Library', component: LoreComponent },
  { path: 'Library/:id', component: LoreComponent, children: [{ path: '**', component: LoreComponent }] },

  // { path: 'Store', component: StoreComponent },
  { path: 'Profile', component: ProfileComponent },
  { path: 'Profile/:forgotpassword', component: ProfileComponent, children: [{ path: '**', component: ProfileComponent }] },

  // TODO: match route on /Profile/Change-Password ?
  { path: 'Change-Password', component: ChangePasswordComponent },
  
  { path: 'Bug-Report', component: BugReportComponent },
  //{ path: 'Builder', component: BuilderComponent },

  { path: 'TOS', component: TosComponent },
  { path: 'Privacy', component: PrivacyComponent },

  // { path: 'Contact', component: ContactComponent },

  // { path: 'FAQ', component: FaqComponent },
  // { path: 'Forum', component: ForumComponent },

  // { path: 'Support', component: SupportComponent },

  { path: 'Testers', component: TestersComponent },
  { path: 'Registration', component: RegisterConfirmationComponent },
  { 
    path: 'Registration/:id/:code', component: RegisterConfirmationComponent,
    children: [{path: '**', component: RegisterConfirmationComponent}]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', useHash: true})],

  exports: [RouterModule]
})
export class AppRoutingModule { }
