import { Component, Input } from '@angular/core';
import { ClassesComponent } from '../classes/classes.component';

@Component({
  selector: 'app-class-button',
  templateUrl: './class-button.component.html',
  styleUrls: ['./class-button.component.css']
})
export class ClassButtonComponent {


  @Input() class;
  @Input() classes: ClassesComponent;
  @Input() index;

  setClass() {
    this.classes.selectClass(this.index);
  }
}
