<div class="backButton" (click)="reset()">
  <span id="back10">&#10094;</span>
  <span id="back8">&#10094;</span>
  <span id="back6">&#10094;</span>
  <span id="back4">&#10094;</span>
  <span id="back2">&#10094;</span>
</div>
<div class="section" id="Video">
  <div style="display: flex;">
    <video autoplay loop preload="auto" [src]="idleLink"></video>
    <div style="position: absolute; top: 90%; left: 50%; transform: translate(-50%, 0); -ms-transform: translate(-50%, 0);">
      <div class="tag">{{ myth.mythtag }}</div>
      <div class="name">{{ myth.mythname }}</div>
    </div>
  </div>
</div>
<div style="background-color: rgba(24,24,24,.5)">
  <div class="container">
    <div style="width: 100%; display: flex; justify-content: center;">
      <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/nameityoutself.webp" id="framePart">
    </div>
    <div class="switches">
      <button *ngIf="!overview" (click)="toggleSection('overview')">Overview</button>
      <button *ngIf="overview" style="color: white;">Overview</button>
      <ng-container *ngIf="hasAbilities">
        <button *ngIf="!abilities" (click)="toggleSection('abilities')">Abilities</button>
        <button *ngIf="abilities" style="color: white;">Abilities</button>
      </ng-container>
      <button *ngIf="!statistics" (click)="toggleSection('statistics')">Statistics</button>
      <button *ngIf="statistics" style="color: white;">Statistics</button>
      <button *ngIf="!skins" (click)="toggleSection('skins')">Skins</button>
      <button *ngIf="skins" style="color: white;">Skins</button>
      <ng-container *ngIf="haslore">
        <button *ngIf="!lore" (click)="toggleSection('lore')">Lore</button>
        <button *ngIf="lore" style="color: white">Lore</button>
      </ng-container>
    </div>
    <div style="width: 100%; display: flex; justify-content: center;">
      <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" id="bottomFrame">
    </div>
    <app-share></app-share>
    <ng-container *ngIf="overview">
      <div class="bio">
        <app-myth-button class="mythdisplay" [myth]="myth" [clickable]="false" [index]=0></app-myth-button>
        <div class="biotext">
          <div style="font-size: 2vw;">{{ myth.mythclasstag }}</div>
          <div class="playwith">Play as {{ myth.mythname }}</div>
          <img alt="Malware was here!" class="dash" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" />
          <div class="para" *ngFor="let item of myth.mythdetails; index as i">
            <span *ngIf="i%2 != 0">&nbsp;&nbsp;&nbsp; {{ item }} <br><br></span>
            <span *ngIf="i%2 == 0" style="font-style: italic;">&nbsp;&nbsp;&nbsp; {{ item }} <br><br></span>
          </div>
        </div>
      </div>
      <div class="trailer">
        <iframe *ngIf="this.spotlightLink !== this.defaultVideo && !checkSpotlightType()" class="trailer-iframe" [src]="spotlightLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></iframe>
        <video *ngIf="this.spotlightLink === this.defaultVideo || checkSpotlightType()" class="trailer-iframe" [src]="spotlightLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></video>
      </div>
      <div class="trailer">
        <iframe *ngIf="this.gameplayLink !== this.defaultVideo && !checkGameplayType()" class="trailer-iframe" [src]="gameplayLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></iframe>
        <video *ngIf="this.gameplayLink === this.defaultVideo || checkGameplayType()" class="trailer-iframe" [src]="gameplayLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></video>
      </div>
      <div class="trailer">
        <iframe *ngIf="this.mythThemeLink !== this.defaultVideo && !checkMythThemeType()" class="trailer-iframe" [src]="mythThemeLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></iframe>
        <video *ngIf="this.mythThemeLink === this.defaultVideo" class="trailer-iframe" [src]="mythThemeLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></video>
      </div>
      <ng-container >
        <div class="slides">
          <img alt="Malware was here!" [src]="myth.images[currentSlide]" class="overlay" (click)="changeSlides(1)">
          <img alt="Malware was here!" [src]="myth.images[currentSlide]" class="backdrop">
      </div> 
      <div *ngIf="myth.images.length > 1" class="slide-buttons">
        <a class="prev" (click)="changeSlides(-1)">&#10094;</a>
        {{ currentSlide + 1}} / {{ myth.images.length }}
        <a class="next" (click)="changeSlides(1)">&#10095;</a>
      </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="abilities">
      <div class="skills">
        <div class="skillbox" style="width: 50%;">
          <div style="font-size: 2vw;">{{ myth.mythtag }}</div>
          <div class="playwith">Master the {{ myth.class }}: {{ myth.mythname }}</div>
          <img alt="Malware was here!" class="dash" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" />
          <div class="table-container">
            <div class="sidebar-container2">
              <img alt="Malware was here!" class="topbar" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-04.webp" />
              <img alt="Malware was here!" class="sidebarLeft" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-02.png" />
              <img alt="Malware was here!" class="sidebarRight" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-01.png" />
              <img alt="Malware was here!" class="bottombar" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-03.webp" />
            </div>
            <div class="skillstable">
              <div>                
                <p style="color: white; width: 50%;">Archtype</p>
                <p style="width: 45%;">{{ myth.Archtype }}</p>
                <p style="cursor: pointer; z-index: 2; font-size: 1vw;" (mouseover)="showTip(myth.Archtype)" (mouseout)="clearTip()">?</p>              
              </div>
              <div>   
                <p style="color: white; width: 50%;">Sub Archtype</p>
                <p style="width: 45%;">{{ myth.SubArchtype }}</p>
                <p style="cursor: pointer; z-index: 2; font-size: 1vw;" (mouseover)="showTip(myth.SubArchtype)" (mouseout)="clearTip()">?</p>
              </div>
              <div>   
                <p style="color: white; width: 50%;">Combat Style</p>
                <p style="width: 45%;">{{ myth.combatstyle }}</p>
              </div>
              <div>   
                <p style="color: white; width: 50%;">Essence Type</p>
                <p style="width: 45%;">{{ myth.EssenceType }}</p>
              </div>
              <div>   
                <p style="color: white; width: 50%;">Weapon(s)</p>
                <p style="width: 45%;">{{ myth.Weapon }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="transparent">
          <img id="moreInfo" *ngIf="moreInfo !== undefined" [src]="moreInfo">
          <img alt="Malware was here!" [src]="myth.transparent">
        </div>
      </div>
      <div style="min-height: 90vh;">
        <div class="divider">
          <p class="dp2">Abilities</p>
          <img alt="Malware was here!" class="dimg" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" >
        </div>
        <div style="display: flex; min-height: 60vh;">
          <div *ngIf="hasAbilities && (this.selectedMove !== this.defaultVideo && !usingLocal)" class="iframe-container" style="padding-top: 25%;">
            <iframe class="iframe-video" [src]="selectedMove" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></iframe>
          </div>
          <div *ngIf="hasAbilities && (this.selectedMove === this.defaultVideo || usingLocal) " class="iframe-container" style="padding-top: 5%;">
            <video [src]="selectedMove" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></video>
          </div>
          <div style="width: 60%; height: 100%; margin-right: 5%; margin-left: 5%;">
            <h1 style="color: silver; font-size: 2.5em; margin: 0;">{{ specials[currentMove] }}</h1>
            <p style="font-size: 2em; margin: 0;">{{ abilityNames[currentMove] }}</p>
            <p style="color: white; font-size: 1.75em; font-family: 'Raleway'; text-align: left;">{{ specialsDescriptions[currentMove] }}</p>
          </div>
        </div>
        <div class="specials" style="justify-content: space-evenly">
          <div class="cont2" *ngFor="let item of specialsImages; index as i">
            <app-ability-button *ngIf="(i == 0); else elseBlock" class="ability-button" [selected]="true" [images]="item" [slot]="specials[i]" [index]="i"
              [detail]="this"></app-ability-button>
            <ng-template #elseBlock>
              <app-ability-button class="ability-button" [images]="item" [slot]="specials[i]" [index]="i"
                [detail]="this"></app-ability-button>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="trailer">
        <iframe *ngIf="this.spotlightLink !== this.defaultVideo" class="trailer-iframe" [src]="spotlightLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></iframe>
        <video *ngIf="this.spotlightLink === this.defaultVideo" class="trailer-iframe" [src]="spotlightLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></video>
      </div>
    </ng-container>
    <ng-container *ngIf="statistics">
      <div class="bio-icons">
        <div style="width: 10%;">
          <div class="bioContainer">
            <app-ability-button [images]="myth.realmimage" [selected]="true" [slot]="'Realm'" [name]="myth.realm"
            [realm]="myth.realm" [detail]="this" [index]="-1"></app-ability-button>
          </div>
          <div class="bioContainer">
            <app-ability-button [images]="myth.combatstyleimage" [selected]="true" [slot]="'Combat Style'" [name]="myth.combatstyle"
            [clickable]="false"></app-ability-button>
          </div>
          <div class="bioContainer">
            <app-ability-button [images]="myth.DamageTypeIcon" [selected]="true" [slot]="'Damage Type'" [name]="myth.DamageType"
            [clickable]="false"></app-ability-button>
          </div>
          <div class="bioContainer">
            <app-ability-button [images]="myth.classimage" [selected]="true" [slot]="'Class'" [name]="myth.class"
            [className]="myth.class" [detail]="this" [index]="-2"></app-ability-button>
          </div>
        </div>
        <div class="statContainer">
          <div class="statIcon">
            <img [src]="'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/' + currentStat + '.png'" style="width: 100%; height: 100%;">
          </div>
          <div class="statTable">
            <p style="color: white;">*Actual stats may vary in-game*</p>
            <div (mouseenter)="setStatImage('Health')" (click)="setClickedStat('Health')" (mouseleave)="setStatImage('')">
              <p>Health:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.Health[0] }}</p>
              <p *ngIf="myth.Health[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.Health[1] }}</span>
                  per level
              </p>
            </div>
            <div (mouseenter)="setStatImage('HP5')" (click)="setClickedStat('HP5')" (mouseleave)="setStatImage('')">
              <p>HP5:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.HP5[0] }}</p>
              <p *ngIf="myth.HP5[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.HP5[1] }}</span>
                  per level
              </p>
            </div>
            <div *ngIf="myth.Mana != undefined" (mouseenter)="setStatImage('Mana')" (click)="setClickedStat('Mana')" (mouseleave)="setStatImage('')">
              <p>Mana:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.Mana[0] }}</p>
              <p *ngIf="myth.Mana[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.Mana[1] }}</span>
                  per level
              </p>
            </div>
            <div *ngIf="myth.Stamina != undefined" (mouseenter)="setStatImage('Stamina')" (click)="setClickedStat('Stamina')" (mouseleave)="setStatImage('')">
              <p>Stamina:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.Stamina[0] }}</p>
              <p *ngIf="myth.Stamina[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.Stamina[1] }}</span>
                  per level
              </p>
            </div>
            <div *ngIf="myth.MP5 != undefined" (mouseenter)="setStatImage('MP5')" (click)="setClickedStat('MP5')" (mouseleave)="setStatImage('')">
              <p>MP5:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.MP5[0] }}</p>
              <p *ngIf="myth.MP5[1].length > 1" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.MP5[1] }}</span>
                  per level
              </p>
            </div>
            <div *ngIf="myth.SP5 != undefined" (mouseenter)="setStatImage('SP5')" (click)="setClickedStat('SP5')" (mouseleave)="setStatImage('')">
              <p>SP5:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.SP5[0] }}</p>
              <p *ngIf="myth.SP5[1].length > 1" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.SP5[1] }}</span>
                  per level
              </p>
            </div>
            <div (mouseenter)="setStatImage('Physical_Power')" (click)="setClickedStat('Physical_Power')" (mouseleave)="setStatImage('')">
              <p>Base Physical Power:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.BasePhysicalPower[0] }}</p>
              <p *ngIf="myth.BasePhysicalPower[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.BasePhysicalPower[1] }}</span>
                  per level
              </p>
            </div>
            <div (mouseenter)="setStatImage('Magical_Power')" (click)="setClickedStat('Magical_Power')" (mouseleave)="setStatImage('')">
              <p>Base Magical Power:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.BaseMagicalPower[0] }}</p>
              <p *ngIf="myth.BaseMagicalPower[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.BaseMagicalPower[1] }}</span>
                  per level
              </p>
            </div>
            <div (mouseenter)="setStatImage('Armor')" (click)="setClickedStat('Armor')" (mouseleave)="setStatImage('')">
              <p>Armor:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.Armor[0] }}</p>
              <p *ngIf="myth.Armor[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.Armor[1] }}</span>
                  per level
              </p>
            </div>
            <div (mouseenter)="setStatImage('Magic_Resist')" (click)="setClickedStat('Magic_Resist')" (mouseleave)="setStatImage('')">
              <p>Magic Resist:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.MagicResist[0] }}</p>
              <p *ngIf="myth.MagicResist[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.MagicResist[1] }}</span>
                  per level
              </p>
            </div>
            <div (mouseenter)="setStatImage('Attack_Speed')" (click)="setClickedStat('Attack_Speed')" (mouseleave)="setStatImage('')">
              <p>Attack Speed:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.AttackSpeed[0] }}</p>
              <p *ngIf="myth.AttackSpeed[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.AttackSpeed[1] }}</span>
                  per level
              </p>
            </div>
            <div (mouseenter)="setStatImage('Movement_Speed')" (click)="setClickedStat('Movement_Speed')" (mouseleave)="setStatImage('')">
              <p>Movement Speed:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.MovementSpeed[0] }}</p>
              <p *ngIf="myth.MovementSpeed[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.MovementSpeed[1] }}</span>
                  per level
              </p>
            </div>
            <div>
              <p>Range:&nbsp;</p>
              <p style="color: white; font-size: 1em;">{{ myth.Range[0] }}</p>
              <p *ngIf="myth.Range[1] > 0" style="font-size: .75em;"> 
                <span style="color: white;">&nbsp;+{{ myth.Range[1] }}</span>
                  per level
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="trailer">
        <iframe *ngIf="this.gameplayLink !== this.defaultVideo" class="trailer-iframe" [src]="gameplayLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></iframe>
        <video *ngIf="this.gameplayLink === this.defaultVideo" class="trailer-iframe" [src]="gameplayLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></video>
      </div>
    </ng-container>
    <ng-container *ngIf="skins">
      <div class="skinsAndSounds"style="display: flex; justify-content: center; margin-top: 5%;">
        <div style="width: 80%; position: relative; top: 0; left: 0; clip-path: polygon(0 6%, 3.5% 0, 96.5% 0, 100% 6%, 100% 93.5%, 96.5% 100%, 3.5% 100%, 0 94%);">
          <div class="skinbox" style="position: absolute; top: 0; left: 0; background-color: rgba(0, 0, 0, .5); height: 100%; width: 23%; z-index: 2;">
            <h1 style="color: white; text-align: left; margin-top: 2em; margin-bottom: 0; font-size: 2vw;">&nbsp;Skins</h1>
            <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Section_Brackets.webp" style="width: 90%;">
            <div class="scrollskin" style="display: flex; flex-direction: column; align-items: center;">
              <app-skin-button *ngFor="let item of myth.skins; index as i" style="width: 90%; margin-bottom: 2%;" [images]="item" [detail]="this" [index]="i"></app-skin-button>
            </div>
          </div>
          <h1 style="position: absolute; top: 0; left: 50%; transform: translate(-50%); margin: 0; color: white; background-color: rgba(0, 0, 0, .5); font-size: 2.5vw;">
            {{ myth.skins[currentSkin][0] }}
          </h1>
          <img alt="Malware was here!" [src]="myth.skins[currentSkin][1]" style="width: 100%;">
          <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/main_border.webp" style=" position: absolute; top: 0; left: 0; width: 100%; z-index: 1;">
          <div class="soundbox" *ngIf="!ismobile" style="position: absolute; top: 0; left: 80%; background-color: rgba(0, 0, 0, .5); height: 100%; width: 23%; transform: translate(-12.5%); z-index: 2;">
            <h1 style="color: white; text-align: right; margin-top: 2em; margin-bottom: 0; font-size: 2vw;">Voice Lines&nbsp;</h1>
            <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Section_Brackets.webp" style="width: 90%; transform: scale(-1); transform: rotateY(180deg);">
            <div class="scrollsound">
              <ng-container style="display: flex; flex-direction: column; justify-content: left;">
                <div *ngFor="let item of myth.sounds" style="position: relative; top: 0; left: 0; cursor: pointer;" (click)="playSound(item[1])">
                  <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/sound_border.webp" style="width: 90%;">
                  <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/sound_icon.webp" style="position: absolute; top: 22.5%; right: 7.5%; width: 10%">
                  <p style="position: absolute; top: 25%; left: 10%; transform: translate(0 -50%); margin: 0; font-size: 1vw;">{{ item[0] }}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="soundbox" *ngIf="ismobile" style="background-color: rgba(0, 0, 0, .5); height: 50vh; width: 100vw;">
        <h1 style="color: white; text-align: center; margin-top: 2em; margin-bottom: 0; font-size: 2vw;">Voice Lines&nbsp;</h1>
        <div class="scrollsound">
          <ng-container style="display: flex; flex-direction: column; justify-content: left;">
            <div *ngFor="let item of myth.sounds" style="position: relative; top: 0; left: 0; cursor: pointer;" (click)="playSound(item[1])">
              <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/sound_border.webp" style="width: 90%;">
              <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/sound_icon.webp" style="position: absolute; top: 22.5%; right: 7.5%; width: 10%">
              <p style="position: absolute; top: 25%; left: 10%; transform: translate(0 -50%); margin: 0; font-size: 3vw;">{{ item[0] }}</p>
            </div>
          </ng-container>
        </div>
      </div>
      <p style="color: white; font-size: 1em; margin-top: 0; margin-bottom: 5vh;">*Disclaimer: Sounds and voicelines are not exhaustive and are subject to change.</p>
      <ng-container >
        <div class="slides">
          <img alt="Malware was here!" [src]="myth.images[currentSlide]" class="overlay" (click)="changeSlides(1)">
          <img alt="Malware was here!" [src]="myth.images[currentSlide]" class="backdrop">
        </div> 
        <div *ngIf="myth.images.length > 1" class="slide-buttons">
          <a class="prev" (click)="changeSlides(-1)">&#10094;</a>
          {{ currentSlide + 1}} / {{ myth.images.length }}
          <a class="next" (click)="changeSlides(1)">&#10095;</a>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="lore">
      <div class="blurbs">
        <div *ngFor="let item of mythLore" class="blurb">
          <app-myth-button class="mythdisplay" [blurb]="item" [clickable]="false"></app-myth-button>
          <div style="display: flex; flex-direction: column; margin-left: 1em;">
            <h1 style="font-size: 3em">{{item.title}}</h1>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;{{item.intro}}</p>
            <button (click)="goToLore(item.loreIndex)" style="text-decoration: underline;">Read More</button>
          </div>
        </div>
      </div>
      <div class="trailer">
        <iframe *ngIf="this.mythThemeLink !== this.defaultVideo" class="trailer-iframe" [src]="mythThemeLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></iframe>
        <video *ngIf="this.mythThemeLink === this.defaultVideo" class="trailer-iframe" [src]="mythThemeLink" frameborder="0" width="100%" height="100%" loop autoplay controls muted allowfullscreen></video>
      </div>
    </ng-container>
  </div>
</div>
