import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentYear;
  currentVersion = 'Closed Testing';
  today;
  eventDate;
  eventEnd;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.today = new Date();
    this.currentYear = this.today.getFullYear();
    // year, month starts at 0 (0-11), day, hours, mins, seconds, milli
    this.eventDate = new Date(2022, 3, 28, 0, 0, 0, 0);
    this.eventEnd = new Date(2022, 4, 2, 0, 0, 0, 0);
    // if (this.today.getTime() >= this.eventDate.getTime()) {
    //   this.currentVersion = 'Open Testing v2.0';
    // }
    // if (this.today.getTime() >= this.eventEnd.getTime()) {
    //   this.currentVersion = 'Closed Testing';
    // }
  }

  // social functions, these always open in a new window

  facebook() {
    window.open('https://www.facebook.com/EtherealClashOfSouls/');
  }

  reddit() {
    window.open('https://www.reddit.com/r/Undying_Games/');
  }

  instagram() {
    window.open('https://www.instagram.com/ug_ethereal/');
  }

  discord() {
    window.open('https://discord.gg/HBKbtFm');
  }

  youtube() {
    window.open('https://www.youtube.com/channel/UCBuULwnagsHgSZeEKHoodNQ');
  }

  twitter() {
    window.open('https://twitter.com/ug_ethereal?lang=en');
  }

  twitch() {
    window.open('https://www.twitch.tv/undying_games');
  }

  patreon() {
    window.open('https://www.patreon.com/UndyingGames');
  }

  // nav functions
  tos() {
    this.router.navigate(['TOS']);
  }

  privacy() {
    this.router.navigate(['Privacy']);
  }

  navContact() {
    this.router.navigate(['Contact']);
  }

  navFAQ() {
    this.router.navigate(['FAQ']);
  }

  navForum() {
    this.router.navigate(['Forum']);
  }

  navPatch() {
    this.router.navigate(['Patch']);
  }

  navSupport() {
    this.router.navigate(['Support']);
  }

  navService() {
    this.router.navigate(['Service']);
  }

  navGame() {
    this.router.navigate(['About']);
  }
}
