import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UniverseComponent } from '../universe/universe.component';

@Component({
  selector: 'app-realm-detail',
  templateUrl: './realm-detail.component.html',
  styleUrls: ['./realm-detail.component.css']
})
export class RealmDetailComponent implements OnInit, AfterViewInit {

  @Input() data;
  @Input() universe: UniverseComponent;
  @Input() mythList;
  @Input() loreList;
  overview = true;
  myths = false;
  lore = false;
  hasMyths = false;
  hasLore = false;
  locationLore;
  allLore = [];

  constructor() { }

  ngOnInit(): void {
    if (this.data[3] > 0) {
      this.hasLore = true;
    }
    if (this.mythList !== undefined) {
      this.hasMyths = true;
    }
    let k = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.loreList.length; i++) {
      if (this.loreList[i].loreIndex === Number(this.data[3])) {
        this.locationLore = this.loreList[i].paragraphs[0];
      }
      if (this.loreList[i].tags.includes(this.data[0])) {
        this.allLore[k] = this.loreList[i];
        k++;
      }
    }
    const scrollPoint = document.getElementById('scrollPoint');
    window.scrollTo(0, scrollPoint.getBoundingClientRect().bottom);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const elem = document.getElementById('scrollPointLocation');
      elem.scrollIntoView();
    }, 500);
  }

  goToLore(n: number) {
    this.universe.selectMyth(n);
  }

  jumpToFramePart() {
    document.getElementById('framePartLocation').scrollIntoView();
  }
}
