<div class="section" style="display: flex; align-items: center; flex-direction: column; justify-content: center;">
    <div class="mainTrailerContainer">
        <iframe width="70%" height="100%" [src]="mainTrailer" frameborder="0" style="border: .1em solid rgb(202, 166, 91); z-index: 1;"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
        </iframe>
    </div>
    <div *ngIf="false" class="miniVideoContainer">
        <div class="mini-video">
            <div class="mini-video-inner-container">
                <iframe class="mini-trailer-iframe" [src]="miniTrailer1" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                </iframe>
            </div>
            <div class="mini-video-text-container">
                <p class="p1">Showcase</p><br />
                <p class="p2">Official Ethereal Gameplay</p><br />
                <p class="p2">MOBA Exploration</p><br />
            </div>
        </div>
        <div class="mini-video">
            <div class="mini-video-inner-container">
                <iframe class="mini-trailer-iframe" [src]="miniTrailer2" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                </iframe>
            </div>
            <div class="mini-video-text-container">
                <p class="p1">Gameplay Video</p><br />
                <p class="p2">15 minutes of</p><br />
                <p class="p2">Ethereal: Clash of Souls</p><br />
            </div>
        </div>
        <div class="mini-video">
            <div class="mini-video-inner-container">
                <iframe class="mini-trailer-iframe" [src]="miniTrailer3" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                </iframe>
            </div>
            <div class="mini-video-text-container">
                <p class="p1">Developer Interview</p><br />
                <p class="p2">Interview with the Developers</p><br />
                <p class="p2">At Undying Games</p><br />
            </div>
        </div>
    </div>
</div>