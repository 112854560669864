import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InternalComms } from '../internal-comms.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  slideSources = [
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/Arawn/Arawn_Original.webp', 'Myths/Arawn'
    ],
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/Dante/Dante_Original.webp', 'Myths/Dante'
    ],
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/Lea/Lea_Original.webp', 'Myths/Lea'
    ],
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/Malaia/Malaia_Original.webp', 'Myths/Malaia'
    ],
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/Marina/Marina_Original.webp', 'Myths/Marina'
    ],
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/Noxus/Noxus_Original.webp', 'Myths/Noxus'
    ],
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/SkinRenders/Talos/Talos_Original.webp', 'Myths/Talos'
    ],
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/HomeVideo.webm', 'Myths'
    ],
    [
      'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/Splash.webm', 'Myths'
    ]
  ];

  gameWorld1 = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-world-1.webp';
  gameWorld2 = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-world-2.webp';
  gameWorld3 = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-world-3.webp';
  gameWorld4 = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/game-world-4.webp';

  gameWorldTitles = [
    'Play Together',
    'The Greatest Warriors',
    'A Range of Styles',
    'Always Challenging'
  ];

  gameWorldHeadlines = [
    'Conquer All Lanes',
    'Discover Your Myth',
    'Choose Your Class',
    'Explore a New Universe',
  ];

  gameWorldStories: string[] = [
    'In Ethereal: Clash of Souls, players clash as 2 teams of 6 on Elysium, an enormous three-lane map with a dense and dangerous jungle.' +
    ' Myths must guide their minions down the lanes, leading them on the path to destroy the towers' +
    ' and lane spire that guard each path to the enemy team\'s Elder. The vast jungle contains many monsters' +
    ' for the Myths to defeat for valuable experience and rewards. As the game progresses, Elysium\'s dangerous' +
    ' Guardian Drakes will appear throughout the jungle, offering potentially powerful rewards.' +
    ' Atropos, the most powerful and lethal neutral objective is an epic behemoth with three' +
    ' different evolutions, offering the most game-changing rewards for the team who can defeat him.',
    'Myth\'s are the playable characters in Ethereal: Clash of Souls. They are divided into 8 archetypes, each with their' +
    ' own unique class ability that is shared among Myth\'s of the same class. They have a total of seven powerful' +
    ' abilities: three regular abilities, a powerful ultimate ability, a passive ability, the class ability,' +
    ' and finally a \'special\' ability that captures the absolute essence of each unique Myth. Each special' +
    ' ability comes with a distinct pro and con that must be balanced to use the Myth to it\'s full potential.',
    'Ethereal: Clash of Souls has many classes, each with their own distinct Class Abilities. Adding more' +
    ' versatility and strategy to every match played. Hunt down your target as the Marksman, create portals' +
    ' around the map as the Archmage, tear down the environment and build structures as the Berserker, become' +
    ' devoted to an Etherean as the Cleric, acquire relics and gain power as the Knight, climb walls and jump' +
    ' around obstacles as the Reaper, or attack from above as the Skyslayer.',
    'Clashes of The Eternal War forged six Ethereans, god-like entities that influenced everything that' +
    ' was and would be. Harmony between these siblings connected the fibers of the universe in an era of' +
    ' peace. Cael sought to impose his legacy on nascent life through an infusion of Anima. Life- borne' +
    ' Anima upset the fragile balance with its uncontrolled growth. Eventually causing a fracture in the' +
    ' very fabric of existence, thus creating the realms of Ethereal.'
  ];

  constructor(private router: Router, private comms: InternalComms) { }

  ngOnInit(): void {
    this.comms.setCurrent('navHome');
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      (document.getElementById('background') as HTMLVideoElement).muted = true;
      (document.getElementById('background') as HTMLVideoElement).play();
    }, 2000);
  }

  navGame() {
    this.comms.navigate('About');
  }

  navClasses() {
    this.comms.navigate('Classes');
  }

  navRealms() {
    this.comms.navigate('Realms');
  }

  navMyths() {
    this.comms.navigate('Myths');
  }

  navNews() {
    this.comms.navigate('News');
  }
}
