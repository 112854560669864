import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit {

  videos = [];
  clean = [];

  constructor(private comms: InternalComms, private sanitizer: DomSanitizer, private swagger: SwaggerService) { }

  ngOnInit(): void {
    this.comms.setCurrent('navCommunity');
    let k = 0;
    // tslint:disable-next-line: deprecation
    this.swagger.getAllMultimedia().subscribe(response => {
      this.swagger.setMultimedia(response);
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < response.length; i++) {
        if (JSON.parse(response[i].data).type === 'video') {
          this.videos[k] = JSON.parse(response[i].data);
          k++;
        }
      }
      for (let i = 0; i < this.videos.length; i++) {
        this.clean[i] = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/'
          + this.videos[i].link
          + '?autoplay=0&modestbranding=1&showinfo=0');
      }
    });
  }

}
