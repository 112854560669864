import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InternalComms } from '../internal-comms.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  hover3;
  hover6;
  hover1;

  constructor(private router: Router, private comms: InternalComms) { }

  ngOnInit(): void {
    this.comms.setCurrent('navHome');
  }

  navNews() {
    this.router.navigate(['news']);
  }

  blind3() {
    this.hover3 = 'In Blind Mode, every Myth is available for use but you won\'t know the enemy team\'s picks.';
  }

  draft3() {
    this.hover3 = 'In Draft Mode, you can see the pick of the enemy team and change Myth\'s to counter them.';
}

  blind6() {
      this.hover6 = 'In Blind Mode, every Myth is available for use but you won\'t know the enemy team\'s picks.';
  }

  draft6() {
      this.hover6 = 'In Draft Mode, you can see the pick of the enemy team and change Myth\'s to counter them.';
  }

  solo() {
      this.hover1 = 'Queue up for a 1 on 1 Tournamnet and show everyone that your Myth is the best.';
  }

  duo() {
      this.hover1 = 'Queue up with a friend and prove that you are the best team of 2 in Ethereal.';
  }

  clearText() {
    this.hover3 = '';
    this.hover6 = '';
    this.hover1 = '';
  }
}
