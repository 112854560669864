import { Component, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MythsComponent } from '../myths/myths.component';
import { InternalComms } from '../internal-comms.service';
import { AbilityButtonComponent } from '../ability-button/ability-button.component';
import { SkinButtonComponent } from '../skin-button/skin-button.component';

@Component({
  selector: 'app-myth-detail',
  templateUrl: './myth-detail.component.html',
  styleUrls: ['./myth-detail.component.css']
})
export class MythDetailComponent implements OnInit {

  @ViewChildren(AbilityButtonComponent) private abilityButtons: QueryList<AbilityButtonComponent>;
  @ViewChildren(SkinButtonComponent) private skinButtons: QueryList<SkinButtonComponent>;

  @Input() myth;
  @Input() myths: MythsComponent;

  audio = new Audio();

  isHeadless = false;
  overview = true;
  statistics = false;
  abilities = false;
  skins = false;
  lore = false;
  haslore = false;
  hasAbilities = false;
  currentSlide = 0;
  currentMove = 0;
  currentSkin = 0;
  selectedMove;
  spotlightLink;
  gameplayLink;
  mythThemeLink;
  keys;
  values;
  mythLore = [];
  currentLore = 0;
  ismobile = false;
  defaultVideo = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/Corrupted.webm';
  archtypes = [
    ['Artillery', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Artillery.png'],
    ['Burst', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Burst.png'],
    ['Controller', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Controller.png'],
    ['Disruptor', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Disruptor.png'],
    ['Diver', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Diver.png'],
    ['Duelist', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Duelist.png'],
    ['Enchanter', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Enchanter.png'],
    ['Fighter', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Fighter.png'],
    ['Gladiator', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Gladiator.png'],
    ['Juggernaut', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Juggernaut.png'],
    ['Sentinel', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Sentinel.png'],
    ['Slayer', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Slayer.png'],
    ['Spy', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Spy.png'],
    ['Striker', 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Archtypes/Striker.png']
  ];
  moreInfo;
  idleLink;
  abilityNames = [
    'Basic Attack',
    'Passive',
    'Special Ability',
    'Ability One',
    'Ability Two',
    'Ability Three',
    'Ultimate'
  ];
  specials = [];
  specialsImages = [];
  specialsVideos = [];
  specialsDescriptions = [];
  currentStat = 'Health';
  clickedStat = 'Health';
  usingLocal = false;

  constructor(private sanitizer: DomSanitizer, private comms: InternalComms) { }

  ngOnInit(): void {
    if (this.checkSpotlightType()) {
      this.spotlightLink = this.myth.SpotlightLocal;
    } else if (this.myth.Spotlight !== 'default') {
        this.spotlightLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.myth.Spotlight);
    } else {
      this.spotlightLink = this.defaultVideo;
    }
    if (this.checkGameplayType()) {
      this.gameplayLink = this.myth.GameplayLocal;
    } else if (this.myth.Gameplay !== 'default') {
      this.gameplayLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.myth.Gameplay);
    } else {
      this.gameplayLink = this.defaultVideo;
    }
    if (this.checkMythThemeType()) {
      this.mythThemeLink = this.myth.MythThemeLocal;
    } else if (this.myth.MythTheme !== 'default') {
      this.mythThemeLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.myth.MythTheme);
    } else {
      this.mythThemeLink = this.defaultVideo;
    }
    if (this.checkBasicAttackType()) {
      this.selectedMove = this.myth.BasicAttackVideoLocal;
    } else if (this.myth.BasicAttackVideo !== 'default') {
      this.selectedMove = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/'
    + this.myth.BasicAttackVideo
    + '?autoplay=1&modestbranding=1&showinfo=0&controls=1&mute=1&loop=1&playlist='
    + this.myth.BasicAttackVideo);
    } else {
      this.selectedMove = this.defaultVideo;
    }
    this.keys = Object.keys(this.myth);
    this.values = Object.values(this.myth);
    if (this.myth.loreNumbers.length > 0) {
      this.haslore = true;
    }
    if (window.screen.availWidth <= 425) {
      this.ismobile = true;
    }
    this.idleLink = 'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Lore/' + this.myth.mythname + '/IdleVideo.webm';
    this.specials[0] = this.myth.BasicAttack;
    this.specialsImages[0] = this.myth.BasicAttackIcon;
    this.specialsVideos[0] = this.myth.BasicAttackVideo;
    this.specialsDescriptions[0] = this.checkBasicAttackType() ? this.myth.BasicAttackVideoLocal : this.myth.BasicAttackDescription;
    this.specials[1] = this.myth.Passive;
    this.specialsImages[1] = this.myth.PassiveIcon;
    this.specialsVideos[1] = this.checkPassiveType() ? this.myth.PassiveVideoLocal : this.myth.PassiveVideo;
    this.specialsDescriptions[1] = this.myth.PassiveDescription;
    this.specials[2] = this.myth.SpecialAbility;
    this.specialsImages[2] = this.myth.SpecialAbilityIcon;
    this.specialsVideos[2] = this.checkSpecialType() ? this.myth.BasicAttackVideoLocal : this.myth.SpecialAbilityVideo;
    this.specialsDescriptions[2] = this.myth.SpecialAbilityDescription;
    this.specials[3] = this.myth.AbilityOne;
    this.specialsImages[3] = this.myth.AbilityOneIcon;
    this.specialsVideos[3] = this.checkAbilityOneType() ? this.myth.AbilityOneVideoLocal : this.myth.AbilityOneVideo;
    this.specialsDescriptions[3] = this.myth.AbilityOneDescription;
    this.specials[4] = this.myth.AbilityTwo;
    this.specialsImages[4] = this.myth.AbilityTwoIcon;
    this.specialsVideos[4] = this.checkAbilityTwoType() ? this.myth.AbilityTwoVideoLocal : this.myth.AbilityTwoVideo;
    this.specialsDescriptions[4] = this.myth.AbilityTwoDescription;
    this.specials[5] = this.myth.AbilityThree;
    this.specialsImages[5] = this.myth.AbilityThreeIcon;
    this.specialsVideos[5] = this.checkAbilityThreeType() ? this.myth.AbilityThreeVideoLocal : this.myth.AbilityThreeVideo;
    this.specialsDescriptions[5] = this.myth.AbilityThreeDescription;
    this.specials[6] = this.myth.Ultimate;
    this.specialsImages[6] = this.myth.UltimateIcon;
    this.specialsVideos[6] = this.checkUltimateType() ? this.myth.UltimateVideoLocal : this.myth.UltimateVideo;
    this.specialsDescriptions[6] = this.myth.UltimateDescription;
    if (this.specials.length > 0) {
      this.hasAbilities = true;
    }
  }

  navRealm() {
    this.myths.navRealm(this.myth.realm);
  }

  navClass() {
    this.myths.navClass(this.myth.class);
  }

  set(index?: number) {
    this.usingLocal = false;
    this.abilityButtons.forEach(element => {
      element.selected = false;
    });
    if (index !== undefined) {
      this.currentMove = index;
      switch (this.currentMove)
      {
        case 0:
          this.usingLocal = this.checkBasicAttackType();
          break;
        case 1:
          this.usingLocal = this.checkPassiveType();
          break;
        case 2:
          this.usingLocal = this.checkSpecialType();
          break;
        case 3:
          this.usingLocal = this.checkAbilityOneType();
          break;
        case 4:
          this.usingLocal = this.checkAbilityTwoType();
          break;
        case 5:
          this.usingLocal = this.checkAbilityThreeType();
          break;
        case 6:
          this.usingLocal = this.checkUltimateType();
          break;
      }
      if (this.usingLocal) {
        this.selectedMove = this.specialsVideos[index];
      } else if (this.specialsVideos[index] !== 'default') {
        this.selectedMove = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/'
      + this.specialsVideos[index]
      + '?autoplay=1&modestbranding=1&showinfo=0&controls=1&mute=1&loop=1&playlist='
      + this.specialsVideos[index]);
      } else {
        this.selectedMove = this.defaultVideo;
      }
    }
  }

  changeSlides(n: number) {
    this.currentSlide += n;
    if (this.currentSlide < 0) {this.currentSlide = this.myth.images.length - 1; }
    if (this.currentSlide >= this.myth.images.length) {this.currentSlide = 0; }
  }

  goToLore(n: number) {
    this.comms.header.navigate('Library/' + n);
  }

  toggleSection(section) {
    this.overview = false;
    this.statistics = false;
    this.abilities = false;
    this.skins = false;
    this.lore = false;
    switch (section) {
      case 'overview':
        this.overview = true;
        break;
      case 'statistics':
        this.statistics = true;
        break;
      case 'abilities':
        this.abilities = true;
        break;
      case 'skins':
        this.skins = true;
        break;
      case 'lore':
        this.showBlurbs();
        this.lore = true;
        break;
    }
  }

  showBlurbs() {
    for (let i = 0; i < this.myth.loreNumbers.length; i++) {
      this.mythLore[i] = this.myths.findLore(this.myth.loreNumbers[i]);
    }
  }

  playSound(file: string) {
    this.audio.src = file;
    this.audio.play();
  }

  selectSkin(i: number) {
    this.skinButtons.forEach(element => {
      element.current = 2;
    });
    this.currentSkin = i;
  }

  showTip(archtype) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.archtypes.length; i++) {
      if (this.archtypes[i][0] === archtype) {
        this.moreInfo = this.archtypes[i][1];
        break;
      }
    }
  }

  clearTip() {
    this.moreInfo = undefined;
  }

  reset() {
    this.myths.selectMyth(0);
  }

  setStatImage(statName) {
    if (statName.length > 0) {
      this.currentStat = statName;
    } else {
      this.currentStat = this.clickedStat;
    }
  }

  setClickedStat(statName) {
    this.clickedStat = statName;
  }

  checkSpotlightType(): boolean {
    return this.myth.SpotlightLocal !== null && this.myth.SpotlightLocal !== undefined && this.myth.SpotlightLocal.length > 0;
  }

  checkGameplayType(): boolean {
    return this.myth.GameplayLocal !== null && this.myth.GameplayLocal !== undefined && this.myth.GameplayLocal.length > 0;
  }

  checkMythThemeType(): boolean {
    return this.myth.MythThemeLocal !== null && this.myth.MythThemeLocal !== undefined && this.myth.MythThemeLocal.length > 0;
  }

  checkBasicAttackType(): boolean {
    // tslint:disable-next-line: max-line-length
    return this.myth.BasicAttackVideoLocal !== null && this.myth.BasicAttackVideoLocal !== undefined && this.myth.BasicAttackVideoLocal.length > 0;
  }

  checkPassiveType(): boolean {
    return this.myth.PassiveVideoLocal !== null && this.myth.PassiveVideoLocal !== undefined && this.myth.PassiveVideoLocal.length > 0;
  }

  checkSpecialType(): boolean {
    // tslint:disable-next-line: max-line-length
    return this.myth.SpecialAbilityVideoLocal !== null && this.myth.SpecialAbilityVideoLocal !== undefined && this.myth.SpecialAbilityVideoLocal.length > 0;
  }

  checkAbilityOneType(): boolean {
    // tslint:disable-next-line: max-line-length
    return this.myth.AbilityOneVideoLocal !== null && this.myth.AbilityOneVideoLocal !== undefined && this.myth.AbilityOneVideoLocal.length > 0;
  }

  checkAbilityTwoType(): boolean {
    // tslint:disable-next-line: max-line-length
    return this.myth.AbilityTwoVideoLocal !== null && this.myth.AbilityTwoVideoLocal !== undefined && this.myth.AbilityTwoVideoLocal.length > 0;
  }

  checkAbilityThreeType(): boolean {
    // tslint:disable-next-line: max-line-length
    return this.myth.AbilityThreeVideoLocal !== null && this.myth.AbilityThreeVideoLocal !== undefined && this.myth.AbilityThreeVideoLocal.length > 0;
  }

  checkUltimateType(): boolean {
    // tslint:disable-next-line: max-line-length
    return this.myth.UltimateVideoLocal !== null && this.myth.UltimateVideoLocal !== undefined && this.myth.UltimateVideoLocal.length > 0;
  }
}
