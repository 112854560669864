<div style="position: relative; top: 0; left: 0;">
  <div style="display: flex; flex-wrap: wrap; justify-content: space-between; border-bottom: .1em solid grey;">
    <div class="logoAndVersionContainer">
      <div style="width: 5em; height: 3em; display: flex; justify-content: center; border-right: .1em solid grey;">
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/colored_logo.webp" style="width: auto; height: 100%;">
      </div>
      <div style="display: flex; justify-content: center; align-items: center;">
        <p style="color: silver; margin: 0; margin-left: 2em;">{{ currentVersion }}</p>
        <p>&nbsp;{{ currentMessage }}</p>
      </div>
    </div>
    <div class="social3">
      <div style="display: flex; justify-content: center; align-items: center;">
        <p style="color: silver; margin: 0;">&nbsp;&nbsp;&nbsp;Follow our social channels:&nbsp;</p>
      </div>
      <div style="display: block; justify-content: flex-end; cursor: pointer; align-self: center;">
        <button (click)="discord()" class="discord"></button>
        <button (click)="patreon()" class="patreon"></button>
        <button (click)="facebook()" class="facebook"></button>
        <button (click)="instagram()" class="instagram"></button>
        <button (click)="twitter()" class="twitter"></button>
        <button (click)="reddit()" class="reddit"></button>
        <button (click)="youtube()" class="youtube"></button>
        <button (click)="twitch()" class="twitch"></button>
      </div>
    </div>
  </div>
  <div class="Ethereal02">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Ethereal01.webp" style="width: 100%;">
  </div>
  <div style="justify-content: space-between; width: 100%; display: flex; align-items: center; justify-content: center;">
    <div class="Ethereal01">
      <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Ethereal01.webp" style="width: 100%;">
    </div>
    <div class="navBar">
      <div class="menu" (mouseenter)="showMenu('About')" (mouseleave)="showMenu('')">
        <button class="nav" id="navHome" (click)="navigate('Home')">Home</button>
        <div *ngIf="showAbout" class="dropdown">
          <button (click)="navigate('About')">About</button>
        </div>
      </div>
      <div class="menu" (mouseenter)="showMenu('Game')" (mouseleave)="showMenu('')">
        <button class="nav" id="navGame">Game</button>
        <div *ngIf="showGame" class="dropdown">
          <!-- <button (click)="navigate('Elysium')">Elysium</button> -->
          <button (click)="navigate('Myths')">Myths</button>
          <!-- <button (click)="navigate('Familiars')">Familiars</button> -->
          <button (click)="navigate('Items')">Items</button>
          <button (click)="navigate('Classes')">Classes</button>
          <!--<button (click)="navigate('Builder')">Builder</button>-->
        </div>
      </div>
      <div class="menu" (mouseenter)="showMenu('Codex')" (mouseleave)="showMenu('')">
        <button class="nav" id="navCodex">Codex</button>
        <div *ngIf="showCodex" class="dropdown">
          <button (click)="navigate('Realms')">Realms</button>
          <button (click)="navigate('Library')">Library</button>
        </div>
      </div>
      <div class="menu" (mouseenter)="showMenu('Official')" (mouseleave)="showMenu('')">
        <button class="nav" id="navCommunity" (click)="showMenu('Official')">Community</button>
        <div *ngIf="showOfficial" class="dropdown">
          <button (click)="navigate('News')">News</button>
          <button (click)="navigate('CC')">Community Corner</button>
          <button (click)="navigate('Service')">Server Status</button>
          <!--<button (click)="navigate('Roadmap')">Roadmap</button>-->
        </div>
      </div>
      <!--
      <div class="menu" (mouseenter)="showMenu('My Account')" (mouseleave)="showMenu('')">
        <button class="nav" id="navProfile" (click)="navigate('Profile')">My Account</button>
        <div *ngIf="showMyAccount" class="dropdown">
          <button (click)="navigate('Profile')">Profile</button>
          <button (click)="navigate('Bug-Report')">Report Bug</button>
        </div>
      </div>
      -->
    </div>
    <!--
    <div class="navBar" style="width: 20em;">
      <div class="menu">
        <button *ngIf="!signedIn" class="nav" id="signIn" (click)="navigate('Profile')">Sign In/Up</button>
        <button *ngIf="signedIn" class="nav" id="signOut" (click)="signout()">Sign Out</button>
      </div>
    </div>
    -->
    <div style="margin-right: 10vw;">
      <button class="navPlay" (click)="jumpToRegister()">Newsletter</button>
      <!-- <button *ngIf="today.getTime() < eventDate.getTime()" class="navPlay" (click)="jumpToRegister()">Newsletter</button> -->
      <!-- <a *ngIf="today.getTime() >= eventDate.getTime()" class="navPlayButton" [href]="updaterLink" target="_blank" download="updaterLink">Play Now</a> -->
    </div>
  </div>
  <div *ngIf="!ismobile" class="up" style="top: 30%; transform: rotate(90deg);" id="topScroll" (click)="toTop()">&#10094;&#10094;</div>
  <div *ngIf="!ismobile" class="down" style="top: 40%; transform: rotate(90deg);" id="bottomScroll" (click)="toBottom()">&#10095;&#10095;</div>
  <div class="overlay">
    <video id="background" autoplay muted loop src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/DustAndStarsBackground.webm"></video>
  </div>
</div>
