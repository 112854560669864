
<footer>
  <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/top-footer-frame.svg"/>
  <div class="links">
    <button class="dead">o</button>
    <button class="live" (click)="navGame()">About Ethereal: Clash of Souls</button>
    <button class="dead">o</button>
    <button class="live" (click)="navService()">Server Status</button>
    <button class="dead">o</button>
    <!--
    <button class="live" (click)="navSupport()">Support</button>
    <button class="dead">o</button>
    <button class="live" (click)="navPatch()">Patch Notes</button>
    <button class="dead">o</button>
    <button class="live" (click)="navForum()">Forum</button>
    <button class="dead">o</button>
    <button class="live" (click)="navFAQ()">FAQ</button>
    <button class="dead">o</button>
    <button class="live" (click)="navContact()">Contact</button>
    <button class="dead">o</button> -->
  </div>
  <p style="color: silver">{{ currentVersion }}</p>
  <div class="social3">
      <div class="social_media_icon_container2">
        <button (click)="discord()" class="discord"></button>
        <button (click)="patreon()" class="patreon"></button>
        <button (click)="facebook()" class="facebook"></button>
        <button (click)="instagram()" class="instagram"></button>
        <button (click)="twitter()" class="twitter"></button>
        <button (click)="reddit()" class="reddit"></button>
        <button (click)="youtube()" class="youtube"></button>
        <button (click)="twitch()" class="twitch"></button>
      </div>
  </div>
  <div class="footer-icon" style="margin-top: 1em;">
      <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/transparent-icon.webp" style="height: 7em;">
      <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/PCDD.webp" style="height: 7em; margin-left: -1em">
  </div>
  <div class="links">
    <button class="dead">o</button>
    <button class="live" (click)="privacy()">Privacy Policy</button>
    <button class="dead">o</button>
    <button class="live" (click)="tos()">Terms of Service</button>
    <button class="dead">o</button>
  </div>
  <p id="legal">© 2017-{{currentYear}} Undying Games. All rights reserved. All trademarks referenced here are the properties of their respective owners.</p>
</footer>
