<div style="position: relative; top: 0; left: 0">
  <video class="backdrop" width="100%" muted autoplay loop controlsList="nodownload" style="opacity: .75;">
    <source src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Videos/Portada.webm">
  </video>
  <form>
    <div class="loginBox">
      <div style="display: inline-flex; width: 100%; justify-content: center;">
        <img alt="Malware was here!" class="logo" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Logo.webp">
      </div>
      <div style="display: flex; justify-content: center; flex-direction: column;">
        <div class="container">
          <p>Username:</p>
          <input type="text" id="username" [value]="user.username" (input)="user.username = $event.target.value" autocomplete="username">
        </div>
        <div *ngIf="!isSigningUp && !hasForgotPassword" class="container">
          <p>Password:</p>
          <input type="password" id="password" [value]="user.password" (input)="user.password = $event.target.value" autocomplete="current-password">
        </div>
        <div *ngIf="isSigningUp && !hasForgotPassword" class="container">
          <p>Password:</p>
          <input type="password" id="password" [value]="user.password" (input)="user.password = $event.target.value" autocomplete="new-password">
        </div>
        <div *ngIf="isSigningUp && !hasForgotPassword" class="container">
          <p>Confirm Password:</p>
          <input type="password" id="password2" [value]="password2"(input)="password2 = $event.target.value" autocomplete="new-password">
        </div>
        <div *ngIf="isSigningUp || hasForgotPassword" class="container">
          <p>Email:</p>
          <input type="email" id="email" [value]="user.email" (input)="user.email = $event.target.value" autocomplete="email">
        </div>
        <div *ngIf="wrongPassword">
          <p>Incorrect password</p>
        </div>
      </div>
      <div>
        <div *ngIf="!isSigningUp && !hasForgotPassword" style="display: inline-flex; width: 100%; justify-content: center;">
          <input type="checkbox" id="rememberMe" (click)="warning()">
          <p style="margin: 0; cursor: pointer;" (click)="checkRemember()">Remember me</p>
        </div>
        <div *ngIf="!isSigningUp && !hasForgotPassword" style="display: inline-flex; width: 100%; justify-content: center; margin: 10px 0;">
          <p style="margin: 0; cursor: pointer;" (click)="hasForgotPassword = true">Forgot Password?</p>
        </div>
        <div style="display: inline-flex; width: 100%; justify-content: center;">
          <img [src]="loginButton[currentLoginButton]" *ngIf="!isSigningUp && !hasForgotPassword" (mouseenter)="setLoginButton(1)" (mouseleave)="setLoginButton(0)" (click)="login()">
          <img [src]="resetButton[currentResetButton]" *ngIf="hasForgotPassword" (mouseenter)="setResetButton(1)" (mouseleave)="setResetButton(0)" (click)="forgotPassword()">
          <img [src]="signUpButton[currentSignUpButton]" *ngIf="isSigningUp" (mouseenter)="setSignUpButton(1)" (mouseleave)="setSignUpButton(0)" (click)="signup()">
        </div>
        <div style="display: inline-flex; width: 100%; justify-content: center;">
          <img [src]="createButton[currentCreateButton]" *ngIf="!isSigningUp && !hasForgotPassword" (mouseenter)="setCreateButton(1)" (mouseleave)="setCreateButton(0)" (click)="isSigningUp = !isSigningUp">
          <img [src]="backButton[currentBackButton]" *ngIf="isSigningUp || hasForgotPassword" (mouseenter)="setBackButton(1)" (mouseleave)="setBackButton(0)" (click)="cancel()">
        </div>
      </div>
    </div>
  </form>
</div>
<div style="height: 5em;"></div>
<div *ngIf="isSigningUp && !tosAccepted" class="fixedPanel">
  <div class="innerPanel">
    <div>
      <app-termsbox></app-termsbox>
      <p>Go to the link at the bottom of this site at anytime to review the Terms of Service</p>
      <div class="buttonContainer">
        <button (click)="acceptTerms()">Accept</button>
        <button (click)="cancel()">Decline</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isSigningUp && tosAccepted && !privacyAccepted" class="fixedPanel">
  <div class="innerPanel">
    <div>
      <app-privacybox></app-privacybox>
      <p>Go to the link at the bottom of this site at anytime to review our Privacy Policy</p>
      <div class="buttonContainer">
        <button (click)="acceptPrivacy()">Accept</button>
        <button (click)="cancel()">Decline</button>
      </div>
    </div>
  </div>
</div>
