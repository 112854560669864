import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  isHeadless = true;

  selected;
  tree;
  branch = [];
  branches = [];
  parents = [];
  children = [];
  showTree = false;
  fullTree;
  tooltip = '';
  itemdata = [];
  currentItem = -1;
  showItemDetail = false;
  inclusive = true;

  filters = [
    ['Attack',
      [
        'Attack Speed',
        'Critical Chance', 'Critical Damage',
        'Physical Lifesteal', 'Physical Power', 'Physical Penetration'
      ]
    ],
    ['Defense',
      ['Armor', 'Health', 'HP5', 'Magic Resist', 'Persistence']
    ],
    ['Magical',
      ['Cooldown', 'Magical Lifesteal', 'Magical Power', 'Magical Penetration', 'Mana', 'MP5']
    ],
    ['Utility',
      ['Movement Speed']
    ]
  ];

  tooltips = [
    ['Attack',
      [
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Attack_Speed.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Critical_Chance.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Critical_Damage.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Physical_Lifesteal.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Physical_Power.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Physical_Penetration.png'
      ]
    ],
    ['Defense',
      [
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Armor.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Health.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/HP5.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Magic_Resist.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Persistence.png'
      ]
    ],
    ['Magical',
      [
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Cooldown.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Magical_Lifesteal.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Magical_Power.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Magical_Penetration.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Mana.png',
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/MP5.png'
      ]
    ],
    ['Utility',
      [
        'https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/Stats/Movement_Speed.png'
      ]
    ]
  ];

  types = [
    'Amulet', 'Axe',
    'Book', 'Boots', 'Bow',
    'Cape', 'Charm', 'Chestplate',
    'Gauntlets', 'Gun',
    'Mark', 'Mask',
    'Ring',
    'Scythe', 'Shield', 'Shoes', 'Spear', 'Staff', 'Starting', 'Sword'
  ];

  tiers = [
    'Tier 1',
    'Tier 2',
    'Tier 3'
  ];

  constructor(private comms: InternalComms, private router: Router, private route: ActivatedRoute, private swagger: SwaggerService) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.isHeadless = params.isHeadless;
    });

    this.comms.setCurrent('navGame');
    // tslint:disable-next-line: deprecation
    this.swagger.getAllItems().subscribe(response => {
      this.swagger.setItems(response);
      for (let i = 0; i < response.length; i++) {
        this.itemdata[i] = JSON.parse(response[i].data);
      }
      this.itemdata.sort((a, b) => a.name.localeCompare(b.name));
      // tslint:disable-next-line: deprecation
      this.route.paramMap.subscribe(params => {
        if (params.has('id') && +params.get('id') < this.itemdata.length) {
          this.currentItem = +params.get('id');
        } else if (params.has('id')) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < this.itemdata.length; i++) {
            if ((this.itemdata[i].name).split(' ').join('-') === params.get('id')) {
              this.currentItem = this.itemdata[i].itemNumber;
            }
          }
        }
        if (this.currentItem > -1) {
          this.selectItem(this.currentItem, false);
        }
        this.selected = this.itemdata;
      });
    });
  }

  setInclusive() {
    const andor = document.getElementById('andor') as HTMLInputElement;
    if (andor.checked) {
      this.inclusive = true;
    } else {
      this.inclusive = false;
    }
    this.filterItems();
  }

  selectItem(n: number, skipSearch?: boolean) {
    for (let i = 0; i < this.itemdata.length; i++) {
      // tslint:disable-next-line: triple-equals
      if (this.itemdata[i].itemNumber == n) {
        this.currentItem = i;
        break;
      }
    }
    this.showItemDetail = true;
    const temp = document.getElementById('itemlistbox');
    temp.style.opacity = '.5';

    if(this.isHeadless) {
      window.history.pushState({}, '', `/#/Items/${this.itemdata[this.currentItem].itemNumber}/${this.itemdata[this.currentItem].name.split(' ').join('-')}?isHeadless=true`);
    }
    else {
      window.history.pushState({}, '', `/#/Items/${this.itemdata[this.currentItem].itemNumber}/${this.itemdata[this.currentItem].name.split(' ').join('-')}`);
    }

    this.findTree();
    this.findParentsAndChildren();
  }

  closeDetail() {
    this.showItemDetail = false;
    const temp = document.getElementById('itemlistbox');
    temp.style.opacity = '1';

    if(this.isHeadless) {
      window.history.pushState({}, '', `/#/Items?isHeadless=true`);
    } else {
      window.history.pushState({}, '', `/#/Items/`);
    }
  }

  findTree() {
    const temp = this.itemdata[this.currentItem];
    switch (temp.tier) {
      case 1:
        this.tree = this.getfullTree(temp);
        break;
      case 2:
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.itemdata.length; i++) {
          if (temp.parents[0] === this.itemdata[i].itemNumber) {
            this.tree = this.getfullTree(this.itemdata[i]);
            break;
          }
        }
        break;
      case 3:
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.itemdata.length; i++) {
          if (temp.parents[0] === this.itemdata[i].itemNumber) {
            // tslint:disable-next-line: prefer-for-of
            for (let j = 0; j < this.itemdata.length; j++) {
              if (this.itemdata[i].parents[0] === this.itemdata[j].itemNumber) {
                this.tree = this.getfullTree(this.itemdata[j]);
                break;
              }
            }
          }
        }
        break;
    }
    this.getAllBranches();
  }

  getfullTree(baseItem): any {
    const tempArray = [
      [],
      [],
      []
    ];
    tempArray[0][0] = baseItem;
    this.fullTree = baseItem.fulltree;
    let m = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < baseItem.children.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < this.itemdata.length; j++) {
        if (this.itemdata[j].itemNumber === baseItem.children[i]) {
          tempArray[1][i] = this.itemdata[j];
          for (let k = 0; k < tempArray[1][i].children.length; k++) {
            // tslint:disable-next-line: prefer-for-of
            for (let c = 0; c < this.itemdata.length; c++) {
              if (this.itemdata[c].itemNumber === tempArray[1][i].children[k]) {
                tempArray[2][k] = this.itemdata[c];
                m++;
                break;
              }
            }
          }
          break;
        }
      }
    }
    return tempArray;
  }

  getAllBranches() {
    let n = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.tree[0][0].branches.length; i++) {
      this.branches[i] = [];
      for (let k = 0; k < this.tree[0][0].branches[i].length; k++) {
        // tslint:disable-next-line: prefer-for-of
        for (let m = 0; m < this.itemdata.length; m++) {
          if (this.tree[0][0].branches[i][k] === this.itemdata[m].itemNumber) {
            this.branches[i][k] = this.itemdata[m];
            n++;
            break;
          }
        }
      }
    }
    // loop to find the default current branch
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.branches.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      for (let k = 0; k < this.branches[i].length; k++) {
        if (this.branches[i][k] === this.itemdata[this.currentItem]) {
          this.branch = this.branches[i];
          break;
        }
      }
    }
  }

  findParentsAndChildren() {
    this.parents = [];
    this.children = [];
    for (let i = 0; i < this.itemdata[this.currentItem].parents.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      for (let k = 0; k < this.itemdata.length; k++) {
        if (this.itemdata[this.currentItem].parents[i] === this.itemdata[k].itemNumber) {
          this.parents[i] = this.itemdata[k];
          break;
        }
      }
    }
    for (let i = 0; i < this.itemdata[this.currentItem].children.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      for (let k = 0; k < this.itemdata.length; k++) {
        if (this.itemdata[this.currentItem].children[i] === this.itemdata[k].itemNumber) {
          this.children[i] = this.itemdata[k];
          break;
        }
      }
    }
  }

  setAll() {
    const all = document.getElementById('all') as HTMLInputElement;
    if (all.checked !== false) {
      this.selected = this.itemdata;
      const elems = document.getElementsByClassName('checkbox-filter');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < elems.length; i++) {
        const box = document.getElementById(elems[i].id) as HTMLInputElement;
        box.checked = true;
      }
      const tiers = document.getElementsByClassName('tier-filter');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < tiers.length; i++) {
        const tier = document.getElementById(tiers[i].id) as HTMLInputElement;
        tier.checked = true;
      }
      const types = document.getElementsByClassName('type-filter');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < types.length; i++) {
        const type = document.getElementById(types[i].id) as HTMLInputElement;
        type.checked = true;
      }
      all.checked = true;
    } else {
      this.selected = [];
      const elems = document.getElementsByClassName('checkbox-filter');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < elems.length; i++) {
        const box = document.getElementById(elems[i].id) as HTMLInputElement;
        box.checked = false;
      }
      const tiers = document.getElementsByClassName('tier-filter');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < tiers.length; i++) {
        const tier = document.getElementById(tiers[i].id) as HTMLInputElement;
        tier.checked = false;
      }
      const types = document.getElementsByClassName('type-filter');
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < types.length; i++) {
        const type = document.getElementById(types[i].id) as HTMLInputElement;
        type.checked = false;
      }
      all.checked = false;
    }
  }

  filterItems() {
    let k = 0;
    this.selected = [];
    const all = document.getElementById('all') as HTMLInputElement;
    all.checked = false;
    const tiers = document.getElementsByClassName('tier-filter');
    const elems = document.getElementsByClassName('checkbox-filter');
    const types = document.getElementsByClassName('type-filter');
    if (this.inclusive) {
      // tslint:disable-next-line: prefer-for-of
      for (let t = 0; t < tiers.length; t++) {
        const tier = document.getElementById(tiers[t].id) as HTMLInputElement;
        if (tier.checked) {
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < elems.length; i++) {
            const box = document.getElementById(elems[i].id) as HTMLInputElement;
            if (box.checked) {
              // tslint:disable-next-line: prefer-for-of
              for (let j = 0; j < this.itemdata.length; j++) {
                if (!this.selected.includes(this.itemdata[j]) && this.itemdata[j].tier === (t + 1)) {
                  // tslint:disable-next-line: prefer-for-of
                  for (let c = 0; c < this.itemdata[j].category.length; c++) {
                    if (this.itemdata[j].category[c] === box.id) {
                      // tslint:disable-next-line: prefer-for-of
                      for (let q = 0; q < types.length; q++) {
                        const type = document.getElementById(types[q].id) as HTMLInputElement;
                        if (this.itemdata[j].type === type.id && type.checked === true) {
                          this.selected[k] = this.itemdata[j];
                          k++;
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.itemdata.length; i++) {
        let add = true;
        // tslint:disable-next-line: prefer-for-of
        for (let c = 0; c < this.itemdata[i].category.length; c++) {
          const box = document.getElementById(this.itemdata[i].category[c]) as HTMLInputElement;
          if (!box.checked) {
            add = false;
            break;
          }
          const tier = document.getElementById('Tier ' + this.itemdata[i].tier) as HTMLInputElement;
          if (!tier.checked) {
            add = false;
            break;
          }
          const type = document.getElementById(this.itemdata[i].type) as HTMLInputElement;
          if (!type.checked) {
            add = false;
            break;
          }
        }
        if (add) {
          this.selected[k] = this.itemdata[i];
          k++;
        }
      }
    }
    this.selected.sort((a, b) => a.name.localeCompare(b.name));
  }

  showFullTree() {
    this.showTree = true;
  }

  hideFullTree() {
    this.showTree = false;
  }

  massClick(n) {
    let tiers;
    switch (n) {
      case 0: case 1: case 2: case 3:
        tiers = document.getElementsByClassName('checkbox-filter');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.filters[n][1].length; i++) {
          const box = document.getElementById(this.filters[n][1][i]) as HTMLInputElement;
          if (box.checked === false) {
            box.checked = true;
          } else {
            box.checked = false;
          }
        }
        break;
      case 4:
        const types = document.getElementsByClassName('type-filter');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < types.length; i++) {
          const type = document.getElementById(types[i].id) as HTMLInputElement;
          if (type.checked === false) {
            type.checked = true;
          } else {
            type.checked = false;
          }
        }
        break;
      case 5:
        tiers = document.getElementsByClassName('tier-filter');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < tiers.length; i++) {
          const tier = document.getElementById(tiers[i].id) as HTMLInputElement;
          if (tier.checked === false) {
            tier.checked = true;
          } else {
            tier.checked = false;
          }
        }
        break;
    }
    this.filterItems();
  }

  showInlcusiveToolTip() {
    this.tooltip = 'Removes unchecked categories from search.';
  }

  showToolTip(n, m) {
    this.tooltip = this.tooltips[n][1][m];
  }

  showCatToolTip(text) {
    this.tooltip = 'Flip ' + text + ' selections';
  }

  hideToolTip() {
    this.tooltip = '';
  }

  navigate(page) {
    if(this.isHeadless) {
      this.router.navigate([page], {
        queryParams: {
          isHeadless: true
        }
      });
    } else {
      this.router.navigate([page]);
    }
  }

  onKeySearch(value) {
    this.setAll();
    this.selected = [];
    for (const item of this.itemdata) {
      if (this.selected.indexOf(item) < 0 && (item.name.toLowerCase().includes(value.toLowerCase())
        || item.type.toLowerCase() === value.toLowerCase())) {
        this.selected.push(item);
      }
    }
    this.selected.sort((a, b) => a.name.localeCompare(b.name));
  }
}
