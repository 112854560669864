import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InternalComms } from '../internal-comms.service';
import { SwaggerService } from '../swagger.service';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.css']
})
export class ClassesComponent implements OnInit, AfterViewInit {

  isHeadless = false;

  currentClass = 0;
  showClassDetail = false;
  classes = [];

  constructor(private comms: InternalComms, private router: Router, private route: ActivatedRoute,
              private swagger: SwaggerService) {}

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.isHeadless = params.isHeadless;
    });

    this.comms.setCurrent('navGame');
    this.swagger.getAllClassData().subscribe(response => {
      this.swagger.setClassData(response);
      for (let i = 0; i < response.length; i++) {
        this.classes[i] = JSON.parse(response[i].data);
      }
      this.classes.sort((a, b) => a.className.localeCompare(b.className));
      // tslint:disable-next-line: deprecation
      this.route.paramMap.subscribe(params => {
        if (params.has('id') && +params.get('id') < this.classes.length) {
          this.currentClass = +params.get('id');
        } else if (params.has('id')) {
          for (let i = 0; i < this.classes.length; i++) {
            if (this.classes[i].className === params.get('id')) {
              this.currentClass = i;
            }
          }
        }
      });
      this.showClassDetail = true;
      // tslint:disable-next-line: max-line-length

      if(this.isHeadless) {
        window.history.pushState({}, '', `/#/Classes/${this.currentClass}/${this.classes[this.currentClass].className.split(' ').join('-')}?isHeadless=true`);
      } else {
        window.history.pushState({}, '', `/#/Classes/${this.currentClass}/${this.classes[this.currentClass].className.split(' ').join('-')}`);
      }
    });
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   document.getElementById('scrollPointClasses').scrollIntoView();
    // }, 500);
  }

  selectClass(n: number) {
    this.currentClass = n;
    // tslint:disable-next-line: max-line-length

    if(this.isHeadless) {
      window.history.pushState({}, '', `/#/Classes/${this.currentClass}/${this.classes[this.currentClass].className.split(' ').join('-')}?isHeadless=true`);
    } else {
      window.history.pushState({}, '', `/#/Classes/${this.currentClass}/${this.classes[this.currentClass].className.split(' ').join('-')}`);
    }

    document.getElementById('scrollPointClasses').scrollIntoView();
  }

  navigate(page) {
    if(this.isHeadless) {
      this.router.navigate([page], {
        queryParams: {
          isHeadless: true
        }
      });
    } else {
      this.router.navigate([page]);
    }
  }
}
