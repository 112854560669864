<div class="topcard" id="scrollPoint">
    <img alt="Malware was here!" [src]="data[5]" class="blackwhite">
    <span class="cardline"></span>
    <div class="card-container">
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/menu-frame.webp" class="top">
        <!-- Side bar images-->
        <div class="sidebar-container">
            <img alt="Malware was here!" class="sidebarLeft" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-02.png" />
            <img alt="Malware was here!" class="sidebarRight" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-ornament-01.png" />
        </div>
        <img alt="Malware was here!" [src]="data[6]" class="card" id="scrollPointLocation">
        <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" class="bottom">
    </div>
    <div class="tag">{{ data[4] }}</div>
</div>
<div style="width: 100%; display: flex; justify-content: center;" id="framePartLocation">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/nameityoutself.webp" id="framePart">
</div>
<div class="switches">
    <button *ngIf="!overview" (click)="overview = true; myths = false; lore = false; jumpToFramePart();">Overview</button>
    <button *ngIf="overview" style="color: white;">Overview</button>
    <ng-container *ngIf="hasMyths">
        <button *ngIf="!myths" class="myth2" (click)="overview = false; myths = true; lore = false; jumpToFramePart();">Myths</button>
        <button *ngIf="myths" class="myth2" style="color: white;">Myths</button>
    </ng-container>
    <ng-container *ngIf="hasLore">
        <button *ngIf="!lore" (click)="overview = false; myths = false; lore = true; jumpToFramePart();">Lore</button>
        <button *ngIf="lore" style="color: white">Lore</button>
    </ng-container>
</div>
<div style="width: 100%; display: flex; justify-content: center;">
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/frame-banner.webp" id="bottomFrame">
</div>
<app-share></app-share>
<div class="overview" *ngIf="overview || myths">
    <app-myth-button class="mythdisplay" [data]="data" [clickable]="false"></app-myth-button>
    <div *ngIf="overview" style="margin-left: .5em;">
        <p class="paragraphs" *ngFor="let item of locationLore">&nbsp;&nbsp;&nbsp;&nbsp;{{item}}</p>
    </div>
    <div class="myths" *ngIf="myths && mythList.length > 0">
        <app-myth-button class="mythdisplay" *ngFor="let item of mythList" [myth]="item" [universe]="universe"></app-myth-button>
    </div>
</div>
<div class="lore" *ngIf="lore && allLore.length > 0">
    <div *ngFor="let item of allLore" class="blurb">
        <app-myth-button class="mythdisplay" [blurb]="item" [clickable]="false"></app-myth-button>
        <div style="display: flex; flex-direction: column; justify-content: center; width: 100%; margin-left: 1em;">
          <h1 style="color: rgb(202, 166, 91);">{{item.title}}</h1>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;{{item.intro}}</p>
          <button (click)="goToLore(item.loreIndex)" style="text-decoration: underline;">Read More</button>
        </div>
      </div>
</div>
<img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/top-footer-frame.webp" width="100%">