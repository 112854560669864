<div class="container">
  <div class="container2">
    <div class="tagline">{{ news.tag }}</div>
    <div class="byline">By: {{ news.byline }}</div>
    <div class="headline">{{ news.headline }}</div>
    <div class="date">{{ news.date }}</div>
    <img alt="Malware was here!" src="https://s3.us-east-2.amazonaws.com/undyinggames.com/assets/Images/left-theme-horizontal-line.webp" style="width: 50%">
    <div>
      <ng-container *ngFor="let item of news.story;">
        <p *ngIf="!item.includes('(Bold)') && !item.includes('(Italic)') && !item.includes('(Link)') && !item.includes('(exLink)') " class="story">
          &nbsp;&nbsp;&nbsp;&nbsp;
          {{ item }}
        </p>
        <p *ngIf="item.includes('(Bold)') && !item.includes('(Italic)') && !item.includes('(Underline)')" class="storyBold">
          {{ item.replace('(Bold)', '') }}
        </p>
        <p *ngIf="item.includes('(Bold)') && !item.includes('(Italic)')&& item.includes('(Underline)')" class="storyBoldUnderline">
          {{ item.replace('(Bold)', '').replace('(Underline)', '') }}
        </p>
        <p *ngIf="!item.includes('(Bold)') && item.includes('(Italic)')" class="storyItalic">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ item.replace('(Italic)', '') }}
        </p>
        <p *ngIf="item.includes('(Bold)') && item.includes('(Italic)')" class="storyBoldItalic">
          &nbsp;&nbsp;&nbsp;&nbsp;{{ item.replace('(Bold)', '').replace('(Italic)', '') }}
        </p>
        <p *ngIf="item.includes('(Link)')"  class="link">
          <a [href]="item.replace('(Link)', '')" target="_blank">{{ item.replace('(Link)https://undyinggames.com/#/', '') }}</a>
        </p>
        <p *ngIf="item.includes('(exLink)')"  class="link">
          <a [href]="item.replace('(exLink)', '')" target="_blank">
            {{ item.replace('(exLink)https://www.', '').replace('(exLink)http://www.', '').replace('(exLink)https://', '').replace('(exLink)http://', '') }}
          </a>
        </p>
      </ng-container>
    </div>
  </div>
</div>
<app-share></app-share>
<div *ngIf="news.localVideo !== undefined && news.localVideo !== ''" class="videoContainer">
  <video class="trailer-iframe" [src]="news.localVideo" frameborder="0" loop autoplay controls muted></video>
</div>
<div *ngIf="videoLink !== undefined && videoLink !== ''" class="videoContainer">
  <iframe class="trailer-iframe" [src]="videoLink" frameborder="0" loop autoplay controls></iframe>
</div>
<div class="slides" (click)="changeSlides(1)">
  <img alt="Malware was here!" [src]="news.images[currentSlide]" class="overlay">
  <img alt="Malware was here!" [src]="news.images[currentSlide]" class="backdrop">
</div>
